import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, mergeProps as _mergeProps, createVNode as _createVNode, renderSlot as _renderSlot, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

import { Position } from '@/types/events';
import { useDisplay } from 'vuetify';

interface Props {
  position: Position;
  eventMessage: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'EventPreviewDialog',
  props: {
    position: {},
    eventMessage: {}
  },
  setup(__props: any) {

const { smAndUp } = useDisplay();



return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_spacer = _resolveComponent("v-spacer")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    "max-width": "620",
    opacity: "0.9",
    persistent: ""
  }, {
    activator: _withCtx(({ props: activatorProps }) => [
      _createVNode(_component_v_btn, _mergeProps({
        class: "custom-default-btn show-allies",
        bg: "blue-grey-lighten-1"
      }, activatorProps, {
        icon: "mdi-eye",
        size: _unref(smAndUp) ? 'large' : 'default',
        height: _unref(smAndUp) ? 44 : 36
      }), null, 16, ["size", "height"])
    ]),
    default: _withCtx(({ isActive }) => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_text, null, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "title")
            ]),
            _: 3
          }),
          _createVNode(_component_v_card_actions, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_spacer),
              _createVNode(_component_v_btn, {
                class: "custom-default-btn",
                text: "Закрити",
                size: _unref(smAndUp) ? 'large' : 'default',
                onClick: ($event: any) => (isActive.value = false)
              }, null, 8, ["size", "onClick"]),
              _renderSlot(_ctx.$slots, "default")
            ]),
            _: 2
          }, 1024)
        ]),
        _: 2
      }, 1024)
    ]),
    _: 3
  }))
}
}

})