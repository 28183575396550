let cachedEnv = null;
let envPromise = null;

export async function loadEnv() {
  if (cachedEnv) return cachedEnv;
  if (envPromise) return await envPromise;

  try {
    envPromise = (async () => {
      const response = await fetch('/env.json');
      const data = await response.json();
      cachedEnv = data;
      envPromise = null;
      return cachedEnv;
    })();

    return await envPromise;
  } catch (error) {
    envPromise = null;
    console.error('Failed to load env.json:', error);
    throw error;
  }
}

export function getEnv() {
  if (!cachedEnv) {
    throw new Error(
      'Environment variables not loaded yet. Call loadEnv() first.',
    );
  }
  return cachedEnv;
}
