import type { EventType } from '@/types/events';

import { configUAV, configUAVOld } from '@/config/configUAV';

export const eventConfig: EventType[] = [
  {
    id: 1,
    name: 'Почули',
    options: {
      category: 'single',
    },
    type: 'constant',
    children: [
      {
        id: 2,
        name: 'Виходи',
        type: 'constant',
        children: [
          {
            id: 3,
            name: 'Ствольна артилерія',
            type: 'constant',
            displayType: 'Почули виходи ствольної артилерії',
          },
          {
            id: 4,
            name: 'Міномет',
            type: 'constant',
            children: [
              {
                id: 124,
                name: '82мм',
                type: 'constant',
                displayType: 'Почули виходи міномета, 82мм',
              },
              {
                id: 125,
                name: '120мм',
                type: 'constant',
                displayType: 'Почули виходи міномета, 120мм',
              },
              {
                id: 126,
                name: 'Великокаліберний міномет',
                type: 'constant',
                displayType: 'Почули виходи міномета, великокаліберного',
              },
              {
                id: 127,
                name: 'Не можливо визначити',
                type: 'constant',
                displayType:
                  'Почули виходи міномета, калібр якого не можливо визначити',
              },
            ],
          },
          {
            id: 5,
            name: 'Танк',
            type: 'constant',
            displayType: 'Почули виходи танка',
          },
          {
            id: 6,
            name: 'СПГ',
            type: 'constant',
            displayType: 'Почули виходи СПГ',
          },
          {
            id: 7,
            name: 'АГС',
            type: 'constant',
            displayType: 'Почули виходи АГС',
          },
          {
            id: 8,
            name: 'Гармата 30мм',
            type: 'constant',
            displayType: 'Почули виходи гармати 30мм',
          },
          {
            id: 9,
            name: 'НАРи',
            type: 'constant',
            displayType: 'Почули виходи НАРів',
          },
          {
            id: 10,
            name: 'Реактивна артилерія',
            type: 'constant',
            displayType: 'Почули виходи реактивної артилерії',
          },
          {
            id: 12,
            name: 'Не встановлено',
            type: 'constant',
            displayType: 'Почули виходи засобу не встановленого типу',
          },
          {
            id: 11,
            name: 'Інше',
            type: 'input',
            displayType: 'Почули виходи ',
            shouldDisplayInputValue: true,
          },
        ],
      },
      {
        id: 13,
        name: 'Приходи',
        type: 'constant',
        children: [
          {
            id: 14,
            name: 'Ствольна артилерія',
            type: 'constant',
            displayType: 'Почули приходи ствольної артилерії',
          },
          {
            id: 15,
            name: 'Міномет',
            type: 'constant',
            children: [
              {
                id: 128,
                name: '82мм',
                type: 'constant',
                displayType: 'Почули приходи міномета 82мм',
              },
              {
                id: 129,
                name: '120мм',
                type: 'constant',
                displayType: 'Почули приходи міномета 120мм',
              },
              {
                id: 130,
                name: 'Великокаліберний міномет',
                type: 'constant',
                displayType: 'Почули приходи міномета великокаліберного',
              },
              {
                id: 131,
                name: 'Не можливо визначити',
                type: 'constant',
                displayType:
                  'Почули приходи міномета калібр якого не можливо визначити',
              },
            ],
          },
          {
            id: 16,
            name: 'Танк',
            type: 'constant',
            displayType: 'Почули приходи танка',
          },
          {
            id: 17,
            name: 'СПГ',
            type: 'constant',
            displayType: 'Почули приходи СПГ',
          },
          {
            id: 18,
            name: 'АГС',
            type: 'constant',
            displayType: 'Почули приходи АГС',
          },
          {
            id: 19,
            name: 'Гармата 30мм',
            type: 'constant',
            displayType: 'Почули приходи гармати 30мм',
          },
          {
            id: 20,
            name: 'НАРи',
            type: 'constant',
            displayType: 'Почули приходи НАРів',
          },
          {
            id: 21,
            name: 'КАБ',
            type: 'constant',
            displayType: 'Почули приходи КАБу',
          },
          {
            id: 22,
            name: 'Реактивна артилерія',
            type: 'constant',
            displayType: 'Почули приходи реактивної артилерії',
          },
          {
            id: 23,
            name: 'FPV',
            type: 'constant',
            displayType: 'Почули приходи FPV',
          },
          {
            id: 24,
            name: 'Скид з дрона',
            type: 'constant',
            displayType: 'Почули приходи схожі на скид з дрона',
          },
          {
            id: 26,
            name: 'Не встановлено',
            type: 'constant',
            displayType: 'Почули приходи засобу не встановленого типу',
          },
          {
            id: 25,
            name: 'Інше',
            type: 'input',
            displayType: 'Почули приходи ',
            shouldDisplayInputValue: true,
          },
        ],
      },
      {
        id: 27,
        name: 'Техніка',
        type: 'constant',
        children: [
          {
            id: 28,
            name: 'Колісна',
            type: 'constant',
            children: [
              {
                id: 29,
                name: 'Рухається',
                type: 'constant',
                displayType: 'Почули техніку, колісну, що рухається',
              },
              {
                id: 30,
                name: 'Не рухається',
                type: 'constant',
                displayType: 'Почули техніку, колісну, що не рухається',
              },
            ],
          },
          {
            id: 31,
            name: 'Гусенична',
            type: 'constant',
            children: [
              {
                id: 32,
                name: 'Рухається',
                type: 'constant',
                displayType: 'Почули техніку, гусеничну, що рухається',
              },
              {
                id: 33,
                name: 'Не рухається',
                type: 'constant',
                displayType: 'Почули техніку, гусеничну, що не рухається',
              },
            ],
          },
          {
            id: 34,
            name: 'Не можливо визначити',
            type: 'constant',
            children: [
              {
                id: 35,
                name: 'Рухається',
                type: 'constant',
                displayType:
                  'Почули техніку, тип якої не можливо визначити, що рухається',
              },
              {
                id: 36,
                name: 'Не рухається',
                type: 'constant',
                displayType:
                  'Почули техніку, тип якої не можливо визначити, що не рухається',
              },
            ],
          },
        ],
      },
      {
        id: 122,
        name: 'Стрілецький бій',
        type: 'constant',
        displayType: 'Почули стрілецький бій',
      },
      {
        id: 146,
        name: 'Стрілецька зброя',
        type: 'constant',
        displayType: 'Почули стрілецьку зброю',
      },
      {
        id: 123,
        name: 'Вибухи',
        type: 'constant',
        displayType: 'Почули вибухи',
      },
      {
        id: 37,
        name: 'Авіація',
        type: 'constant',
        children: [
          {
            id: 38,
            name: 'Гелікоптер',
            type: 'constant',
            displayType: 'Почули авіацію: гелікоптер',
          },
          {
            id: 39,
            name: 'Літак',
            type: 'constant',
            displayType: 'Почули авіацію: літак',
          },
          {
            id: 40,
            name: 'Не встановлено',
            type: 'constant',
            displayType: 'Почули авіацію: тип не встановлено',
          },
        ],
      },
      {
        id: 41,
        name: 'БПЛА',
        type: 'constant',
        children: [
          {
            id: 42,
            name: 'Крило',
            type: 'constant',
            displayType: 'Почули БПЛА: крило',
          },
          {
            id: 43,
            name: 'FPV',
            type: 'constant',
            displayType: 'Почули БПЛА: FPV',
          },
          {
            id: 44,
            name: 'Розвідувальний квадрокоптер',
            type: 'constant',
            displayType: 'Почули БПЛА: розвідувальний квадрокоптер',
          },
          {
            id: 45,
            name: 'Важкий бомбер',
            type: 'constant',
            displayType: 'Почули БПЛА: важкий бомбер',
          },
        ],
      },
      {
        id: 46,
        name: 'Інше',
        type: 'input',
        displayType: 'Почули ',
        shouldDisplayInputValue: true,
      },
    ],
  },
  {
    id: 47,
    name: 'Побачили',
    options: { category: 'single' },
    type: 'constant',
    children: [
      {
        id: 48,
        name: 'Техніку противника',
        type: 'constant',
        children: [
          {
            id: 49,
            name: 'ЛАТ',
            type: 'constant',
            displayType: 'Побачили техніку противника: ЛАТ',
          },
          {
            id: 50,
            name: 'ВАТ',
            type: 'constant',
            displayType: 'Побачили техніку противника: ВАТ',
          },
          {
            id: 51,
            name: 'БТР',
            type: 'constant',
            displayType: 'Побачили техніку противника: БТР',
          },
          {
            id: 52,
            name: 'БМП',
            type: 'constant',
            displayType: 'Побачили техніку противника: БМП',
          },
          {
            id: 53,
            name: 'Танк',
            type: 'constant',
            displayType: 'Побачили техніку противника: танк',
          },
          {
            id: 54,
            name: 'Не можливо визначити',
            type: 'constant',
            displayType:
              'Побачили техніку противника: тип не можливо визначити',
          },
        ],
      },
      {
        id: 55,
        name: 'Піхота',
        type: 'constant',
        displayType: 'Побачили піхоту',
      },
      {
        id: 56,
        name: 'Авіація',
        type: 'constant',
        children: [
          {
            id: 57,
            name: 'Гелікоптер',
            type: 'constant',
            displayType: 'Побачили авіацію: гелікоптер',
          },
          {
            id: 58,
            name: 'Літак',
            type: 'constant',
            displayType: 'Побачили авіацію: літак',
          },
          {
            id: 59,
            name: 'Не встановлено',
            type: 'constant',
            displayType: 'Побачили авіацію: тип не встановлено',
          },
        ],
      },
      {
        id: 147,
        name: 'Виходи',
        type: 'constant',
        children: [
          {
            id: 148,
            name: 'Ствольна артилерія',
            type: 'constant',
            displayType: 'Побачили виходи ствольної артилерії',
          },
          {
            id: 149,
            name: 'Міномет',
            type: 'constant',
            children: [
              {
                id: 150,
                name: '82мм',
                type: 'constant',
                displayType: 'Побачили виходи міномета, 82мм',
              },
              {
                id: 151,
                name: '120мм',
                type: 'constant',
                displayType: 'Побачили виходи міномета, 120мм',
              },
              {
                id: 152,
                name: 'Великокаліберний міномет',
                type: 'constant',
                displayType: 'Побачили виходи міномета, великокаліберного',
              },
              {
                id: 153,
                name: 'Не можливо визначити',
                type: 'constant',
                displayType:
                  'Побачили виходи міномета, калібр якого не можливо визначити',
              },
            ],
          },
          {
            id: 154,
            name: 'Танк',
            type: 'constant',
            displayType: 'Побачили виходи танка',
          },
          {
            id: 155,
            name: 'СПГ',
            type: 'constant',
            displayType: 'Побачили виходи СПГ',
          },
          {
            id: 156,
            name: 'АГС',
            type: 'constant',
            displayType: 'Побачили виходи АГС',
          },
          {
            id: 157,
            name: 'Гармата 30мм',
            type: 'constant',
            displayType: 'Побачили виходи гармати 30мм',
          },
          {
            id: 158,
            name: 'НАРи',
            type: 'constant',
            displayType: 'Побачили виходи НАРів',
          },
          {
            id: 159,
            name: 'Реактивна артилерія',
            type: 'constant',
            displayType: 'Побачили виходи реактивної артилерії',
          },
          {
            id: 161,
            name: 'Не встановлено',
            type: 'constant',
            displayType: 'Побачили виходи засобу не встановленого типу',
          },
          {
            id: 160,
            name: 'Інше',
            type: 'input',
            displayType: 'Побачили виходи ',
            shouldDisplayInputValue: true,
          },
        ],
      },
      {
        id: 162,
        name: 'Приходи',
        type: 'constant',
        children: [
          {
            id: 163,
            name: 'Ствольна артилерія',
            type: 'constant',
            displayType: 'Побачили приходи ствольної артилерії',
          },
          {
            id: 164,
            name: 'Міномет',
            type: 'constant',
            children: [
              {
                id: 165,
                name: '82мм',
                type: 'constant',
                displayType: 'Побачили приходи міномета, 82мм',
              },
              {
                id: 166,
                name: '120мм',
                type: 'constant',
                displayType: 'Побачили приходи міномета, 120мм',
              },
              {
                id: 167,
                name: 'Великокаліберний міномет',
                type: 'constant',
                displayType: 'Побачили приходи міномета, великокаліберного',
              },
              {
                id: 168,
                name: 'Не можливо визначити',
                type: 'constant',
                displayType:
                  'Побачили приходи міномета, калібр якого не можливо визначити',
              },
            ],
          },
          {
            id: 169,
            name: 'Танк',
            type: 'constant',
            displayType: 'Побачили приходи танка',
          },
          {
            id: 170,
            name: 'СПГ',
            type: 'constant',
            displayType: 'Побачили приходи СПГ',
          },
          {
            id: 171,
            name: 'АГС',
            type: 'constant',
            displayType: 'Побачили приходи АГС',
          },
          {
            id: 172,
            name: 'Гармата 30мм',
            type: 'constant',
            displayType: 'Побачили приходи гармати 30мм',
          },
          {
            id: 173,
            name: 'НАРи',
            type: 'constant',
            displayType: 'Побачили приходи НАРів',
          },
          {
            id: 174,
            name: 'КАБ',
            type: 'constant',
            displayType: 'Побачили приходи КАБу',
          },
          {
            id: 175,
            name: 'Реактивна артилерія',
            type: 'constant',
            displayType: 'Побачили приходи реактивної артилерії',
          },
          {
            id: 176,
            name: 'FPV',
            type: 'constant',
            displayType: 'Побачили приходи FPV',
          },
          {
            id: 177,
            name: 'Скид з дрона',
            type: 'constant',
            displayType: 'Побачили приходи схожі на скид з дрона',
          },
          {
            id: 179,
            name: 'Не встановлено',
            type: 'constant',
            displayType: 'Побачили приходи засобу не встановленого типу',
          },
          {
            id: 178,
            name: 'Інше',
            type: 'input',
            displayType: 'Побачили приходи ',
            shouldDisplayInputValue: true,
          },
        ],
      },
      {
        id: 60,
        name: 'Вибухи',
        type: 'constant',
        displayType: 'Побачили вибухи',
      },
      {
        id: 61,
        name: 'Спалах',
        type: 'constant',
        displayType: 'Побачили спалах',
      },
      {
        id: 62,
        name: 'Дим',
        type: 'constant',
        displayType: 'Побачили дим',
      },
      {
        id: 132,
        name: 'Трасуючі кулі',
        type: 'constant',
        displayType: 'Побачили трасуючі кулі',
      },
      {
        id: 63,
        name: 'Сигнальна ракета',
        type: 'constant',
        children: [
          {
            id: 64,
            name: 'Біла',
            type: 'constant',
            displayType: 'Побачили сигнальну ракету білого кольору',
          },
          {
            id: 65,
            name: 'Жовта',
            type: 'constant',
            displayType: 'Побачили сигнальну ракету жовтого кольору',
          },
          {
            id: 66,
            name: 'Червона',
            type: 'constant',
            displayType: 'Побачили сигнальну ракету червоного кольору',
          },
          {
            id: 67,
            name: 'Інше',
            type: 'input',
            displayType: 'Побачили сигнальну ракету ',
            shouldDisplayInputValue: true,
          },
        ],
      },
      {
        id: 68,
        name: 'БПЛА',
        type: 'constant',
        children: [
          {
            id: 69,
            name: 'Крило',
            type: 'constant',
            displayType: 'Побачили БПЛА: крило',
          },
          {
            id: 70,
            name: 'FPV',
            type: 'constant',
            displayType: 'Побачили БПЛА: FPV',
          },
          {
            id: 71,
            name: 'Розвідувальний квадрокоптер',
            type: 'constant',
            displayType: 'Побачили БПЛА: розвідувальний квадрокоптер',
          },
          {
            id: 72,
            name: 'Важкий бомбер',
            type: 'constant',
            displayType: 'Побачили БПЛА: важкий бомбер',
          },
          {
            id: 73,
            name: 'Ланцет',
            type: 'constant',
            displayType: 'Побачили БПЛА: ланцет',
          },
          {
            id: 74,
            name: 'Баражуючий боєприпас',
            type: 'constant',
            displayType: 'Побачили БПЛА: баражуючий боєприпас',
          },
        ],
      },
      {
        id: 75,
        name: 'Освітлювальна міна',
        type: 'constant',
        displayType: 'Побачили освітлювальну міну',
      },
      {
        id: 76,
        name: 'Запалювальна міна',
        type: 'constant',
        displayType: 'Побачили запалювальну міну',
      },
      {
        id: 77,
        name: 'Пожежа',
        type: 'constant',
        displayType: 'Побачили пожежу',
      },
      {
        id: 78,
        name: 'Ракета',
        type: 'constant',
        displayType: 'Побачили ракету',
      },
      {
        id: 79,
        name: 'Інше',
        type: 'input',
        displayType: 'Побачили ',
        shouldDisplayInputValue: true,
      },
    ],
  },
  {
    id: 80,
    name: 'Обстріл',
    options: {
      category: 'single',
      isUnderFire: true,
    },
    type: 'constant',
    children: [
      {
        id: 81,
        name: 'Міномет',
        type: 'constant',
        children: [
          {
            id: 82,
            name: '82мм',
            type: 'constant',
            displayType: 'Обстріл з міномета, 82мм',
          },
          {
            id: 83,
            name: '120мм',
            type: 'constant',
            displayType: 'Обстріл з міномета, 120мм',
          },
          {
            id: 84,
            name: 'Великокаліберний міномет',
            type: 'constant',
            displayType: 'Обстріл з міномета, великокаліберного',
          },
          {
            id: 85,
            name: 'Не можливо визначити',
            type: 'constant',
            displayType:
              'Обстріл з міномета, калібр якого не можливо визначити',
          },
        ],
      },
      {
        id: 86,
        name: 'Ствольна артилерія',
        type: 'constant',
        displayType: 'Обстріл з ствольної артилерії',
      },
      {
        id: 87,
        name: 'Стрілецька зброя',
        type: 'constant',
        children: [
          {
            id: 88,
            name: 'Стрілецька зброя',
            type: 'constant',
            displayType: 'Обстріл зі стрілецької зброї',
          },
          {
            id: 89,
            name: 'Великокаліберний кулемет',
            type: 'constant',
            displayType: 'Обстріл з великокаліберного кулемета',
          },
          {
            id: 90,
            name: 'Підствольний гранатомет',
            type: 'constant',
            displayType: 'Обстріл з підствольного гранатомета',
          },
          {
            id: 91,
            name: 'Гранатомет',
            type: 'constant',
            displayType: 'Обстріл з гранатомета',
          },
          {
            id: 92,
            name: 'Снайперська зброя',
            type: 'constant',
            displayType: 'Обстріл з снайперської зброї',
          },
          {
            id: 93,
            name: 'не можливо визначити',
            type: 'constant',
            displayType:
              'Обстріл зі стрілецької зброї, тип якої не можливо визначити',
          },
        ],
      },
      {
        id: 94,
        name: 'АГС',
        type: 'constant',
        displayType: 'Обстріл з АГС',
      },
      {
        id: 95,
        name: 'СПГ',
        type: 'constant',
        displayType: 'Обстріл з СПГ',
      },
      {
        id: 96,
        name: 'ПТРК',
        type: 'constant',
        displayType: 'Обстріл з ПТРК',
      },
      {
        id: 97,
        name: 'Танк',
        type: 'constant',
        displayType: 'Обстріл з танку',
      },
      {
        id: 98,
        name: 'РСЗВ',
        type: 'constant',
        displayType: 'Обстріл з РСЗВ',
      },
      {
        id: 99,
        name: 'Гармата 30мм',
        type: 'constant',
        displayType: 'Обстріл з гармати 30мм',
      },
      {
        id: 100,
        name: 'Гармата 100мм',
        type: 'constant',
        displayType: 'Обстріл з гармати 100мм',
      },
      {
        id: 101,
        name: 'Не можливо визначити',
        type: 'constant',
        displayType: 'Обстріл з засобу, тип якого не можливо визначити',
      },
    ],
  },
  {
    id: 102,
    name: 'Обстріл припинився',
    type: 'constant',
    displayType: 'Обстріл припинився',
    options: {
      category: 'single',
      skipMapStep: true,
      isUnderFire: false,
    },
  },
  {
    id: 103,
    name: 'Удар БПЛА',
    options: {
      category: 'single',
      skipMapStep: true,
    },
    type: 'constant',
    children: [
      {
        id: 104,
        name: 'Удар FPV',
        type: 'constant',
        displayType: 'Удар FPV',
      },
      {
        id: 105,
        name: 'Скид з БПЛА',
        type: 'constant',
        displayType: 'Скид з БПЛА',
      },
      {
        id: 106,
        name: 'Удар баражуючим боєприпасом',
        type: 'constant',
        displayType: 'Удар баражуючим боєприпасом',
      },
      {
        id: 107,
        name: 'Удар Ланцетом',
        type: 'constant',
        displayType: 'Удар Ланцетом',
      },
      {
        id: 108,
        name: 'Скид горючої суміші з дрона',
        type: 'constant',
        displayType: 'Скид горючої суміші з дрона',
      },
    ],
  },
  {
    id: 109,
    name: 'Авіаційний удар',
    options: {
      category: 'single',
      skipMapStep: true,
    },
    type: 'constant',
    children: [
      {
        id: 110,
        name: 'Удар КАБом',
        type: 'constant',
        displayType: 'Удар КАБом',
      },
      {
        id: 111,
        name: 'Удар НАРами',
        type: 'constant',
        displayType: 'Удар НАРами',
      },
    ],
  },
  {
    id: 112,
    name: 'Застосування хімзброї',
    options: { category: 'single' },
    type: 'constant',
    children: [
      {
        id: 113,
        name: 'Скид з БПЛА (хімзброя)',
        type: 'constant',
        displayType: 'Скид з БПЛА (хімзброя)',
      },
      {
        id: 114,
        name: 'Удар FPV (хімзброя)',
        type: 'constant',
        displayType: 'Удар FPV (хімзброя)',
      },
      {
        id: 115,
        name: 'Обстріл з міномета (хімзброя)',
        type: 'constant',
        displayType: 'Обстріл з міномета (хімзброя)',
      },
      {
        id: 116,
        name: 'Не можливо визначити',
        type: 'constant',
        displayType: 'Застосування хімзброї',
      },
    ],
  },
  {
    id: 117,
    name: 'Штурмові дії ворога',
    type: 'constant',
    displayType: 'Штурмові дії ворога',
    options: {
      category: 'single',
      skipMapStep: true,
      allies: true,
      enemies: true,
      isUnderAttack: true,
    },
  },
  {
    id: 181,
    name: 'Вогневий контакт',
    type: 'constant',
    displayType: 'Вогневий контакт',
    options: {
      category: 'single',
    },
  },
  {
    id: 118,
    name: 'Штурмові дії ворога припинились',
    type: 'constant',
    displayType: 'Штурмові дії ворога припинились',
    options: { category: 'single', skipMapStep: true, isUnderAttack: false },
  },
  {
    id: 119,
    name: 'Підрив на мінуванні',
    type: 'constant',
    displayType: 'Підрив на мінуванні',
    options: {
      category: 'single',
      skipMapStep: true,
    },
  },
  {
    id: 120,
    name: 'Пожежа',
    type: 'constant',
    displayType: 'Пожежа',
    options: {
      category: 'single',
      skipMapStep: true,
    },
  },
  {
    id: 135,
    name: 'Перевірка статусу',
    type: 'constant',
    displayType: 'Перевірка статусу',
    options: {
      category: 'single',
      skipMapStep: true,
    },
  },
  {
    id: 180,
    name: 'Втрата/пошкодження ОВТ',
    type: 'constant',
    displayType: 'Втрата/пошкодження ОВТ',
    options: {
      category: 'single',
      skipMapStep: true,
    },
  },
  {
    id: 136,
    name: '300',
    type: 'constant',
    displayType: '300',
    options: {
      category: 'single',
      skipMapStep: true,
    },
  },
  {
    id: 137,
    name: '200',
    type: 'constant',
    displayType: '200',
    options: {
      category: 'single',
      skipMapStep: true,
    },
  },
  {
    id: 139,
    name: 'Рух',
    options: {
      category: 'single',
      skipMapStep: true,
    },
    type: 'constant',
    children: [
      {
        id: 140,
        name: 'Почали рух',
        type: 'constant',
        displayType: 'Почали рух',
      },
      {
        id: 141,
        name: 'Зупинилися',
        type: 'constant',
        displayType: 'Зупинилися',
      },
      {
        id: 142,
        name: 'Прибули',
        type: 'constant',
        displayType: 'Прибули',
      },
      {
        id: 143,
        name: 'Провели ротацію',
        type: 'constant',
        displayType: 'Провели ротацію',
      },
      {
        id: 144,
        name: 'Пройшли',
        type: 'constant',
        displayType: 'Пройшли',
      },
      {
        id: 145,
        name: 'Готові до руху',
        type: 'constant',
        displayType: 'Готові до руху',
      },
    ],
  },
  {
    id: 138,
    name: 'Втрата радіостанції',
    type: 'constant',
    displayType: 'Втрата радіостанції',
    options: {
      category: 'single',
      skipMapStep: true,
    },
  },
  {
    id: 133,
    name: 'Перехоплення ворожого радіоефіру',
    type: 'constant',
    displayType: 'Перехоплення ворожого радіоефіру',
    options: {
      category: 'single',
      skipMapStep: true,
    },
  },
  {
    id: 134,
    name: 'Посадили ворожу FPV',
    type: 'constant',
    displayType: 'Посадили ворожу FPV',
    options: { category: 'single' },
  },
  {
    id: 200,
    name: 'Виконали вогневе завдання',
    displayType: 'Виконали вогневе завдання',
    options: { category: 'steps', skipMapStep: true },
    type: 'constant',
    steps: [
      {
        name: 'Засіб',
        type: 'constant',
        children: [
          {
            name: 'Засіб',
            type: 'constant',
            children: [
              {
                name: 'Міномет',
                type: 'constant',
                children: [
                  {
                    name: '60 мм',
                    type: 'constant',
                    displayType: 'Засіб: Міномет, 60 мм',
                  },
                  {
                    name: '81 мм',
                    type: 'constant',
                    displayType: 'Засіб: Міномет, 81 мм',
                  },
                  {
                    name: '82 мм',
                    type: 'constant',
                    displayType: 'Засіб: Міномет, 82 мм',
                  },
                  {
                    name: '120 мм',
                    type: 'constant',
                    displayType: 'Засіб: Міномет, 120 мм',
                  },
                ],
              },
              {
                name: 'Стрілецька зброя',
                type: 'constant',
                children: [
                  {
                    name: 'Стрілецька зброя',
                    type: 'constant',
                    displayType: 'Засіб: Стрілецька зброя',
                  },
                  {
                    name: 'Підствольний гранатомет',
                    type: 'constant',
                    displayType: 'Засіб: Підствольний гранатомет',
                  },
                  {
                    name: 'Гранатомет',
                    type: 'constant',
                    displayType: 'Засіб: Гранатомет',
                  },
                  {
                    name: 'Снайперська зброя',
                    type: 'constant',
                    displayType: 'Засіб: Снайперська зброя',
                  },
                  {
                    name: 'Кулемет',
                    type: 'constant',
                    displayType: 'Засіб: Кулемет',
                  },
                ],
              },
              {
                name: 'Великокаліберний кулемет',
                type: 'constant',
                children: [
                  {
                    name: 'ДШК',
                    type: 'constant',
                    displayType: 'Засіб: ДШК',
                  },
                  {
                    name: 'Browning M2',
                    type: 'constant',
                    displayType: 'Засіб: Browning M2',
                  },
                ],
              },
              {
                name: 'ПТРК',
                type: 'constant',
                children: [
                  {
                    name: 'Стугна',
                    type: 'constant',
                    displayType: 'Засіб: Стугна',
                  },
                  {
                    name: 'Корнет',
                    type: 'constant',
                    displayType: 'Засіб: Корнет',
                  },
                  {
                    name: 'FGM-48 Javelin',
                    type: 'constant',
                    displayType: 'Засіб: FGM-48 Javelin',
                  },
                  {
                    name: 'MILAN',
                    type: 'constant',
                    displayType: 'Засіб: MILAN',
                  },
                  {
                    name: 'TOW',
                    type: 'constant',
                    displayType: 'Засіб: TOW',
                  },
                  {
                    name: 'NLAW',
                    type: 'constant',
                    displayType: 'Засіб: NLAW',
                  },
                ],
              },
              {
                name: 'Гаубиця',
                type: 'constant',
                children: [
                  {
                    name: '85 мм',
                    type: 'constant',
                    displayType: 'Засіб: Гаубиця, 85 мм',
                  },
                  {
                    name: '105 мм',
                    type: 'constant',
                    displayType: 'Засіб: Гаубиця, 105 мм',
                  },
                  {
                    name: '122 мм',
                    type: 'constant',
                    displayType: 'Засіб: Гаубиця, 122 мм',
                  },
                  {
                    name: '152 мм',
                    type: 'constant',
                    displayType: 'Засіб: Гаубиця, 152 мм',
                  },
                  {
                    name: '155 мм',
                    type: 'constant',
                    displayType: 'Засіб: Гаубиця, 155 мм',
                  },
                ],
              },
              {
                name: 'Mk-19',
                type: 'constant',
                displayType: 'Засіб: Mk-19',
              },
              {
                name: 'АГС',
                type: 'constant',
                displayType: 'Засіб: АГС',
              },
              {
                name: 'СПГ',
                type: 'constant',
                displayType: 'Засіб: СПГ',
              },
              {
                name: 'Інше',
                type: 'input',
                displayType: 'Засіб: ',
                shouldDisplayInputValue: true,
              },
            ],
          },
        ],
      },
      {
        name: 'Ціль',
        type: 'constant',
        children: [
          {
            name: 'Ціль',
            type: 'constant',
            children: [
              {
                name: 'Піхота',
                type: 'constant',
                children: [
                  {
                    name: 'Піхота',
                    type: 'constant',
                    displayType: 'Ціль: Піхота',
                  },
                  {
                    name: 'Піхота у русі',
                    type: 'constant',
                    displayType: 'Ціль: Піхота у русі',
                  },
                  {
                    name: 'Піхота в укритті',
                    type: 'constant',
                    displayType: 'Ціль: Піхота в укритті',
                  },
                ],
              },
              {
                name: 'Артилерійський засіб',
                type: 'constant',
                children: [
                  {
                    name: 'Артилерійський засіб',
                    type: 'constant',
                    displayType: 'Ціль: Артилерійський засіб',
                  },
                  {
                    name: 'Міномет',
                    type: 'constant',
                    displayType: 'Ціль: Міномет',
                  },
                  {
                    name: 'Міномет 82 мм',
                    type: 'constant',
                    displayType: 'Ціль: Міномет, 82 мм',
                  },
                  {
                    name: 'Міномет 120 мм',
                    type: 'constant',
                    displayType: 'Ціль: Міномет, 120 мм',
                  },
                  {
                    name: 'Гаубиця',
                    type: 'constant',
                    displayType: 'Ціль: Гаубиця',
                  },
                  {
                    name: 'САУ',
                    type: 'constant',
                    displayType: 'Ціль: САУ',
                  },
                  {
                    name: 'РСЗВ',
                    type: 'constant',
                    displayType: 'Ціль: РСЗВ',
                  },
                ],
              },
              {
                name: 'Техніка',
                type: 'constant',
                children: [
                  {
                    name: 'ББМ',
                    type: 'constant',
                    displayType: 'Ціль: ББМ',
                  },
                  {
                    name: 'ВАТ',
                    type: 'constant',
                    displayType: 'Ціль: ВАТ',
                  },
                  {
                    name: 'ЛАТ',
                    type: 'constant',
                    displayType: 'Ціль: ЛАТ',
                  },
                  {
                    name: 'БМП',
                    type: 'constant',
                    displayType: 'Ціль: БМП',
                  },
                  {
                    name: 'БТР',
                    type: 'constant',
                    displayType: 'Ціль: БТР',
                  },
                  {
                    name: 'МТЛБ',
                    type: 'constant',
                    displayType: 'Ціль: МТЛБ',
                  },
                  {
                    name: 'Танк',
                    type: 'constant',
                    displayType: 'Ціль: Танк',
                  },
                  {
                    name: 'Мотоцикл',
                    type: 'constant',
                    displayType: 'Ціль: Мотоцикл',
                  },
                  {
                    name: 'Квадроцикл',
                    type: 'constant',
                    displayType: 'Ціль: Квадроцикл',
                  },
                  {
                    name: 'Баггі',
                    type: 'constant',
                    displayType: 'Ціль: Баггі',
                  },
                  {
                    name: 'Інженерна техніка',
                    type: 'constant',
                    displayType: 'Ціль: Інженерна техніка',
                  },
                ],
              },
              {
                name: 'Склад',
                type: 'constant',
                children: [
                  {
                    name: 'Склад',
                    type: 'constant',
                    displayType: 'Ціль: Склад',
                  },
                  {
                    name: 'Склад БК',
                    type: 'constant',
                    displayType: 'Ціль: Склад БК',
                  },
                  {
                    name: 'Склад ПММ',
                    type: 'constant',
                    displayType: 'Ціль: Склад ПММ',
                  },
                ],
              },
              {
                name: 'СПГ',
                type: 'constant',
                displayType: 'Ціль: СПГ',
              },
              {
                name: 'АГС',
                type: 'constant',
                displayType: 'Ціль: АГС',
              },
              {
                name: 'ПТРК',
                type: 'constant',
                displayType: 'Ціль: ПТРК',
              },
              {
                name: 'Розрахунок БПЛА',
                type: 'constant',
                displayType: 'Ціль: Розрахунок БПЛА',
              },
              {
                name: 'Бліндаж/укриття',
                type: 'constant',
                displayType: 'Ціль: Бліндаж/укриття',
              },
              {
                name: 'Засіб РЕБ',
                type: 'constant',
                displayType: 'Ціль: Засіб РЕБ',
              },
              {
                name: 'Антена',
                type: 'constant',
                displayType: 'Ціль: Антена',
              },
              {
                name: 'Створення реперу',
                type: 'constant',
                displayType: 'Ціль: Створення реперу',
              },
              {
                name: 'Задимлення',
                type: 'constant',
                displayType: 'Ціль: Задимлення',
              },
              {
                name: 'Мінування',
                type: 'constant',
                displayType: 'Ціль: Мінування',
              },
              {
                name: 'Інша ціль',
                type: 'input',
                displayType: 'Ціль: ',
                shouldDisplayInputValue: true,
              },
            ],
          },
        ],
      },
      {
        name: 'Результат',
        type: 'constant',
        children: [
          {
            name: 'Результат',
            type: 'constant',
            children: [
              {
                name: 'Вогневий вплив',
                type: 'constant',
                displayType: 'Результат: Вогневий вплив',
              },
              {
                name: 'Влучання в ціль',
                type: 'constant',
                displayType: 'Результат: Влучання в ціль',
              },
              {
                name: 'Влучання по координатах',
                type: 'constant',
                displayType: 'Результат: Влучання по координатах',
              },
              {
                name: 'Подавлено',
                type: 'constant',
                displayType: 'Результат: Подавлено',
              },
              {
                name: 'Знищено',
                type: 'constant',
                displayType: 'Результат: Знищено',
              },
              {
                name: 'Пошкоджено',
                type: 'constant',
                displayType: 'Результат: Пошкоджено',
              },
              {
                name: 'Заборона дії',
                type: 'constant',
                displayType: 'Результат: Заборона дії',
              },
              {
                name: 'Репер створено',
                type: 'constant',
                displayType: 'Результат: Репер створено',
              },
              {
                name: 'Задимлено',
                type: 'constant',
                displayType: 'Результат: Задимлено',
              },
              {
                name: 'Заміновано',
                type: 'constant',
                displayType: 'Результат: Заміновано',
              },
              {
                name: 'Результат невідомий',
                type: 'constant',
                displayType: 'Результат: Результат невідомий',
              },
              {
                name: 'Результату немає',
                type: 'constant',
                displayType: 'Результат: Результату немає',
              },
              {
                name: 'Інше',
                type: 'input',
                displayType: 'Результат: ',
                shouldDisplayInputValue: true,
              },
            ],
          },
        ],
      },
      {
        name: 'Координати або квадрат',
        type: 'constant',
        children: [
          {
            name: 'Координати або квадрат',
            type: 'coordinates-square',
          },
        ],
      },
      {
        name: 'Витрати та залишок',
        type: 'constant',
        children: [
          {
            name: 'Витрати та залишок',
            type: 'expenditure-balance',
          },
        ],
      },
      {
        name: 'Додатково',
        type: 'constant',
        children: [{ name: 'Додатково', type: 'notes' }],
      },
    ],
  },
  { ...configUAVOld },
  { ...configUAV },
  {
    id: 407,
    name: 'Бойова готовність',
    options: {
      category: 'steps',
    },
    type: 'constant',
    steps: [
      {
        name: 'Бойова готовність',
        displayType: 'Бойова готовність',
        type: 'constant',
        children: [
          {
            name: 'БГ',
            type: 'constant',
            displayType: 'В бойовій готовності',
          },
          {
            name: 'Не БГ',
            type: 'constant',
            displayType: 'Не БГ',
            children: [
              {
                name: 'Причина',
                displayType: 'Причина: ',
                type: 'input',
                shouldDisplayInputValue: false,
              },
            ],
          },
        ],
      },
      {
        name: 'Додатково',
        type: 'constant',
        children: [{ name: 'Додатково', type: 'notes' }],
      },
    ],
  },
  {
    id: 121,
    name: 'Інше',
    displayType: 'Інше',
    options: {
      category: 'single',
    },
    type: 'constant',
  },
];
