import { defineComponent as _defineComponent } from 'vue'
import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "d-flex justify-space-between align-start pb-3" }
const _hoisted_2 = {
  key: 0,
  class: "headline font-weight-bold"
}

import type { EventType } from '@/types/events';


export default /*@__PURE__*/_defineComponent({
  __name: 'EventBreadcrumbs',
  props: {
    path: {}
  },
  emits: ["goTo", "close-dialog"],
  setup(__props: any) {





return (_ctx: any,_cache: any) => {
  const _component_v_breadcrumbs_item = _resolveComponent("v-breadcrumbs-item")!
  const _component_v_breadcrumbs = _resolveComponent("v-breadcrumbs")!
  const _component_v_btn = _resolveComponent("v-btn")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_v_breadcrumbs, { class: "pt-0 pl-0 pb-0" }, {
      default: _withCtx(() => [
        (!_ctx.path.length)
          ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Оберіть тип"))
          : _createCommentVNode("", true),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.path, (crumb, index) => {
          return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
            (!!crumb?.name)
              ? (_openBlock(), _createBlock(_component_v_breadcrumbs_item, {
                  key: 0,
                  class: "font-weight-medium",
                  onClick: ($event: any) => (_ctx.$emit('goTo', index))
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(crumb.name), 1)
                  ]),
                  _: 2
                }, 1032, ["onClick"]))
              : _createCommentVNode("", true)
          ], 64))
        }), 128))
      ]),
      _: 1
    }),
    _createVNode(_component_v_btn, {
      class: "custom-red-btn mb-0",
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('close-dialog'))),
      density: "compact",
      icon: "mdi-close"
    })
  ]))
}
}

})