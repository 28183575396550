<template>
  <v-dialog max-width="620" opacity="0.9" persistent>
    <template v-slot:activator="{ props: activatorProps }">
      <v-btn
        class="custom-default-btn show-allies"
        bg="blue-grey-lighten-1"
        v-bind="activatorProps"
        icon="mdi-eye"
        :size="smAndUp ? 'large' : 'default'"
        :height="smAndUp ? 44 : 36"
      ></v-btn>
    </template>

    <template v-slot:default="{ isActive }">
      <v-card>
        <v-card-text>
          <slot name="title" />
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="custom-default-btn"
            text="Закрити"
            :size="smAndUp ? 'large' : 'default'"
            @click="isActive.value = false"
          ></v-btn>
          <slot name="default" />
        </v-card-actions>
      </v-card>
    </template>
  </v-dialog>
</template>

<script setup lang="ts">
import { Position } from '@/types/events';
import { useDisplay } from 'vuetify';

interface Props {
  position: Position;
  eventMessage: string;
}

const { smAndUp } = useDisplay();

defineProps<Props>();
</script>

<style scoped>
.show-allies {
  display: none;
}

@media (max-width: 970px) {
  .show-allies {
    display: block;
  }
}
</style>
