<template>
  <v-card variant="flat">
    <v-card-text class="pa-0">
      <v-text-field
        v-model="model"
        :label="label"
        outlined
        hide-details="auto"
        :rules="[rules.maxLength(100)]"
      ></v-text-field>
    </v-card-text>
    <v-card-actions
      class="d-flex justify-end mt-0"
      v-if="type === 'input-optional'"
    >
      <slot />
      <v-btn
        class="custom-green-btn px-10"
        @click="proceed"
        ref="buttonExposeRef"
        :size="smAndUp ? 'large' : 'default'"
        >Далі</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { rules } from '@/utils/rules';
const props = defineProps<{
  label: string;
  type: 'input' | 'input-optional';
  displayType: string | undefined;
  linkedObject: LinkedObjectType | undefined;
}>();

import { LinkedObjectType } from '@/types/events';
import { useDisplay } from 'vuetify';

const { smAndUp } = useDisplay();

const model = defineModel({ required: true });

const emit = defineEmits(['input']);

function proceed() {
  const values: string[] = [];
  const linkedObject: Record<string, string> = {};

  const linkedObjectKey = Object.keys(props.linkedObject ?? {}).filter(
    (i) => i !== 'parentObject',
  )[0];

  if (model.value) {
    values.push(`${props.displayType}${model.value}`);
    if (linkedObjectKey) {
      linkedObject[linkedObjectKey] = model.value as string;
    }
  }

  emit(
    'input',
    values,
    Object.keys(linkedObject).length ? linkedObject : undefined,
  );
}
</script>
