<template>
  <div class="azimuth-section">
    <svg
      class="azimuth-circle elevation-3"
      viewBox="0 0 200 200"
      @click="setAzimuth"
      :style="{ opacity: isAzimuthUnknown ? '0.6' : '1' }"
    >
      <!-- Dotted lines - always show -->
      <line
        v-for="angle in markerAngles"
        :key="angle"
        :x1="100"
        :y1="100"
        :x2="100 + 70 * Math.cos(((angle - 90) * Math.PI) / 180)"
        :y2="100 + 70 * Math.sin(((angle - 90) * Math.PI) / 180)"
        stroke="black"
        stroke-dasharray="4"
      />
      <!-- Degree markers - always show -->
      <text
        v-for="angle in markerAngles"
        :key="'text' + angle"
        :x="100 + 85 * Math.cos(((angle - 90) * Math.PI) / 180)"
        :y="100 + 85 * Math.sin(((angle - 90) * Math.PI) / 180)"
        font-size="12"
        text-anchor="middle"
        dominant-baseline="middle"
      >
        {{ angle }}
      </text>
      <!-- Azimuth arrow - only show when we have valid numeric azimuth -->
      <template v-if="showAzimuthArrow">
        <line
          :x1="100"
          :y1="100"
          :x2="100 + 65 * Math.cos(((Number(azimuth) - 90) * Math.PI) / 180)"
          :y2="100 + 65 * Math.sin(((Number(azimuth) - 90) * Math.PI) / 180)"
          stroke="var(--primary-arrow-color)"
          stroke-width="2"
        />
        <polygon
          :points="getArrowHeadPoints()"
          fill="var(--primary-arrow-color)"
          :transform="polugonArrowRotate"
        />
      </template>
      <template v-if="!azimuthError">
        <path
          data-pw="range-azimuth-path"
          v-for="(path, index) in sectorPaths"
          :key="index"
          :d="path"
          fill="var(--primary-color)"
        />
      </template>
    </svg>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';

const emit = defineEmits(['validateAzimuth']);

const isAzimuthUnknown = defineModel('isAzimuthUnknown');
const azimuth = defineModel('azimuth');

defineProps({
  azimuthError: Boolean,
});

const showAzimuthArrow = computed(
  () =>
    !isAzimuthUnknown.value && !isNaN(Number(azimuth.value)) && azimuth.value,
);

const polugonArrowRotate = computed(
  () =>
    `rotate(180 ${
      100 + 75 * Math.cos(((Number(azimuth.value) - 90) * Math.PI) / 180)
    } ${100 + 75 * Math.sin(((Number(azimuth.value) - 90) * Math.PI) / 180)})`,
);

const computeSectorPath = (startAngle, endAngle, radius = 70) => {
  const startX = 100 + radius * Math.cos(((startAngle - 90) * Math.PI) / 180);
  const startY = 100 + radius * Math.sin(((startAngle - 90) * Math.PI) / 180);
  const endX = 100 + radius * Math.cos(((endAngle - 90) * Math.PI) / 180);
  const endY = 100 + radius * Math.sin(((endAngle - 90) * Math.PI) / 180);

  const largeArcFlag = endAngle - startAngle > 180 ? 1 : 0;

  return `M100,100 L${startX},${startY} A${radius},${radius} 0 ${largeArcFlag},1 ${endX},${endY} Z`;
};

const sectorPaths = computed(() => {
  const rangeAzimuth = azimuth.value.split('-');

  if (rangeAzimuth.length !== 2) return [];

  return [computeSectorPath(rangeAzimuth[0], rangeAzimuth[1])];
});

const markerAngles = [0, 30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330];

const setAzimuth = (event: MouseEvent) => {
  if (isAzimuthUnknown.value) return;

  const target = event.target as SVGElement;
  const { left, top, width, height } = target.getBoundingClientRect();

  const centerX = left + width / 2;
  const centerY = top + height / 2;

  const deltaX = event.clientX - centerX;
  const deltaY = event.clientY - centerY;

  const distanceFromCenter = Math.sqrt(deltaX ** 2 + deltaY ** 2);

  const threshold = 20;

  if (distanceFromCenter > threshold) {
    const radians = Math.atan2(deltaY, deltaX);
    const degrees = (((radians * (180 / Math.PI) + 360) % 360) + 90) % 360;

    azimuth.value = Math.round(degrees).toString();
    emit('validateAzimuth');
  }
};

const getArrowHeadPoints = () => {
  if (isAzimuthUnknown.value || isNaN(Number(azimuth.value))) {
    return '0,0 0,0 0,0'; // Return invisible points
  }

  const length = 80;
  const arrowSize = 10;
  const angle = parseFloat(azimuth.value) - 90;
  const x = 100 + length * Math.cos((angle * Math.PI) / 180);
  const y = 100 + length * Math.sin((angle * Math.PI) / 180);

  const leftX = x - arrowSize * Math.cos(((angle + 150) * Math.PI) / 180);
  const leftY = y - arrowSize * Math.sin(((angle + 150) * Math.PI) / 180);
  const rightX = x - arrowSize * Math.cos(((angle - 150) * Math.PI) / 180);
  const rightY = y - arrowSize * Math.sin(((angle - 150) * Math.PI) / 180);

  return `${x},${y} ${leftX},${leftY} ${rightX},${rightY}`;
};
</script>

<style>
:root {
  --primary-arrow-color: rgba(255, 0, 0, 0.7);
  --primary-color: rgba(255, 0, 0, 0.3);
}
</style>

<style scoped>
.azimuth-section {
  display: flex;
  align-items: center;
  justify-content: center;
}

.azimuth-circle {
  user-select: none;
  cursor: pointer;
  width: 100%;
  max-width: 247px;
  height: auto;
  aspect-ratio: 1 / 1;
  border-radius: 50%;
  border: 2px solid black;
  position: relative;

  @media (max-width: 960px) {
    max-width: 220px;
  }
}
</style>
