<template>
  <v-dialog
    :model-value="dialogVisible"
    @update:model-value="$emit('update:dialog-visible', $event)"
    max-width="600px"
  >
    <v-card>
      <v-toolbar flat>
        <v-toolbar-title>Події для чату {{ chatName }}</v-toolbar-title>
      </v-toolbar>

      <v-card-text>
        <v-progress-circular
          v-if="loading"
          indeterminate
          color="primary"
          class="ma-5"
        ></v-progress-circular>

        <v-treeview
          v-else
          :key="selectionKey"
          v-model:selected="selection"
          :items="items"
          select-strategy="classic"
          item-title="name"
          item-value="id"
          return-object
          selectable
          dense
          @update:selected="onSelectionUpdate"
        ></v-treeview>
      </v-card-text>

      <v-card-actions>
        <v-btn class="custom-red-btn" @click="handleClose">Відмінити</v-btn>
        <v-btn class="custom-green-btn" @click="handleSave">Зберегти</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from 'vue';
import { eventConfig } from '@/config/configResponse';
import { EventType } from '@/types/events';

//deleting extra fields to optimize render
const chatsConfig = eventConfig.map((evt: EventType) => {
  if (evt.steps) {
    return {
      id: evt.id,
      name: evt?.title ?? evt.name,
      type: evt.type,
    };
  } else return evt;
});

const props = defineProps<{
  dialogVisible: boolean;
  chatName: string;
  chatType: 'signal' | 'whatsapp';
  config: any | null;
}>();

const emit = defineEmits<{
  (e: 'update:dialog-visible', value: boolean): void;
  (e: 'save', config: any[]): void;
}>();

watch(
  () => props.config,
  (config) => {
    if (config === undefined) return;
    console.log('config changed');
    selection.value = [];
    loadAndPrepareData();
  },
  { deep: true },
);

onMounted(() => {
  loadAndPrepareData();
  loading.value = false;
});

const selection = ref<EventType[]>([]);
const items = ref<EventType[]>([]);
const loading = ref(true);
const selectionKey = ref(0);

const loadAndPrepareData = () => {
  // Ensure chatsConfig.eventTypes exists and is an array
  items.value = Array.isArray(chatsConfig) ? chatsConfig : [];

  if (props.config && Array.isArray(props.config)) {
    preselectNodes(props.config);
  } else {
    selectAllNodes(items.value);
  }
};

const onSelectionUpdate = (newSelection: EventType[]) => {
  if (Array.isArray(newSelection)) {
    selection.value = newSelection;
  }
};

const handleSave = () => {
  const selectedNodes = selection.value.map((node) => ({
    id: node.id,
    name: node.name,
  }));

  emit('save', selectedNodes);
};

const handleClose = () => {
  emit('update:dialog-visible', false);
};

const preselectNodes = (config: any[]) => {
  if (!Array.isArray(config)) return;

  const validConfig = config.filter(
    (item) =>
      item &&
      typeof item === 'object' &&
      'id' in item &&
      item.id !== undefined &&
      item.id !== null,
  );

  const selectedIds = validConfig.map((item) => item.id);

  const selectNodes = (nodes: EventType[]) => {
    if (!Array.isArray(nodes)) return;

    nodes.forEach((node) => {
      if (selectedIds.includes(node.id)) {
        if (
          !selection.value.find((selectedNode) => selectedNode.id === node.id)
        ) {
          selection.value.push(node);
        }
      }
      if (
        node.children &&
        Array.isArray(node.children) &&
        node.children.length > 0
      ) {
        selectNodes(node.children);
      }
    });
  };

  selectNodes(items.value);
  selection.value = [...selection.value];
  selectionKey.value++;

  // Using nextTick-like behavior
  setTimeout(() => {
    items.value = [...items.value];
  });
};

const selectAllNodes = (nodes: EventType[]) => {
  if (!Array.isArray(nodes)) return;

  const selectAll = (nodesToSelect: EventType[]) => {
    nodesToSelect.forEach((node) => {
      if (
        !selection.value.find((selectedNode) => selectedNode.id === node.id)
      ) {
        selection.value.push(node);
      }
      if (
        node.children &&
        Array.isArray(node.children) &&
        node.children.length > 0
      ) {
        selectAll(node.children);
      }
    });
  };

  selectAll(nodes);
  selectionKey.value++;

  setTimeout(() => {
    items.value = [...items.value];
  });
};
</script>

<style scoped>
.custom-green-btn {
  background-color: #4caf50;
  color: white;
}

.custom-red-btn {
  background-color: #d32f2f;
  color: white;
}
</style>
