<template>
  <v-card variant="flat">
    <v-card-text class="pa-0">
      <template v-for="model in models" :key="model.name">
        <v-list-item class="list-item">
          <v-list-item-content>
            <v-list-item-title
              class="list-item-title"
              @click="model.isCollapsed = !model.isCollapsed"
              >{{ model.ammoType }}
              <v-icon
                :icon="model.isCollapsed ? 'mdi-minus' : 'mdi-plus'"
              ></v-icon
            ></v-list-item-title>
            <event-model-count
              v-if="model.isCollapsed"
              label="Модель"
              v-model:ammoModel="model.ammoModel"
              v-model:ammoAmount="model.ammoAmount"
            />
          </v-list-item-content>
        </v-list-item>
      </template>
    </v-card-text>
    <v-card-actions class="d-flex justify-end mt-0">
      <slot />
      <v-btn
        :disabled="disabled"
        class="custom-green-btn px-10"
        @click="proceed"
        ref="buttonExposeRef"
        :size="smAndUp ? 'large' : 'default'"
        >Далі</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { ref, onMounted, computed } from 'vue';
import EventModelCount from '@/components/EventCreation/EventInputModelCount.vue';
import { useDisplay } from 'vuetify';
import { useEventsSessionStore } from '@/stores/eventSession';

const eventSessionStore = useEventsSessionStore();

const { smAndUp } = useDisplay();

const emit = defineEmits(['input']);

const buttonExposeRef = ref<HTMLButtonElement | null>(null);

defineExpose({
  buttonExposeRef,
});

const props = defineProps({
  typeOfAmmunitions: {
    type: Array<string>,
    default: () => [],
  },
});

const models = ref<
  Array<{
    ammoType: string;
    ammoModel: string;
    ammoAmount: number;
    isCollapsed: boolean;
  }>
>([]);

const disabled = computed(
  () =>
    !models.value.some((model) => model.isCollapsed) ||
    !eventSessionStore.valid,
);

function proceed() {
  const filteredModels = models.value.filter(
    (model) => model.ammoAmount > 0 && model.isCollapsed,
  );

  const ammunitionDetails = filteredModels.map((model) => ({
    ammoModel: model.ammoModel,
    ammoAmount: model.ammoAmount,
    ammoType: model.ammoType,
  }));

  emit(
    'input',
    [
      `БЧ: ${ammunitionDetails
        .map(
          (model) =>
            `${model.ammoType}${
              model.ammoModel ? ` (${model.ammoModel}) ` : ' '
            }${model.ammoAmount} шт`,
        )
        .join(', ')}`,
    ],
    {
      parentObject: 'uavData',
      ammunitionType: ammunitionDetails
        .map((model) => model.ammoType)
        .join(', '),
      ammunitionDetails,
    },
  );
}
onMounted(() => {
  models.value = props.typeOfAmmunitions.map((ammoType) => ({
    ammoType,
    ammoModel: '',
    ammoAmount: 1,
    isCollapsed: false,
  }));
});
</script>

<style scoped>
.list-item {
  padding: 0 !important;
}
.list-item:hover {
  background-color: transparent;
}

.list-item + .list-item {
  margin-top: 8px;
}

.list-item-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  background-color: #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  padding: 12px 16px;
}

.list-item-title:hover {
  background-color: #d0d0d0;
}
</style>
