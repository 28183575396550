import { EventType } from '@/types/events';

type MissionType = 'Ударна' | 'Мінування' | 'Доставка' | 'РКС';

function targetHelper({
  children,
  canShowStream,
}: {
  children: EventType[];
  canShowStream?: boolean;
}): EventType[] {
  const coordinatesSquareTargetDescription: EventType[] = [
    {
      name: 'Координати або квадрат',
      type: 'coordinates-square-target-destruction',
      linkedObject: {
        parentObject: 'uavData',
      },
      children,
      canShowEventPath: false,
      startCreatedAtEventPath: true,
    },
  ];

  return [
    {
      name: 'ОС',
      type: 'constant',
      displayType: 'Ціль: ОС',
      linkedObject: {
        parentObject: 'uavData',
        target: 'ОС',
      },
      children: [
        {
          name: 'Координати або квадрат',
          type: 'coordinates-square-target-destruction',
          linkedObject: {
            parentObject: 'uavData',
          },
          children: resultHelper({
            missionType: 'Ударна',
            isPersonnel: true,
            canShowStream,
          }),
        },
      ],
    },
    {
      name: 'ІФС',
      type: 'constant',
      displayType: 'Ціль: ІФС',
      linkedObject: {
        parentObject: 'uavData',
        target: 'ІФС',
      },
      children: coordinatesSquareTargetDescription,
    },
    {
      name: 'Автомобільна техніка',
      type: 'constant',
      children: [
        {
          name: 'ВАТ',
          type: 'constant',
          displayType: 'Ціль: ВАТ',
          linkedObject: {
            parentObject: 'uavData',
            target: 'ВАТ',
          },

          children: coordinatesSquareTargetDescription,
        },
        {
          name: 'ЛАТ',
          type: 'constant',
          displayType: 'Ціль: ЛАТ',
          linkedObject: {
            parentObject: 'uavData',
            target: 'ЛАТ',
          },
          children: coordinatesSquareTargetDescription,
        },
        {
          name: 'Мотоцикл',
          type: 'constant',
          displayType: 'Ціль: Мотоцикл',
          linkedObject: {
            parentObject: 'uavData',
            target: 'Мотоцикл',
          },
          children: coordinatesSquareTargetDescription,
        },
        {
          name: 'Квадроцикл',
          type: 'constant',
          displayType: 'Ціль: Квадроцикл',
          linkedObject: {
            parentObject: 'uavData',
            target: 'Квадроцикл',
          },
          children: coordinatesSquareTargetDescription,
        },
        {
          name: 'Спец. техніка',
          type: 'constant',
          displayType: 'Ціль: Спец. техніка',
          linkedObject: {
            parentObject: 'uavData',
            target: 'Спец. техніка',
          },
          children: coordinatesSquareTargetDescription,
        },
        {
          name: 'Інше',
          type: 'input',
          displayType: 'Ціль: ',
          shouldDisplayInputValue: true,
          linkedObject: {
            parentObject: 'uavData',
            target: '',
          },
          children: coordinatesSquareTargetDescription,
        },
      ],
    },
    {
      name: 'Арт. система',
      type: 'constant',
      children: [
        {
          name: 'Міномет',
          type: 'constant',
          displayType: 'Ціль: Міномет',
          linkedObject: {
            parentObject: 'uavData',
            target: 'Міномет',
          },
          children: coordinatesSquareTargetDescription,
        },
        {
          name: 'Букс. Артилерія',
          type: 'constant',
          displayType: 'Ціль: Букс. Артилерія',
          linkedObject: {
            parentObject: 'uavData',
            target: 'Букс. Артилерія',
          },
          children: coordinatesSquareTargetDescription,
        },
        {
          name: 'САУ',
          type: 'constant',
          displayType: 'Ціль: САУ',
          linkedObject: {
            parentObject: 'uavData',
            target: 'САУ',
          },
          children: coordinatesSquareTargetDescription,
        },
        {
          name: 'Інше',
          type: 'input',
          displayType: 'Ціль: ',
          shouldDisplayInputValue: true,
          linkedObject: {
            parentObject: 'uavData',
            target: '',
          },
          children: coordinatesSquareTargetDescription,
        },
      ],
    },
    {
      name: 'ББМ',
      type: 'constant',
      displayType: 'Ціль: ББМ',
      linkedObject: {
        parentObject: 'uavData',
        target: 'ББМ',
      },
      children: coordinatesSquareTargetDescription,
    },
    {
      name: 'Танк',
      type: 'constant',
      displayType: 'Ціль: Танк',
      linkedObject: {
        parentObject: 'uavData',
        target: 'Танк',
      },
      children: coordinatesSquareTargetDescription,
    },
    {
      name: 'Радіотехнічне обладнання',
      type: 'constant',
      displayType: 'Ціль: Радіотехнічне обладнання',
      linkedObject: {
        parentObject: 'uavData',
        target: 'Радіотехнічне обладнання',
      },
      children: coordinatesSquareTargetDescription,
    },
    {
      name: 'Склади (БП та ПММ)',
      type: 'constant',
      displayType: 'Ціль: Склади (БП та ПММ)',
      linkedObject: {
        parentObject: 'uavData',
        target: 'Склади (БП та ПММ)',
      },
      children: coordinatesSquareTargetDescription,
    },
    {
      name: 'ТЗ БпЛА',
      type: 'constant',
      displayType: 'Ціль: ТЗ БпЛА',
      linkedObject: {
        parentObject: 'uavData',
        target: 'ТЗ БпЛА',
      },
      children: coordinatesSquareTargetDescription,
    },
    {
      name: 'ППО',
      type: 'constant',
      displayType: 'Ціль: ППО',
      linkedObject: {
        parentObject: 'uavData',
        target: 'ППО',
      },
      children: coordinatesSquareTargetDescription,
    },
    {
      name: 'РСЗВ',
      type: 'constant',
      displayType: 'Ціль: РСЗВ',
      linkedObject: {
        parentObject: 'uavData',
        target: 'РСЗВ',
      },
      children: coordinatesSquareTargetDescription,
    },
    {
      name: 'Плавзасіб',
      type: 'constant',
      displayType: 'Ціль: Плавзасіб',
      linkedObject: {
        parentObject: 'uavData',
        target: 'Плавзасіб',
      },
      children: coordinatesSquareTargetDescription,
    },
    {
      name: 'Інша ціль',
      type: 'input',
      displayType: 'Ціль: ',
      linkedObject: {
        parentObject: 'uavData',
        target: '',
      },
      shouldDisplayInputValue: true,
      children: coordinatesSquareTargetDescription,
    },
  ].map((i) => ({
    ...i,
    canShowEventPath: false,
    startCreatedAtEventPath: true,
  })) as EventType[];
}

function typeOfAmmunitionHelper({
  isFPV,
  children,
}: {
  isFPV: boolean;
  children: EventType[];
}): EventType[] {
  // trigger
  const typeOfAmmunitions: string[] = [
    'Фугасний',
    'Уламковий',
    'Уламково-фугасний',
    'Кумулятивний',
    'Уламково-кумулятивний',
    'Кумулятивно-фугасний',
    'Запалювальний',
    'Термобаричний',
    'Аерозольно-маскувальний',
    'Міна протипіхотна',
    'Міна протитанкова',
  ];
  if (isFPV) {
    return typeOfAmmunitions.map((name) => ({
      name,
      type: 'constant',
      displayType: `БЧ: ${name}`,
      canShowEventPath: true,
      startCreatedAtEventPath: true,
      linkedObject: {
        parentObject: 'uavData',
        ammunitionType: name,
      },
      children: [
        {
          name: 'Марка та к-сть БК',
          type: 'input-model-count',
          linkedObject: {
            parentObject: 'uavData',
            ammunitionType: name,
          },
          children,
        },
      ],
    }));
  }
  return [
    {
      name: 'БК',
      type: 'input-type-model-count',
      linkedObject: {
        parentObject: 'uavData',
      },
      typeOfAmmunitions,
      children,
      canShowEventPath: true,
      startCreatedAtEventPath: true,
    },
  ];
}

function missionHelper({
  missionType,
  children,
}: {
  missionType: MissionType;
  children: EventType[];
}): EventType {
  switch (missionType) {
    case 'Ударна':
      return {
        name: 'Ударна',
        type: 'constant',
        displayType: 'Місія: Ударна',
        canShowEventPath: true,
        linkedObject: {
          parentObject: 'uavData',
          mission: 'Ударна',
        },
        children,
      };
    case 'Мінування':
      return {
        name: 'Мінування',
        type: 'constant',
        displayType: 'Місія: Мінування',
        canShowEventPath: true,
        linkedObject: {
          parentObject: 'uavData',
          mission: 'Мінування',
        },
        children,
      };
    case 'Доставка':
      return {
        name: 'Доставка',
        type: 'constant',
        displayType: 'Місія: Доставка',
        canShowEventPath: true,
        linkedObject: {
          parentObject: 'uavData',
          mission: 'Доставка',
        },
        children,
      };
    case 'РКС':
      return {
        name: 'РКС',
        type: 'constant',
        displayType: 'Місія: РКС',
        canShowEventPath: true,
        linkedObject: {
          parentObject: 'uavData',
          mission: 'РКС',
        },
        children,
      };
  }
}

function resultHelper({
  missionType,
  isPersonnel,
  canShowStream,
}: {
  missionType: MissionType;
  isPersonnel?: boolean;
  canShowStream?: boolean;
}): EventType[] {
  const children: EventType[] = canShowStream
    ? [
        {
          name: 'Стрім/запис',
          displayType: 'Стрім/запис: ',
          type: 'input-optional',
          shouldDisplayInputValue: false,
          linkedObject: {
            parentObject: 'uavData',
            stream: null,
          },
        },
      ]
    : [];
  const actionOfREB: EventType[] = [
    {
      name: 'Втрата управління',
      type: 'constant',
      displayType: 'Результат: Втрата управління',
      linkedObject: {
        parentObject: 'uavData',
        resultDetails: 'Втрата управління',
        result: 'Не успішно',
      },
      children,
      startCreatedAtEventPath: true,
    },
    {
      name: 'Втрата відео',
      type: 'constant',
      displayType: 'Результат: Втрата відео',
      linkedObject: {
        parentObject: 'uavData',
        resultDetails: 'Втрата відео',
        result: 'Не успішно',
      },
      children,
      startCreatedAtEventPath: true,
    },
    {
      name: 'Не успішно з інших причин',
      type: 'constant',
      displayType: 'Результат: Не успішно з інших причин',
      linkedObject: {
        parentObject: 'uavData',
        resultDetails: 'Не успішно з інших причин',
        result: 'Не успішно',
      },
      children,
      startCreatedAtEventPath: true,
    },
  ];

  if (isPersonnel) {
    return [
      {
        name: 'Влучання',
        type: 'constant',
        displayType: 'Результат: Успішно, влучання',
        linkedObject: {
          parentObject: 'uavData',
          resultDetails: 'Влучання',
          result: 'Успішно',
        },
        children: [
          {
            name: '200 - 300',
            type: 'killed-wounded-summary',
            linkedObject: {
              parentObject: 'uavData',
            },
            children,
          },
        ],
        startCreatedAtEventPath: true,
      },
      {
        name: 'Обстріляно',
        type: 'constant',
        displayType: 'Результат: Успішно, обстріляно',
        linkedObject: {
          parentObject: 'uavData',
          resultDetails: 'Обстріляно',
          result: 'Успішно',
        },
        children: [
          {
            name: '200 - 300',
            type: 'killed-wounded-summary',
            linkedObject: {
              parentObject: 'uavData',
            },
            children,
          },
        ],
        startCreatedAtEventPath: true,
      },
      ...actionOfREB,
    ];
  }

  if (missionType === 'Ударна') {
    return [
      {
        name: 'Знищено',
        type: 'constant',
        displayType: 'Результат: Успішно, знищено',
        linkedObject: {
          parentObject: 'uavData',
          resultDetails: 'Знищено',
          result: 'Успішно',
        },
        children: [
          {
            name: '200 - 300',
            type: 'killed-wounded-summary',
            linkedObject: {
              parentObject: 'uavData',
            },
            children,
          },
        ],
        startCreatedAtEventPath: true,
      },
      {
        name: 'Пошкоджено',
        type: 'constant',
        displayType: 'Результат: Успішно, пошкоджено',
        linkedObject: {
          parentObject: 'uavData',
          resultDetails: 'Пошкоджено',
          result: 'Успішно',
        },
        children: [
          {
            name: '200 - 300',
            type: 'killed-wounded-summary',
            linkedObject: {
              parentObject: 'uavData',
            },
            children,
          },
        ],
        startCreatedAtEventPath: true,
      },
      {
        name: 'Влучання',
        type: 'constant',
        displayType: 'Результат: Успішно, влучання',
        linkedObject: {
          parentObject: 'uavData',
          resultDetails: 'Влучання',
          result: 'Успішно',
        },
        children: [
          {
            name: '200 - 300',
            type: 'killed-wounded-summary',
            linkedObject: {
              parentObject: 'uavData',
            },
            children,
          },
        ],
        startCreatedAtEventPath: true,
      },
      {
        name: 'Обстріляно',
        type: 'constant',
        displayType: 'Результат: Успішно, обстріляно',
        linkedObject: {
          parentObject: 'uavData',
          resultDetails: 'Обстріляно',
          result: 'Успішно',
        },
        children: [
          {
            name: '200 - 300',
            type: 'killed-wounded-summary',
            linkedObject: {
              parentObject: 'uavData',
            },
            children,
          },
        ],
        startCreatedAtEventPath: true,
      },
      {
        name: 'Не детон',
        type: 'constant',
        displayType: 'Результат: Не успішно, не детон',
        linkedObject: {
          parentObject: 'uavData',
          resultDetails: 'Не детон',
          result: 'Не успішно',
        },
        children,
        startCreatedAtEventPath: true,
      },
      ...actionOfREB,
    ];
  }
  if (missionType === 'Мінування') {
    return [
      {
        name: 'Заміновано',
        type: 'constant',
        displayType: 'Результат: Успішно, заміновано',
        linkedObject: {
          parentObject: 'uavData',
          resultDetails: 'Заміновано',
          result: 'Успішно',
        },
        children,
        startCreatedAtEventPath: true,
      },

      ...actionOfREB,
    ];
  }

  if (missionType === 'Доставка') {
    return [
      {
        name: 'Доставлено',
        type: 'constant',
        displayType: 'Результат: Успішно, доставлено',
        linkedObject: {
          parentObject: 'uavData',
          resultDetails: 'Доставлено',
          result: 'Успішно',
        },
        children,
        startCreatedAtEventPath: true,
        canShowEventPath: false,
      },

      ...actionOfREB,
    ];
  }

  return [
    {
      name: 'Виконано',
      type: 'constant',
      displayType: 'Результат: Успішно, виконано',
      linkedObject: {
        parentObject: 'uavData',
        resultDetails: 'Виконано',
        result: 'Успішно',
      },
      children,
      startCreatedAtEventPath: true,
      canShowEventPath: false,
    },

    ...actionOfREB,
  ];
}

function handlersWrapper({
  missionType,
  isFPV,
  canShowStream,
}: {
  missionType: MissionType;
  isFPV: boolean;
  canShowStream?: boolean;
}): EventType {
  const result = resultHelper({
    missionType,
    canShowStream,
  }).map((i) => ({
    ...i,
    canShowEventPath: false,
    startCreatedAtEventPath: true,
  })) as EventType[];

  if (missionType === 'Ударна') {
    return missionHelper({
      missionType,
      children: typeOfAmmunitionHelper({
        isFPV: isFPV,
        children: targetHelper({
          children: result,
          canShowStream,
        }),
      }),
    });
  }
  if (missionType === 'Мінування') {
    return missionHelper({
      missionType,
      children: typeOfAmmunitionHelper({
        isFPV: isFPV,
        children: [
          {
            name: 'Координати або квадрат',
            type: 'coordinates-square-target-destruction',
            linkedObject: {
              parentObject: 'uavData',
            },
            children: result,
            canShowEventPath: false,
            startCreatedAtEventPath: true,
          },
        ],
      }),
    });
  }
  if (missionType === 'Доставка') {
    return missionHelper({
      missionType,
      children: [
        {
          name: 'Координати або квадрат',
          type: 'coordinates-square-target-destruction',
          linkedObject: {
            parentObject: 'uavData',
          },
          children: result,
          canShowEventPath: false,
          startCreatedAtEventPath: true,
        },
      ],
    });
  }

  return missionHelper({
    missionType,
    children: [
      {
        name: 'Координати або квадрат',
        type: 'coordinates-square-target-destruction',
        linkedObject: {
          parentObject: 'uavData',
        },
        children: result,
        canShowEventPath: false,
        startCreatedAtEventPath: true,
      },
    ],
  });
}

export const configUAV: EventType = {
  id: 500,
  title: 'Виконано завдання Mavic/Autel',
  name: 'Виконано завдання (БПЛА)',
  displayType: 'Виконано завдання (БПЛА)',
  options: {
    category: 'steps',
    skipMapStep: true,
    saveKey: 'previous-uav-data',
  },
  type: 'constant',
  steps: [
    {
      name: 'Засіб',
      type: 'constant',
      children: [
        {
          name: 'Mavic 3 Pro',
          type: 'constant',
          displayType: 'Засіб: Mavic 3 Pro',
          canShowEventPath: true,
          linkedObject: {
            parentObject: 'uavData',
            uavType: 'Mavic 3 Pro',
          },
          children: [
            handlersWrapper({
              missionType: 'Ударна',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'Мінування',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'Доставка',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'РКС',
              isFPV: false,
            }),
          ],
        },
        {
          name: 'Mavic 3E',
          type: 'constant',
          displayType: 'Засіб: Mavic 3E',
          canShowEventPath: true,
          linkedObject: {
            parentObject: 'uavData',
            uavType: 'Mavic 3E',
          },
          children: [
            handlersWrapper({
              missionType: 'Ударна',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'Мінування',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'Доставка',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'РКС',
              isFPV: false,
            }),
          ],
        },
        {
          name: 'Mavic 3T',
          type: 'constant',
          displayType: 'Засіб: Mavic 3T',
          canShowEventPath: true,
          linkedObject: {
            parentObject: 'uavData',
            uavType: 'Mavic 3T',
          },
          children: [
            handlersWrapper({
              missionType: 'Ударна',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'Мінування',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'Доставка',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'РКС',
              isFPV: false,
            }),
          ],
        },
        {
          name: 'Mavic 3 Classic',
          type: 'constant',
          displayType: 'Засіб: Mavic 3 Classic',
          canShowEventPath: true,
          linkedObject: {
            parentObject: 'uavData',
            uavType: 'Mavic 3 Classic',
          },
          children: [
            handlersWrapper({
              missionType: 'Ударна',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'Мінування',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'Доставка',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'РКС',
              isFPV: false,
            }),
          ],
        },
        {
          name: 'Autel 4T',
          type: 'constant',
          displayType: 'Засіб: Autel 4T',
          canShowEventPath: true,
          linkedObject: {
            parentObject: 'uavData',
            uavType: 'Autel 4T',
          },
          children: [
            handlersWrapper({
              missionType: 'Ударна',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'Мінування',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'Доставка',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'РКС',
              isFPV: false,
            }),
          ],
        },
        {
          name: 'Інше',
          type: 'input',
          displayType: 'Засіб: ',
          canShowEventPath: true,
          linkedObject: {
            parentObject: 'uavData',
            uavType: 'Autel',
          },
          children: [
            handlersWrapper({
              missionType: 'Ударна',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'Мінування',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'Доставка',
              isFPV: false,
            }),
            handlersWrapper({
              missionType: 'РКС',
              isFPV: false,
            }),
          ],
        },
      ],
    },
    {
      name: 'Додатково',
      type: 'constant',
      children: [
        {
          name: 'Додатково',
          type: 'notes',
        },
      ],
    },
  ],
};

// old config

const result: EventType = {
  name: 'Результат',
  type: 'constant',
  children: [
    {
      name: 'Успішно',
      type: 'constant',
      children: [
        {
          name: 'Знищено',
          type: 'constant',
          displayType: 'Результат: Успішно, знищено',
          linkedObject: {
            parentObject: 'uavData',
            resultDetails: 'Знищено',
            result: 'Успішно',
          },
          children: [
            {
              name: '200 - 300',
              type: 'killed-wounded-summary',
              linkedObject: {
                parentObject: 'uavData',
              },
            },
          ],
        },
        {
          name: 'Пошкоджено',
          type: 'constant',
          displayType: 'Результат: Успішно, пошкоджено',
          linkedObject: {
            parentObject: 'uavData',
            resultDetails: 'Пошкоджено',
            result: 'Успішно',
          },
          children: [
            {
              name: '200 - 300',
              type: 'killed-wounded-summary',
              linkedObject: {
                parentObject: 'uavData',
              },
            },
          ],
        },
        {
          name: 'Уражено',
          type: 'constant',
          displayType: 'Результат: Успішно, уражено',
          linkedObject: {
            parentObject: 'uavData',
            resultDetails: 'Уражено',
            result: 'Успішно',
          },
          children: [
            {
              name: '200 - 300',
              type: 'killed-wounded-summary',
              linkedObject: {
                parentObject: 'uavData',
              },
            },
          ],
        },
        {
          name: 'Влучання',
          type: 'constant',
          displayType: 'Результат: Успішно, влучання',
          linkedObject: {
            parentObject: 'uavData',
            resultDetails: 'Влучання',
            result: 'Успішно',
          },
          children: [
            {
              name: '200 - 300',
              type: 'killed-wounded-summary',
              linkedObject: {
                parentObject: 'uavData',
              },
            },
          ],
        },
        {
          name: 'Обстріляно',
          type: 'constant',
          displayType: 'Результат: Успішно, обстріляно',
          linkedObject: {
            parentObject: 'uavData',
            resultDetails: 'Обстріляно',
            result: 'Успішно',
          },
          children: [
            {
              name: '200 - 300',
              type: 'killed-wounded-summary',
              linkedObject: {
                parentObject: 'uavData',
              },
            },
          ],
        },
        {
          name: 'Інше',
          type: 'input',
          shouldDisplayInputValue: true,
          displayType: 'Результат: ',
          linkedObject: {
            parentObject: 'uavData',
            resultDetails: '',
            result: 'Успішно',
          },
          children: [
            {
              name: '200 - 300',
              type: 'killed-wounded-summary',
              linkedObject: {
                parentObject: 'uavData',
              },
            },
          ],
        },
      ],
    },
    {
      name: 'Не успішно',
      type: 'constant',
      children: [
        {
          name: 'Втрата управління',
          type: 'constant',
          displayType: 'Результат: Не успішно, втрата управління',
          linkedObject: {
            parentObject: 'uavData',
            resultDetails: 'Втрата управління',
            result: 'Не успішно',
          },
        },
        {
          name: 'Втрата відео',
          type: 'constant',
          displayType: 'Результат: Не успішно, втрата відео',
          linkedObject: {
            parentObject: 'uavData',
            resultDetails: 'Втрата відео',
            result: 'Не успішно',
          },
        },
        {
          name: 'Не детон',
          type: 'constant',
          displayType: 'Результат: Не успішно, не детон',
          linkedObject: {
            parentObject: 'uavData',
            resultDetails: 'Не детон',
            result: 'Не успішно',
          },
        },
        {
          name: 'Збили',
          type: 'constant',
          displayType: 'Результат: Не успішно, збили',
          linkedObject: {
            parentObject: 'uavData',
            resultDetails: 'Збили',
            result: 'Не успішно',
          },
        },
        {
          name: 'Погодні умови',
          type: 'constant',
          displayType: 'Результат: Не успішно, погодні умови',
          linkedObject: {
            parentObject: 'uavData',
            resultDetails: 'Погодні умови',
            result: 'Не успішно',
          },
        },
        {
          name: 'Не влучили',
          type: 'constant',
          displayType: 'Результат: Не успішно, не влучили',
          linkedObject: {
            parentObject: 'uavData',
            resultDetails: 'Не влучили',
            result: 'Не успішно',
          },
        },
        {
          name: 'Інше',
          displayType: 'Результат: ',
          type: 'input',
          shouldDisplayInputValue: true,
          linkedObject: {
            parentObject: 'uavData',
            resultDetails: '',
            result: 'Не успішно',
          },
        },
      ],
    },
  ],
};

const streamOrRecord: EventType = {
  name: 'Стрім/запис',
  displayType: 'Стрім/запис: ',
  type: 'input',
  shouldDisplayInputValue: false,
  children: [result],
  linkedObject: {
    parentObject: 'uavData',
    stream: '',
  },
};

const numberAmmunition: EventType = {
  name: 'Кількість БК',
  displayType: 'Кількість БК: ',
  type: 'input',
  shouldDisplayInputValue: false,
  children: [streamOrRecord],
};

const combatUnit: EventType = {
  name: 'БЧ',
  type: 'constant',
  children: [
    {
      name: 'Уламкова',
      type: 'constant',
      displayType: 'БЧ: Уламкова',
      linkedObject: {
        parentObject: 'uavData',
        ammunitionType: 'Уламкова',
      },
      children: [numberAmmunition],
    },
    {
      name: 'Фугасна',
      type: 'constant',
      displayType: 'БЧ: Фугасна',
      linkedObject: {
        parentObject: 'uavData',
        ammunitionType: 'Фугасна',
      },
      children: [numberAmmunition],
    },
    {
      name: 'Уламково-фугасна',
      type: 'constant',
      displayType: 'БЧ: Уламково-фугасна',
      linkedObject: {
        parentObject: 'uavData',
        ammunitionType: 'Уламково-фугасна',
      },
      children: [numberAmmunition],
    },
    {
      name: 'Кумулятивна',
      type: 'constant',
      displayType: 'БЧ: Кумулятивна',
      linkedObject: {
        parentObject: 'uavData',
        ammunitionType: 'Кумулятивна',
      },
      children: [numberAmmunition],
    },
    {
      name: 'Кумулятивно-уламкова',
      type: 'constant',
      displayType: 'БЧ: Кумулятивно-уламкова',
      linkedObject: {
        parentObject: 'uavData',
        ammunitionType: 'Кумулятивно-уламкова',
      },
      children: [numberAmmunition],
    },
    {
      name: 'Термобарична',
      type: 'constant',
      displayType: 'БЧ: Термобарична',
      linkedObject: {
        parentObject: 'uavData',
        ammunitionType: 'Термобарична',
      },
      children: [numberAmmunition],
    },
    {
      name: 'Запалювальна',
      type: 'constant',
      displayType: 'БЧ: Запалювальна',
      linkedObject: {
        parentObject: 'uavData',
        ammunitionType: 'Запалювальна',
      },
      children: [numberAmmunition],
    },
    {
      name: 'Газ',
      type: 'constant',
      displayType: 'БЧ: Газ',
      linkedObject: {
        parentObject: 'uavData',
        ammunitionType: 'Газ',
      },
      children: [numberAmmunition],
    },
    {
      name: 'Інше',
      displayType: 'БЧ: ',
      type: 'input',
      shouldDisplayInputValue: true,
      linkedObject: {
        parentObject: 'uavData',
        ammunitionType: '',
      },
      children: [numberAmmunition],
    },
  ],
};

const coordinatesAndSquare: EventType = {
  name: 'Координати або квадрат',
  type: 'coordinates-square',
  linkedObject: {
    parentObject: 'uavData',
  },
  children: [combatUnit],
};
export const configUAVOld: EventType = {
  id: 406,
  name: 'Виконано завдання (БПЛА)',
  displayType: 'Виконано завдання (БПЛА)',
  options: { category: 'steps', skipMapStep: true },
  type: 'constant',
  steps: [
    {
      name: 'Номер вильоту',
      type: 'constant',
      children: [
        {
          name: 'Введіть номер вильоту',
          displayType: 'Номер вильоту: ',
          type: 'input-number',
          shouldDisplayInputValue: false,
          linkedObject: {
            parentObject: 'uavData',
            flyNumber: 0,
          },
        },
      ],
    },
    {
      name: 'Засіб',
      type: 'constant',
      children: [
        {
          name: 'Засіб',
          type: 'constant',
          children: [
            {
              name: 'FPV-дрон',
              type: 'constant',
              displayType: 'Засіб: FPV-дрон',
              linkedObject: {
                parentObject: 'uavData',
                uavType: 'FPV-дрон',
              },
            },
            {
              name: 'FPV-крило',
              type: 'constant',
              displayType: 'Засіб: FPV-крило',
              linkedObject: {
                parentObject: 'uavData',
                uavType: 'FPV-крило',
              },
            },
            {
              name: 'Mavic',
              type: 'constant',
              displayType: 'Засіб: Mavic',
              linkedObject: {
                parentObject: 'uavData',
                uavType: 'Mavic',
              },
            },
            {
              name: 'Кажан',
              type: 'constant',
              displayType: 'Засіб: Кажан',
              linkedObject: {
                parentObject: 'uavData',
                uavType: 'Кажан',
              },
            },
            {
              name: 'Stalker',
              type: 'constant',
              displayType: 'Засіб: Stalker',
              linkedObject: {
                parentObject: 'uavData',
                uavType: 'Stalker',
              },
            },
            {
              name: 'Lucky Strike',
              type: 'constant',
              displayType: 'Засіб: Lucky Strike',
              linkedObject: {
                parentObject: 'uavData',
                uavType: 'Lucky Strike',
              },
            },
            {
              name: 'Vampire',
              type: 'constant',
              displayType: 'Засіб: Vampire',
              linkedObject: {
                parentObject: 'uavData',
                uavType: 'Vampire',
              },
            },
            {
              name: 'Heavy Shot',
              type: 'constant',
              displayType: 'Засіб: Heavy Shot',
              linkedObject: {
                parentObject: 'uavData',
                uavType: 'Heavy Shot',
              },
            },
            {
              name: 'Nemesis',
              type: 'constant',
              displayType: 'Засіб: Nemesis',
              linkedObject: {
                parentObject: 'uavData',
                uavType: 'Nemesis',
              },
            },
            {
              name: 'Сич',
              type: 'constant',
              displayType: 'Засіб: Сич',
              linkedObject: {
                parentObject: 'uavData',
                uavType: 'Сич',
              },
            },
            {
              name: 'Warmate',
              type: 'constant',
              displayType: 'Засіб: Warmate',
              linkedObject: {
                parentObject: 'uavData',
                uavType: 'Warmate',
              },
            },
            {
              name: 'Лінза',
              type: 'constant',
              displayType: 'Засіб: Лінза',
              linkedObject: {
                parentObject: 'uavData',
                uavType: 'Лінза',
              },
            },
            {
              name: 'Інше',
              type: 'input',
              displayType: 'Засіб: ',
              linkedObject: {
                parentObject: 'uavData',
                uavType: '',
              },
              shouldDisplayInputValue: true,
            },
          ],
        },
      ],
    },
    {
      name: 'Місія',
      type: 'constant',
      children: [
        {
          name: 'Місія',
          type: 'constant',
          children: [
            {
              name: 'Ударна',
              type: 'constant',
              displayType: 'Місія: Ударна',
              linkedObject: {
                parentObject: 'uavData',
                mission: 'Ударна',
              },
              children: [
                {
                  name: 'Піхота',
                  type: 'constant',
                  children: [
                    {
                      name: 'Піхота',
                      type: 'constant',
                      displayType: 'Ціль: Піхота',
                      linkedObject: {
                        parentObject: 'uavData',
                        target: 'Піхота',
                      },
                      children: [coordinatesAndSquare],
                    },
                    {
                      name: 'Піхота у русі',
                      type: 'constant',
                      displayType: 'Ціль: Піхота у русі',
                      linkedObject: {
                        parentObject: 'uavData',
                        target: 'Піхота у русі',
                      },
                      children: [coordinatesAndSquare],
                    },
                    {
                      name: 'Піхота в укритті',
                      type: 'constant',
                      displayType: 'Ціль: Піхота в укритті',
                      linkedObject: {
                        parentObject: 'uavData',
                        target: 'Піхота в укритті',
                      },
                      children: [coordinatesAndSquare],
                    },
                  ],
                },
                {
                  name: 'Техніка',
                  type: 'constant',
                  children: [
                    {
                      name: 'ББМ',
                      type: 'constant',
                      displayType: 'Ціль: ББМ',
                      children: [coordinatesAndSquare],
                      linkedObject: {
                        parentObject: 'uavData',
                        target: 'ББМ',
                      },
                    },
                    {
                      name: 'ВАТ',
                      type: 'constant',
                      displayType: 'Ціль: ВАТ',
                      linkedObject: {
                        parentObject: 'uavData',
                        target: 'ВАТ',
                      },
                      children: [coordinatesAndSquare],
                    },
                    {
                      name: 'ЛАТ',
                      type: 'constant',
                      displayType: 'Ціль: ЛАТ',
                      linkedObject: {
                        parentObject: 'uavData',
                        target: 'ЛАТ',
                      },
                      children: [coordinatesAndSquare],
                    },
                    {
                      name: 'БМП',
                      type: 'constant',
                      displayType: 'Ціль: БМП',
                      linkedObject: {
                        parentObject: 'uavData',
                        target: 'БМП',
                      },
                      children: [coordinatesAndSquare],
                    },
                    {
                      name: 'БТР',
                      type: 'constant',
                      displayType: 'Ціль: БТР',
                      linkedObject: {
                        parentObject: 'uavData',
                        target: 'БТР',
                      },
                      children: [coordinatesAndSquare],
                    },
                    {
                      name: 'МТЛБ',
                      type: 'constant',
                      displayType: 'Ціль: МТЛБ',
                      linkedObject: {
                        parentObject: 'uavData',
                        target: 'МТЛБ',
                      },
                      children: [coordinatesAndSquare],
                    },
                    {
                      name: 'Танк',
                      type: 'constant',
                      displayType: 'Ціль: Танк',
                      linkedObject: {
                        parentObject: 'uavData',
                        target: 'Танк',
                      },
                      children: [coordinatesAndSquare],
                    },
                    {
                      name: 'Мотоцикл',
                      type: 'constant',
                      displayType: 'Ціль: Мотоцикл',
                      linkedObject: {
                        parentObject: 'uavData',
                        target: 'Мотоцикл',
                      },
                      children: [coordinatesAndSquare],
                    },
                    {
                      name: 'Квадроцикл',
                      type: 'constant',
                      displayType: 'Ціль: Квадроцикл',
                      linkedObject: {
                        parentObject: 'uavData',
                        target: 'Квадроцикл',
                      },
                      children: [coordinatesAndSquare],
                    },
                    {
                      name: 'Баггі',
                      type: 'constant',
                      displayType: 'Ціль: Баггі',
                      linkedObject: {
                        parentObject: 'uavData',
                        target: 'Баггі',
                      },
                      children: [coordinatesAndSquare],
                    },
                    {
                      name: 'Інженерна техніка',
                      type: 'constant',
                      displayType: 'Ціль: Інженерна техніка',
                      linkedObject: {
                        parentObject: 'uavData',
                        target: 'Інженерна техніка',
                      },
                      children: [coordinatesAndSquare],
                    },
                  ],
                },
                {
                  name: 'Артилерійський засіб',
                  type: 'constant',
                  children: [
                    {
                      name: 'Артилерійський засіб',
                      type: 'constant',
                      displayType: 'Ціль: Артилерійський засіб',
                      linkedObject: {
                        parentObject: 'uavData',
                        target: 'Артилерійський засіб',
                      },
                      children: [coordinatesAndSquare],
                    },
                    {
                      name: 'Міномет',
                      type: 'constant',
                      displayType: 'Ціль: Міномет',
                      linkedObject: {
                        parentObject: 'uavData',
                        target: 'Міномет',
                      },
                      children: [coordinatesAndSquare],
                    },
                    {
                      name: 'Міномет 82 мм',
                      type: 'constant',
                      displayType: 'Ціль: Міномет, 82 мм',
                      linkedObject: {
                        parentObject: 'uavData',
                        target: 'Міномет, 82 мм',
                      },
                      children: [coordinatesAndSquare],
                    },
                    {
                      name: 'Міномет 120 мм',
                      type: 'constant',
                      displayType: 'Ціль: Міномет, 120 мм',
                      linkedObject: {
                        parentObject: 'uavData',
                        target: 'Міномет, 120 мм',
                      },
                      children: [coordinatesAndSquare],
                    },
                    {
                      name: 'Гаубиця',
                      type: 'constant',
                      displayType: 'Ціль: Гаубиця',
                      linkedObject: {
                        parentObject: 'uavData',
                        target: 'Гаубиця',
                      },
                      children: [coordinatesAndSquare],
                    },
                    {
                      name: 'САУ',
                      type: 'constant',
                      displayType: 'Ціль: САУ',
                      linkedObject: {
                        parentObject: 'uavData',
                        target: 'САУ',
                      },
                      children: [coordinatesAndSquare],
                    },
                    {
                      name: 'РСЗВ',
                      type: 'constant',
                      displayType: 'Ціль: РСЗВ',
                      linkedObject: {
                        parentObject: 'uavData',
                        target: 'РСЗВ',
                      },
                      children: [coordinatesAndSquare],
                    },
                  ],
                },

                {
                  name: 'СПГ',
                  type: 'constant',
                  displayType: 'Ціль: СПГ',
                  linkedObject: {
                    parentObject: 'uavData',
                    target: 'СПГ',
                  },
                  children: [coordinatesAndSquare],
                },
                {
                  name: 'АГС',
                  type: 'constant',
                  displayType: 'Ціль: АГС',
                  linkedObject: {
                    parentObject: 'uavData',
                    target: 'АГС',
                  },
                  children: [coordinatesAndSquare],
                },

                {
                  name: 'Розрахунок БПЛА',
                  type: 'constant',
                  displayType: 'Ціль: Розрахунок БПЛА',
                  linkedObject: {
                    parentObject: 'uavData',
                    target: 'Розрахунок БПЛА',
                  },
                  children: [coordinatesAndSquare],
                },

                {
                  name: 'Бліндаж/укриття',
                  type: 'constant',
                  displayType: 'Ціль: Бліндаж/укриття',
                  linkedObject: {
                    parentObject: 'uavData',
                    target: 'Бліндаж/укриття',
                  },
                  children: [coordinatesAndSquare],
                },

                {
                  name: 'Засіб РЕБ',
                  type: 'constant',
                  displayType: 'Ціль: Засіб РЕБ',
                  linkedObject: {
                    parentObject: 'uavData',
                    target: 'Засіб РЕБ',
                  },
                  children: [coordinatesAndSquare],
                },
                {
                  name: 'Антена',
                  type: 'constant',
                  displayType: 'Ціль: Антена',
                  linkedObject: {
                    parentObject: 'uavData',
                    target: 'Антена',
                  },
                  children: [coordinatesAndSquare],
                },

                {
                  name: 'ПТРК',
                  type: 'constant',
                  displayType: 'Ціль: ПТРК',
                  linkedObject: {
                    parentObject: 'uavData',
                    target: 'ПТРК',
                  },
                  children: [coordinatesAndSquare],
                },
                {
                  name: 'Склад',
                  type: 'constant',
                  children: [
                    {
                      name: 'Склад',
                      type: 'constant',
                      displayType: 'Ціль: Склад',
                      linkedObject: {
                        parentObject: 'uavData',
                        target: 'Склад',
                      },
                      children: [coordinatesAndSquare],
                    },
                    {
                      name: 'Склад БК',
                      type: 'constant',
                      displayType: 'Ціль: Склад БК',
                      linkedObject: {
                        parentObject: 'uavData',
                        target: 'Склад БК',
                      },
                      children: [coordinatesAndSquare],
                    },
                    {
                      name: 'Склад ПММ',
                      type: 'constant',
                      displayType: 'Ціль: Склад ПММ',
                      linkedObject: {
                        parentObject: 'uavData',
                        target: 'Склад ПММ',
                      },
                      children: [coordinatesAndSquare],
                    },
                  ],
                },
                {
                  name: 'Інша ціль',
                  type: 'input',
                  displayType: 'Ціль: ',
                  linkedObject: {
                    parentObject: 'uavData',
                    target: '',
                  },
                  shouldDisplayInputValue: true,
                  children: [coordinatesAndSquare],
                },
              ],
            },
            {
              name: 'Мінування',
              type: 'constant',
              displayType: 'Місія: Мінування',
              linkedObject: {
                parentObject: 'uavData',
                mission: 'Мінування',
              },
              children: [
                {
                  name: 'Координати або квадрат',
                  type: 'coordinates-square',
                  linkedObject: {
                    parentObject: 'uavData',
                  },
                  children: [
                    {
                      name: 'Тип мін та кількість',
                      displayType: 'Тип мін та кількість: ',
                      type: 'input',
                      shouldDisplayInputValue: false,
                      linkedObject: {
                        parentObject: 'uavData',
                        ammunitionType: '',
                      },
                      children: [
                        {
                          name: 'Результат',
                          type: 'constant',
                          children: [
                            {
                              name: 'Заміновано',
                              type: 'constant',
                              displayType: 'Результат: Заміновано',
                              linkedObject: {
                                parentObject: 'uavData',
                                resultDetails: 'Заміновано',
                                result: 'Успішно',
                              },
                            },
                            {
                              name: 'Втрата управління',
                              type: 'constant',
                              displayType: 'Результат: Втрата управління',
                              linkedObject: {
                                parentObject: 'uavData',
                                resultDetails: 'Втрата управління',
                                result: 'Не успішно',
                              },
                            },
                            {
                              name: 'Втрата відео',
                              type: 'constant',
                              displayType: 'Результат: Втрата відео',
                              linkedObject: {
                                parentObject: 'uavData',
                                resultDetails: 'Втрата відео',
                                result: 'Не успішно',
                              },
                            },
                            {
                              name: 'Збили',
                              type: 'constant',
                              displayType: 'Результат: Збили',
                              linkedObject: {
                                parentObject: 'uavData',
                                resultDetails: 'Збили',
                                result: 'Не успішно',
                              },
                            },
                            {
                              name: 'Погодні умови',
                              type: 'constant',
                              displayType: 'Результат: Погодні умови',
                              linkedObject: {
                                parentObject: 'uavData',
                                resultDetails: 'Погодні умови',
                                result: 'Не успішно',
                              },
                            },
                            {
                              name: 'Не влучили',
                              type: 'constant',
                              displayType: 'Результат: Не влучили',
                              linkedObject: {
                                parentObject: 'uavData',
                                resultDetails: 'Не влучили',
                                result: 'Не успішно',
                              },
                            },
                            {
                              name: 'Інше',
                              type: 'input',
                              displayType: 'Результат: ',
                              linkedObject: {
                                parentObject: 'uavData',
                                resultDetails: '',
                                result: 'Не успішно',
                              },
                              shouldDisplayInputValue: true,
                            },
                          ],
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              name: 'Доставка',
              type: 'constant',
              displayType: 'Місія: Доставка',
              linkedObject: {
                parentObject: 'uavData',
                mission: 'Доставка',
              },
              children: [
                {
                  name: 'Координати або квадрат',
                  type: 'coordinates-square',
                  linkedObject: {
                    parentObject: 'uavData',
                  },
                  children: [
                    {
                      name: 'Навантаження',
                      displayType: 'Навантаження: ',
                      type: 'input',
                      shouldDisplayInputValue: false,
                      children: [
                        {
                          name: 'Доставлено',
                          type: 'constant',
                          displayType: 'Результат: Доставлено',
                          linkedObject: {
                            parentObject: 'uavData',
                            resultDetails: 'Доставлено',
                            result: 'Успішно',
                          },
                        },
                        {
                          name: 'Втрата управління',
                          type: 'constant',
                          displayType: 'Результат: Втрата управління',
                          linkedObject: {
                            parentObject: 'uavData',
                            resultDetails: 'Втрата управління',
                            result: 'Не успішно',
                          },
                        },
                        {
                          name: 'Втрата відео',
                          type: 'constant',
                          displayType: 'Результат: Втрата відео',
                          linkedObject: {
                            parentObject: 'uavData',
                            resultDetails: 'Втрата відео',
                            result: 'Не успішно',
                          },
                        },
                        {
                          name: 'Збили',
                          type: 'constant',
                          displayType: 'Результат: Збили',
                          linkedObject: {
                            parentObject: 'uavData',
                            resultDetails: 'Збили',
                            result: 'Не успішно',
                          },
                        },
                        {
                          name: 'Погодні умови',
                          type: 'constant',
                          displayType: 'Результат: Погодні умови',
                          linkedObject: {
                            parentObject: 'uavData',
                            resultDetails: 'Погодні умови',
                            result: 'Не успішно',
                          },
                        },
                        {
                          name: 'Не доставили',
                          type: 'constant',
                          displayType: 'Результат: Не доставили',
                          linkedObject: {
                            parentObject: 'uavData',
                            resultDetails: 'Не доставили',
                            result: 'Не успішно',
                          },
                        },
                        {
                          name: 'Інше',
                          type: 'input',
                          displayType: 'Результат: ',
                          linkedObject: {
                            parentObject: 'uavData',
                            resultDetails: '',
                            result: 'Не успішно',
                          },
                          shouldDisplayInputValue: true,
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              name: 'РКС',
              type: 'constant',
              displayType: 'Місія: РКС',
              linkedObject: {
                parentObject: 'uavData',
                mission: 'РКС',
              },
              children: [
                {
                  name: 'Результат',
                  type: 'constant',
                  children: [
                    {
                      name: 'Виконано',
                      type: 'constant',
                      displayType: 'Результат: Виконано',
                      linkedObject: {
                        parentObject: 'uavData',
                        resultDetails: 'Виконано',
                        result: 'Успішно',
                      },
                    },
                    {
                      name: 'Втрата управління',
                      type: 'constant',
                      displayType: 'Результат: Втрата управління',
                      linkedObject: {
                        parentObject: 'uavData',
                        resultDetails: 'Втрата управління',
                        result: 'Не успішно',
                      },
                    },
                    {
                      name: 'Втрата відео',
                      type: 'constant',
                      displayType: 'Результат: Втрата відео',
                      linkedObject: {
                        parentObject: 'uavData',
                        resultDetails: 'Втрата відео',
                        result: 'Не успішно',
                      },
                    },
                    {
                      name: 'Збили',
                      type: 'constant',
                      displayType: 'Результат: Збили',
                      linkedObject: {
                        parentObject: 'uavData',
                        resultDetails: 'Збили',
                        result: 'Не успішно',
                      },
                    },
                    {
                      name: 'Погодні умови',
                      type: 'constant',
                      displayType: 'Результат: Погодні умови',
                      linkedObject: {
                        parentObject: 'uavData',
                        resultDetails: 'Погодні умови',
                        result: 'Не успішно',
                      },
                    },
                    {
                      name: 'Інше',
                      type: 'input',
                      displayType: 'Результат: ',
                      linkedObject: {
                        parentObject: 'uavData',
                        resultDetails: '',
                        result: 'Не успішно',
                      },
                      shouldDisplayInputValue: true,
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    {
      name: 'Додатково',
      type: 'constant',
      children: [
        {
          name: 'Додатково',
          type: 'notes',
        },
      ],
    },
  ],
};
