import Brigade from './Brigade';

class Battalion {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.brigadeId = data.brigadeId;
    this.isService = data.isService;
    this.signalChat = data.signalChat;
    this.whatsappChat = data.whatsappChat;
    this.brigade = data.brigadeId ? new Brigade(data.brigade) : null;
  }
}

export default Battalion;
