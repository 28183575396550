import { defineStore } from 'pinia';
import apiClient from '@/services/axios';
import { formatMGRS } from '@/services/utils';

export const useEventsStore = defineStore('events', {
  state: () => ({
    events: [],
    uavEvents: [],
    eventsCount: 0,
    uavEventsCount: 0,
    positions: {},
    loading: true,
  }),

  getters: {
    formattedEvents: (state) => {
      return state.events.slice().map((event) => {
        const positionName = event.positionName || 'Невідома позиція';

        // delete 31NAA6602100000 with migration from exising events
        const formattedMGRS = ['31NAA6602100000', 'N/A'].includes(event.mgrs)
          ? ''
          : formatMGRS(event.mgrs);

        return {
          ...event,
          position: positionName,
          reporter: {
            callsign: event.reporter?.callsign || 'Невідомий',
          },
          mgrs: formattedMGRS,
        };
      });
    },
    formattedUavEvents: (state) => {
      return state.uavEvents.slice().map((event) => {
        return {
          createdAt: new Date(event.createdAt).toLocaleString(),
          time: new Date(event.time).toLocaleString(),
          mission: event.uavEventData.mission,
          battalion: event.reporter?.squadron?.battalion?.name,
          reporter: event.reporter?.callsign,
          uavType: event.uavEventData.uavType,
          ammunitionType: event.uavEventData.ammunitionType,
          target: event.uavEventData.target,
          targetDetails: event.uavEventData.targetDetails,
          wounded: event.uavEventData.wounded,
          possibleWounded: event.uavEventData.possibleWounded,
          killed: event.uavEventData.killed,
          possibleKilled: event.uavEventData.possibleKilled,
          result: event.uavEventData.result,
          resultDetails: event.uavEventData.resultDetails,
          coordinates: event.uavEventData.coordinates,
          square: event.uavEventData.square,
          stream: event.uavEventData.stream,
          notes: event.notes,
        };
      });
    },
  },

  actions: {
    async fetchEvents(page, limit, startDate, endDate) {
      this.loading = true;
      try {
        const {
          data: { data, count },
        } = await apiClient.getEvents(page, limit, startDate, endDate);
        this.events = data;
        this.eventsCount = count;
      } catch (error) {
        console.error('Error fetching events:', error);
      } finally {
        this.loading = false;
      }
    },

    async fetchUavEvents(page, limit, startDate, endDate) {
      this.loading = true;
      try {
        const {
          data: { data, count },
        } = await apiClient.getUavEvents(page, limit, startDate, endDate);
        this.uavEvents = data;
        this.uavEventsCount = count;
      } catch (error) {
        console.error('Error fetching uav events:', error);
      } finally {
        this.loading = false;
      }
    },
  },
});
