<template>
  <div id="app">
    <router-view />
  </div>
</template>

<script setup>
import { onMounted, onUnmounted } from 'vue';
import { useAdminStore } from '@/stores/admin';
import { useRouter, useRoute } from 'vue-router';
import {
  startVersionPolling,
  stopVersionInterval,
} from '@/services/versionPoll';
import { startRefreshingToken } from '@/services/keycloak';
import { UserRole } from '@/types/admin';

const adminStore = useAdminStore();
const router = useRouter();
const route = useRoute();

onMounted(async () => {
  await adminStore.initialize();
  await redirectUser();
  startRefreshingToken();

  startVersionPolling();
});

onUnmounted(() => {
  stopVersionInterval();
});

async function redirectUser() {
  const defaultRoute = (() => {
    if (UserRole.stat === adminStore.currentUser?.role) return 'Stats';
    if (UserRole.uav_stat === adminStore.currentUser?.role) return 'UAVStats';
    if (UserRole.uav_pilot === adminStore.currentUser?.role) return 'UAVPilot';
    return 'Event';
  })();

  if (route.name !== defaultRoute) {
    router.replace({ name: defaultRoute });
  }
}
</script>
