<template>
  <v-dialog
    :model-value="dialog"
    @update:model-value="handleDialogUpdate"
    :max-width="600"
    :content-class="
      currentType === 'notes' ? 'dialog-events-content-sizes' : ''
    "
    persistent
  >
    <div
      v-if="currentType === 'notes'"
      class="bg-white rounded-lg elevation-1 pa-3 desctop-preview"
    >
      <event-preview-content
        :position="positionData"
        :eventMessage="eventMessage"
        @handleSubmit="handleSubmit"
      />
    </div>

    <event-last-created
      v-if="path.length === 1 && options.saveKey && lastEventPaths.length"
      v-model:show="isShowEventPreviewLastCreatedEventDialog"
      ref="buttonExposeRef"
      :lastEventPaths="lastEventPaths"
      @go-to="reproduceLastCreatedEvent"
    >
      <v-btn
        class="custom-default-btn"
        @click="goBack"
        :size="smAndUp ? 'large' : 'default'"
      >
        Назад
      </v-btn>
    </event-last-created>
    <v-form class="pa-0 ma-0" v-model="eventSessionStore.valid">
      <v-card>
        <v-card-text class="pb-0 pt-3">
          <!-- Алерт -->
          <v-alert
            v-if="alert.show"
            :type="alert.type"
            dismissible
            @update:modelValue="alert.show = false"
          >
            {{ alert.message }}
          </v-alert>

          <event-breadcrumbs
            :path="path"
            @go-to="goTo"
            @close-dialog="closeDialog"
          />

          <!-- Основний контент -->
          <v-container class="pa-0">
            <v-row no-gutters>
              <v-col
                v-for="item in currentOptions"
                :key="item.name"
                cols="12"
                :data-type="item.type ?? 'constant'"
                :data-name="item.name"
                class="event-items"
              >
                <!-- Рендеримо кнопки -->
                <v-list-item
                  v-if="!item.type || item.type === 'constant'"
                  class="list-item-button"
                  @click="handleSelection(item)"
                  :title="item?.title || item.name"
                  :data-pw="item.name"
                />

                <!-- Поле вводу -->
                <event-input
                  v-else-if="
                    item.type === 'input' || item.type === 'input-optional'
                  "
                  :type="item.type"
                  :displayType="item.displayType"
                  :linkedObject="item.linkedObject"
                  :label="item.name!"
                  v-model="inputTypeValue"
                  @update:model-value="() => (selectedItem = item)"
                  @input="
                    (value, linkedObject) =>
                      handleInput(value, item, linkedObject)
                  "
                />

                <event-input-number
                  v-else-if="item.type === 'input-number'"
                  :label="item.name!"
                  v-model="inputTypeValue"
                  @update:model-value="() => (selectedItem = item)"
                />

                <!-- Координати або квадрат -->
                <event-coordinates-square-from
                  v-else-if="
                    item.type === 'coordinates-square' ||
                    item.type === 'coordinates-square-target-destruction'
                  "
                  :type="item.type"
                  ref="buttonExposeRef"
                  @input="
                    (value, linkedObject) =>
                      handleInput(value, item, linkedObject)
                  "
                >
                  <v-btn
                    class="custom-default-btn"
                    @click="goBack"
                    :size="smAndUp ? 'large' : 'default'"
                  >
                    Назад
                  </v-btn>
                </event-coordinates-square-from>

                <!-- Витрати та залишок -->
                <event-expenditure-balance
                  v-else-if="item.type === 'expenditure-balance'"
                  @input="(value) => handleInput(value, item)"
                  ref="buttonExposeRef"
                >
                  <v-btn
                    class="custom-default-btn"
                    :size="smAndUp ? 'large' : 'default'"
                    @click="goBack"
                  >
                    Назад
                  </v-btn>
                </event-expenditure-balance>

                <event-killed-and-wounded-summary
                  v-else-if="item.type === 'killed-wounded-summary'"
                  ref="buttonExposeRef"
                  @input="
                    (value, linkedObject) =>
                      handleInput(value, item, linkedObject)
                  "
                >
                  <v-btn
                    class="custom-default-btn"
                    :size="smAndUp ? 'large' : 'default'"
                    @click="goBack"
                  >
                    Назад
                  </v-btn>
                </event-killed-and-wounded-summary>

                <event-model-count
                  v-if="item.type === 'input-model-count'"
                  ref="buttonExposeRef"
                  :ammoType="(item.linkedObject!.ammunitionType as string)"
                  @input="
                    (value, linkedObject) =>
                      handleInput(value, item, linkedObject)
                  "
                >
                  <v-btn
                    v-if="path.length > 0"
                    class="custom-default-btn"
                    @click="goBack"
                    :size="smAndUp ? 'large' : 'default'"
                  >
                    Назад
                  </v-btn>
                </event-model-count>

                <event-type-model-count
                  v-if="item.type === 'input-type-model-count'"
                  ref="buttonExposeRef"
                  :typeOfAmmunitions="item.typeOfAmmunitions"
                  @input="
                    (value, linkedObject) =>
                      handleInput(value, item, linkedObject)
                  "
                >
                  <v-btn
                    v-if="path.length > 0"
                    class="custom-default-btn"
                    @click="goBack"
                    :size="smAndUp ? 'large' : 'default'"
                  >
                    Назад
                  </v-btn>
                </event-type-model-count>

                <!-- Карта -->
                <map-selection-view
                  v-if="item.type === 'map'"
                  :dialog="dialog"
                  @location-selected="handleLocationSelected"
                  @update:dialog="closeDialog"
                  ref="buttonExposeRef"
                >
                  <template v-slot:title>
                    <event-breadcrumbs
                      :path="path"
                      @go-to="goTo"
                      @close-dialog="closeDialog"
                    />
                  </template>
                  <template v-slot:action>
                    <v-btn
                      class="custom-default-btn"
                      :size="smAndUp ? 'large' : 'default'"
                      @click="goBack"
                    >
                      Назад
                    </v-btn>
                  </template>
                </map-selection-view>

                <!-- Нотатки -->
                <template v-else-if="item.type === 'notes'">
                  <event-notes-form
                    :position="positionData"
                    :eventMessage="eventMessage"
                    :show-allies="Boolean(options?.allies)"
                    :show-enemies="Boolean(options?.enemies)"
                  />
                </template>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions
          class="px-sm-6 pt-3 pb-2 d-flex justify-end mt-0"
          v-if="actionsView && path.length"
        >
          <event-preview-dialog
            :position="positionData"
            :eventMessage="eventMessage"
            v-if="currentType === 'notes'"
          >
            <template v-slot:title>
              <event-preview-content
                :position="positionData"
                :eventMessage="eventMessage"
                @handleSubmit="handleSubmit"
              />
            </template>
            <template v-slot:default>
              <v-btn
                v-if="currentType === 'notes'"
                :disabled="eventStore.loading"
                @click="handleSubmit"
                :size="smAndUp ? 'large' : 'default'"
                class="custom-green-btn"
              >
                Створити подію
              </v-btn>
            </template>
          </event-preview-dialog>

          <v-btn
            v-if="path.length > 0"
            class="custom-default-btn"
            @click="goBack"
            :size="smAndUp ? 'large' : 'default'"
          >
            Назад
          </v-btn>
          <v-btn
            v-if="inputType"
            ref="buttonExposeRef"
            class="custom-green-btn px-10"
            @click="handleNextButtonForInputType"
            :size="smAndUp ? 'large' : 'default'"
            :disabled="!eventSessionStore.valid || !inputTypeValue"
          >
            Далі
          </v-btn>
          <v-btn
            v-if="currentType === 'notes'"
            :disabled="eventStore.loading"
            @click="handleSubmit"
            :size="smAndUp ? 'large' : 'default'"
            class="custom-green-btn"
          >
            Створити подію
          </v-btn>
        </v-card-actions>
        <div v-else class="mt-3"></div> </v-card
    ></v-form>
  </v-dialog>
</template>

<script setup lang="ts">
import {
  ref,
  watch,
  nextTick,
  computed,
  onMounted,
  onBeforeUnmount,
  onUnmounted,
} from 'vue';
import { useDisplay } from 'vuetify';
import type {
  EventType,
  EventStep,
  EventOptions,
  Position,
  EventLocation,
  EventPath,
  LinkedObjectType,
} from '@/types/events';
import _omitBy from 'lodash.omitby';
import _isMatch from 'lodash.ismatch';
import { useEventCreationStore } from '@/stores/eventCreation';
import { useEventsSessionStore } from '@/stores/eventSession';
import MapSelectionView from '@/components/MapSelectionView.vue';
import EventNotesForm from '@/components/EventCreation/EventNotesForm.vue';
import EventInput from '@/components/EventCreation/EventInput.vue';
import EventCoordinatesSquareFrom from '@/components/EventCreation/EventCoordinatesSquareFrom.vue';
import EventExpenditureBalance from '@/components/EventCreation/EventExpenditureBalance.vue';
import EventBreadcrumbs from '@/components/EventCreation/EventBreadcrumbs.vue';
import EventKilledAndWoundedSummary from '@/components/EventCreation/EventKilledAndWoundedSummary.vue';
import EventInputNumber from '@/components/EventCreation/EventInputNumber.vue';
import EventPreviewDialog from '@/components/EventCreation/EventPreviewDialog.vue';
import EventPreviewContent from '@/components/EventCreation/EventPreviewContent.vue';
import EventTypeModelCount from '@/components/EventCreation/EventTypeModelCount.vue';
import EventModelCount from '@/components/EventCreation/EventModelCount.vue';
import EventLastCreated from '@/components/EventCreation/EventLastCreated.vue';
import { eventConfig } from '@/config/configResponse';

const { smAndUp } = useDisplay();

const props = defineProps<{
  dialog: boolean;
  position: Position;
  currentOption?: EventType;
  currentOptions?: EventType[];
}>();

const emit = defineEmits<{
  'update:dialog': [value: boolean];
  'event-success': [message: string];
  'event-error': [message: string];
  close: [];
  select: [selection: EventType | string[] | string];
}>();

const buttonExposeRef = ref();

const eventStore = useEventCreationStore();
const eventSessionStore = useEventsSessionStore();

const positionData = ref({ ...props.position });
const alert = ref({ show: false, message: '', type: 'info' });
const currentOptions = ref<EventType[]>(props?.currentOptions ?? eventConfig);

const inputTypeValue = ref('');
const selectedItem = ref<EventType | null>(null);

const step = ref(0);
const path = ref<EventPath[]>([]);
const history = ref<EventType[][]>([]);
const submitThrottle = ref(false);
const isShowEventPreviewLastCreatedEventDialog = ref(true);

const currentType = ref<EventStep | ''>('');
const options = ref<EventOptions>({
  category: 'single',
});

const actionsView = computed(
  () =>
    ![
      'expenditure-balance',
      'coordinates-square',
      'killed-wounded-summary',
      'input-type-model-count',
      'input-model-count',
      'coordinates-square-target-destruction',
      'input-optional',
    ].includes(currentType.value),
);

const lastEventPaths = computed(() => {
  if (options.value.saveKey) {
    return eventSessionStore.lastEventPath(options.value.saveKey);
  }
  return [];
});

const eventMessage = computed(() =>
  getDisplayTypeInPaths(path.value.filter((i) => !i.ignoreDisplayType)).join(
    '\n',
  ),
);

const inputType = computed(() =>
  currentOptions.value.find((item) =>
    ['input-number', 'input'].includes(item.type),
  ),
);

function handleNextButtonForInputType() {
  if (!selectedItem.value) return;

  if (Object.keys(selectedItem.value?.linkedObject ?? {}).length) {
    let linkedObject = selectedItem.value.linkedObject!;
    const linkedObjectKey = Object.keys(linkedObject).filter(
      (i) => i !== 'parentObject',
    )[0];

    handleInput([`${selectedItem.value.displayType}${inputTypeValue.value}`], {
      ...selectedItem.value,
      linkedObject: {
        ...linkedObject,
        [linkedObjectKey]: inputTypeValue.value,
      },
    });
    return;
  }

  handleInput(
    [`${selectedItem.value.displayType}${inputTypeValue.value}`],
    selectedItem.value,
  );
}

function handleSelection(item: EventType, isGoTo = false) {
  if (!isGoTo) {
    updatePathAndHistory(item);
  }
  updateStoreState(item);

  if (shouldAddDisplayType(item)) {
    if (item?.linkedObject) {
      addFieldToPath({
        selectDisplayType: [item.displayType!],
        selectLinkedObject: item.linkedObject as LinkedObjectType,
      });
    } else {
      addFieldToPath({ selectDisplayType: [item.displayType!] });
    }
  }

  if (isStepsCategory(item)) {
    handleStepsCategory(item);
  } else if (hasChildren(item)) {
    handleChildrenCategory(item);
  } else {
    goNextStep();
  }
}

function updatePathAndHistory(item: EventType) {
  path.value.push({ ...item, step: step.value });
  history.value.push([...currentOptions.value]);
}

function updateStoreState(item: EventType) {
  if (item.id) {
    eventStore.$patch({ selectedId: item.id });
  }

  if (item.options?.category) {
    updateOptionState(item.options); // Оновлення опцій на основі категорії
    eventStore.$patch({ type: item.name });
  }

  if (item.displayType && step.value === 0) {
    eventStore.$patch({
      eventMessage: item.displayType,
      description: item.displayType,
    });
  }
}

function isStepsCategory(item: EventType): boolean {
  return item.options?.category === 'steps';
}

function handleStepsCategory(item: EventType) {
  currentOptions.value = item.steps![0]?.children || [];
  step.value = 1;

  if (step.value === 1) {
    eventStore.$patch({ eventMessage: item.name });
  }
}

function hasChildren(item: EventType): boolean {
  return !!item.children?.length;
}

function handleChildrenCategory(item: EventType) {
  currentOptions.value = item.children!;
}

function shouldAddDisplayType(item: EventType): boolean {
  return !!item.displayType && step.value > 0;
}

function updateOptionState(optionsProps: EventOptions) {
  if (optionsProps.category) {
    options.value = {
      ...options.value,
      category: optionsProps.category,
    };
  }

  if (optionsProps.skipMapStep) {
    options.value = {
      ...options.value,
      skipMapStep: optionsProps.skipMapStep,
    };
  }

  if (optionsProps.allies) {
    options.value = {
      ...options.value,
      allies: optionsProps.allies,
    };
  }

  if (optionsProps.enemies) {
    options.value = {
      ...options.value,
      enemies: optionsProps.enemies,
    };
  }

  if (
    optionsProps.isUnderAttack !== undefined ||
    optionsProps.isUnderFire !== undefined
  ) {
    positionData.value = {
      ...positionData.value,
      ...(optionsProps.isUnderAttack !== undefined && {
        isUnderAttack: optionsProps.isUnderAttack,
      }),
      ...(optionsProps.isUnderFire !== undefined && {
        isUnderFire: optionsProps.isUnderFire,
      }),
    };
  }

  if (optionsProps.saveKey) {
    options.value = {
      ...options.value,
      saveKey: optionsProps.saveKey,
    };
  }
}

function addFieldToPath({
  selectDisplayType,
  selectLinkedObject,
}: {
  selectLinkedObject?: LinkedObjectType;
  selectDisplayType: string[];
}) {
  const pathLastItem = path.value.at(-1)!;
  const pathLength = path.value.length - 1;
  const newPathValue =
    pathLength > 0
      ? [
          ...path.value.slice(0, pathLength),
          { ...pathLastItem, selectDisplayType, selectLinkedObject },
        ]
      : [{ ...pathLastItem, selectDisplayType, selectLinkedObject }];
  path.value = newPathValue;
}

// Обробка введення даних з інпутів та форм вводу
function handleInput(value: string[], item: EventType, linkedObject?: any) {
  const name = item.shouldDisplayInputValue ? inputTypeValue.value : item.name;

  if (options.value.category === 'single') {
    handleSelection({ ...item, displayType: value.join('\n'), name });
    return;
  }
  // від зміни порядку виконнання залежить правильність відображення
  handleSelection({ ...item, name });
  if (item?.linkedObject) {
    addFieldToPath({
      selectDisplayType: value,
      selectLinkedObject:
        !!linkedObject && item.linkedObject
          ? (_omitBy(
              linkedObject,
              (value) => value === null,
            ) as LinkedObjectType)
          : (_omitBy(
              item.linkedObject,
              (value) => value === null,
            ) as LinkedObjectType),
    });
  } else {
    addFieldToPath({ selectDisplayType: value });
  }
}

function goTo(index: number) {
  if (index === 0) {
    resetToInitialState();
    return;
  }

  path.value = path.value.slice(0, index);
  history.value = history.value.slice(0, index);

  const pathValue = path.value.at(-1)!;

  step.value = pathValue.step;

  handleSelection(pathValue, true);
}

//Скидає поточний стан та відновлює останню створену подію, обробляючи її для подальшої взаємодії
function reproduceLastCreatedEvent(event: EventType[]) {
  resetToInitialState();
  event.forEach((item) => handleSelection(item));
}

function goBack() {
  if (path.value.length > 1) {
    goTo(path.value.length - 1);
  } else {
    resetToInitialState();
  }
}

// Перехід до наступного кроку
function goNextStep() {
  if (path.value.length > 1) {
    isShowEventPreviewLastCreatedEventDialog.value = false;
  }
  if (step.value > 0) {
    const nextStep = path.value[0]?.steps?.[step.value];
    if (nextStep) {
      currentOptions.value = nextStep.children || [];
      step.value++;
    }
  } else {
    //Якщо більше немає кроків,або вкладених елементів children, то переходимо до останнього кроку (notes або map)
    currentOptions.value = [
      { type: options.value?.skipMapStep ? 'notes' : 'map' },
    ];
  }
}

function resetToInitialState() {
  currentOptions.value = props?.currentOptions ?? eventConfig; // Початкові опції
  path.value = []; // Скидаємо шлях
  history.value = []; // Скидаємо історію
  step.value = 0; // Початковий крок
  currentType.value = ''; // Початковий тип
  options.value = { category: 'single' }; // Початкові опції
  positionData.value = { ...props.position }; // Початкові дані про позицію
  isShowEventPreviewLastCreatedEventDialog.value = true; // Скидаємо стан перегляду попередньої події
}

// Показ алерту
function showAlert(message: string, type: string = 'info') {
  alert.value = { show: true, message, type };
}

function getDisplayTypeInPaths(
  path: Pick<EventPath, 'selectDisplayType' | 'children' | 'steps'>[],
): string[] {
  const result: string[][] = [];

  function traverse(
    event: Pick<EventPath, 'selectDisplayType' | 'children' | 'steps'>,
  ): void {
    if (event?.selectDisplayType?.length) {
      result.push([...event.selectDisplayType]);
    }
    if (event.children?.length) {
      event.children.forEach(traverse);
    }
    if (event.steps?.length) {
      event.steps.forEach(traverse);
    }
  }

  path.forEach(traverse);

  return result.flat(Infinity) as string[];
}

function getLinkedObjectsInPaths(
  path: Pick<EventPath, 'children' | 'steps' | 'selectLinkedObject'>[],
) {
  let result = {};

  function traverse(
    event: Pick<EventPath, 'children' | 'steps' | 'selectLinkedObject'>,
  ): void {
    if (event.selectLinkedObject) {
      result = { ...result, ...event.selectLinkedObject };
    }
    if (event.children?.length) {
      event.children.forEach(traverse);
    }
    if (event.steps?.length) {
      event.steps.forEach(traverse);
    }
  }

  path.forEach(traverse);

  if ('parentObject' in result) {
    const { parentObject, ...linkedObject } = result;
    result = { [parentObject as string]: linkedObject };
  } else {
    result = {};
  }

  return result;
}

// Завершення
async function handleSubmit() {
  if (submitThrottle.value) return;
  submitThrottle.value = false;
  const linkedObject = getLinkedObjectsInPaths(path.value);

  eventStore.$patch({
    chatMessage: eventMessage.value,
  });

  if (options.value.saveKey) {
    eventSessionStore.setPath(options.value.saveKey, path.value);
  }

  const result = await eventStore.createEvent(positionData.value, linkedObject);

  if (result.success) {
    emit('event-success', result.message);
    closeDialog();
  } else {
    showAlert(result.message, 'error');
  }
}

// Оновлення діалогу
function handleDialogUpdate(value: boolean) {
  if (!value) closeDialog();
}

// Обробка вибору локації
async function handleLocationSelected(location: EventLocation) {
  eventStore.$patch({ location });

  // додавання кроку до шляху
  path.value.push({ name: 'Координати', type: 'map', step: step.value });
  history.value.push([...currentOptions.value]);

  // перехід до останнього кроку
  currentOptions.value = [{ type: 'notes' }];
}

// Закриття діалогу
function closeDialog() {
  submitThrottle.value = true;
  nextTick(() => {
    eventStore.reset();
    emit('update:dialog', false);
    emit('close');
  });
}

// --- Спостереження ---
watch(
  () => props.dialog,
  (newValue) => {
    if (!newValue) closeDialog();
  },
);

watch(
  () => props.position,
  (newValue) => {
    positionData.value = { ...newValue };
  },
);

watch(currentOptions, (value) => {
  const type = value[0]?.type || '';
  currentType.value = type;
  // Обнуляємо значення поля вводу при зміні опцій
  inputTypeValue.value = '';
  selectedItem.value = null;
});

function handleEnterPress(e: KeyboardEvent) {
  if (e.key !== 'Enter') return;

  const inputButton = buttonExposeRef.value?.$el;
  const buttonExpose = buttonExposeRef.value?.[0]?.buttonExposeRef?.$el;

  if (buttonExpose) {
    buttonExpose.click();
  }

  if (inputType.value) {
    inputButton?.click();
  }
}

onMounted(() => {
  // якщо props.currentOption існує, то починаємо подію з цього елементу
  if (props.currentOption) {
    handleSelection(props.currentOption);
  }
  window.addEventListener('keypress', handleEnterPress);
});

onBeforeUnmount(() => {
  window.removeEventListener('keypress', handleEnterPress);
});

onUnmounted(() => {
  window.removeEventListener('keypress', handleEnterPress);
});
</script>

<style scoped>
.headline {
  font-weight: bold;
}

.custom-green-btn {
  background-color: #4caf50;
  color: white;
}

.custom-red-btn {
  background-color: #f44336;
  color: white;
}

.event-items + .event-items {
  margin-top: 8px;
}

.v-breadcrumbs-item:hover {
  text-decoration: underline;
}

.list-item-button {
  background-color: #e0e0e0;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.2s ease;
  padding: 12px 16px;
}

.list-item-button:hover {
  background-color: #d0d0d0;
}

.desctop-preview {
  min-width: 320px;
  max-width: 320px;
  position: absolute;
  left: -332px;
  top: 0;
  @media (max-width: 970px) {
    display: none;
  }
}
</style>
