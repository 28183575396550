<template>
  <v-app>
    <!-- App Bar -->
    <v-app-bar app elevation="4">
      <v-app-bar-nav-icon
        v-if="availableTabs.length > 1"
        @click="drawer = !drawer"
      ></v-app-bar-nav-icon>
      <v-toolbar-title
        >A2 <span class="small-text">v.{{ version }}</span></v-toolbar-title
      >
      <v-chip
        color="primary"
        text-color="white"
        class="mr-4"
        v-if="adminStore.currentUser"
      >
        <v-icon start>mdi-account</v-icon>
        {{ adminStore.currentUser.callsign }}
      </v-chip>
      <v-btn @click="logout" color="primary" class="custom-btn"> Вихід </v-btn>
    </v-app-bar>

    <!-- Navigation Drawer -->
    <v-navigation-drawer v-if="availableTabs.length > 1" v-model="drawer" app>
      <v-list dense>
        <v-list-item
          v-for="tab in availableTabs"
          :key="tab.name"
          :to="{ name: tab.name }"
          @click="drawer = false"
          :value="tab.name"
        >
          <template v-slot:prepend>
            <v-icon>{{ tab.icon }}</v-icon>
          </template>
          <v-list-item-title>{{ tab.title }}</v-list-item-title>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>

    <!-- Main Content -->
    <v-main>
      <router-view></router-view>
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useAdminStore } from '@/stores/admin';
import { logout } from '@/services/keycloak';
import version from '@/version';
import { UserRole } from '@/types/admin';

// State
const adminStore = useAdminStore();
const drawer = ref(false);

// Navigation tabs configuration
const tabs = [
  {
    name: 'Event',
    title: 'Події',
    icon: 'mdi-calendar',
    access: (role: UserRole) =>
      [
        UserRole.user,
        UserRole.admin,
        UserRole.battalion_admin,
        UserRole.squadron_admin,
      ].includes(role),
  },
  {
    name: 'Stats',
    title: 'Статистика',
    icon: 'mdi-chart-bar',
    access: (role: UserRole) =>
      [
        UserRole.stat,
        UserRole.admin,
        UserRole.battalion_admin,
        UserRole.squadron_admin,
      ].includes(role),
  },
  {
    name: 'UAVStats',
    title: 'Статистика БПЛА',
    icon: 'mdi-chart-bar',
    access: (role: UserRole) =>
      [UserRole.uav_stat, UserRole.admin, UserRole.battalion_admin].includes(
        role,
      ),
  },
  {
    name: 'Admin',
    title: 'Адміністрування',
    icon: 'mdi-account-cog',
    access: (role: UserRole) =>
      [
        UserRole.admin,
        UserRole.battalion_admin,
        UserRole.squadron_admin,
      ].includes(role),
  },
];

// Computed
const availableTabs = computed(() => {
  return tabs.filter(
    (tab) =>
      adminStore.currentUser?.role && tab.access(adminStore.currentUser?.role),
  );
});
</script>

<style scoped>
.v-list-item--active {
  background-color: darkgray;
  color: white;
}

.v-list-item--active .v-icon {
  color: white;
}

.custom-btn {
  margin-right: 16px;
}
.small-text {
  font-size: 13px;
  font-weight: normal;
}
</style>
