import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, unref as _unref, createTextVNode as _createTextVNode } from "vue"

import { ref, computed } from 'vue';
import { useDisplay } from 'vuetify';

type LinkedObjectType = {
  coordinates?: string;
  square?: string;
  targetDetails?: string;
};


export default /*@__PURE__*/_defineComponent({
  __name: 'EventCoordinatesSquareFrom',
  props: {
    type: {}
  },
  emits: ['input'],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const { smAndUp } = useDisplay();

const emit = __emit;


const square = ref('');
const coordinates = ref('');
const targetDestruction = ref('');
const disabled = computed(() => !coordinates.value && !square.value);
const buttonExposeRef = ref<HTMLButtonElement | null>(null);

__expose({
  buttonExposeRef,
});

function proceed() {
  const values = [];
  const linkedObject: LinkedObjectType = {};

  if (targetDestruction.value) {
    values.push(`Опис цілі: ${targetDestruction.value}`);
    linkedObject.targetDetails = targetDestruction.value;
  }
  if (coordinates.value) {
    values.push(`Координати: ${coordinates.value}`);
    linkedObject.coordinates = coordinates.value;
  }

  if (square.value) {
    values.push(`Квадрат: ${square.value}`);
    linkedObject.square = square.value;
  }

  emit(
    'input',
    values,
    Object.keys(linkedObject).length ? linkedObject : undefined,
  );
}

return (_ctx: any,_cache: any) => {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_responsive = _resolveComponent("v-responsive")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { variant: "flat" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, { class: "pa-0" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_responsive, {
            class: "mx-auto",
            width: "100%"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                label: "Координати",
                modelValue: coordinates.value,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((coordinates).value = $event)),
                type: "text"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_responsive, {
            class: "mx-auto",
            width: "100%"
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_text_field, {
                label: "Квадрат",
                modelValue: square.value,
                "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((square).value = $event)),
                type: "text"
              }, null, 8, ["modelValue"])
            ]),
            _: 1
          }),
          (_ctx.type === 'coordinates-square-target-destruction')
            ? (_openBlock(), _createBlock(_component_v_responsive, {
                key: 0,
                class: "mx-auto",
                width: "100%"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_text_field, {
                    label: "Опис цілі",
                    modelValue: targetDestruction.value,
                    "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((targetDestruction).value = $event)),
                    type: "text"
                  }, null, 8, ["modelValue"])
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_v_card_actions, { class: "d-flex justify-end mt-0" }, {
        default: _withCtx(() => [
          _renderSlot(_ctx.$slots, "default"),
          _createVNode(_component_v_btn, {
            class: "custom-green-btn px-10",
            onClick: proceed,
            disabled: disabled.value,
            size: _unref(smAndUp) ? 'large' : 'default',
            ref_key: "buttonExposeRef",
            ref: buttonExposeRef
          }, {
            default: _withCtx(() => _cache[3] || (_cache[3] = [
              _createTextVNode("Далі")
            ])),
            _: 1
          }, 8, ["disabled", "size"])
        ]),
        _: 3
      })
    ]),
    _: 3
  }))
}
}

})