<template>
  <v-card variant="flat">
    <v-card-text class="pa-0">
      <v-responsive class="mx-auto" width="100%">
        <v-text-field label="Координати" v-model="coordinates" type="text" />
      </v-responsive>
      <v-responsive class="mx-auto" width="100%">
        <v-text-field label="Квадрат" v-model="square" type="text" />
      </v-responsive>
      <v-responsive
        class="mx-auto"
        width="100%"
        v-if="type === 'coordinates-square-target-destruction'"
      >
        <v-text-field
          label="Опис цілі"
          v-model="targetDestruction"
          type="text"
        />
      </v-responsive>
    </v-card-text>
    <v-card-actions class="d-flex justify-end mt-0">
      <slot />
      <v-btn
        class="custom-green-btn px-10"
        @click="proceed"
        :disabled="disabled"
        :size="smAndUp ? 'large' : 'default'"
        ref="buttonExposeRef"
        >Далі</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useDisplay } from 'vuetify';

const { smAndUp } = useDisplay();

const emit = defineEmits(['input']);
defineProps<{
  type: 'coordinates-square-target-destruction' | 'coordinates-square';
}>();

const square = ref('');
const coordinates = ref('');
const targetDestruction = ref('');
const disabled = computed(() => !coordinates.value && !square.value);
const buttonExposeRef = ref<HTMLButtonElement | null>(null);

defineExpose({
  buttonExposeRef,
});

type LinkedObjectType = {
  coordinates?: string;
  square?: string;
  targetDetails?: string;
};

function proceed() {
  const values = [];
  const linkedObject: LinkedObjectType = {};

  if (targetDestruction.value) {
    values.push(`Опис цілі: ${targetDestruction.value}`);
    linkedObject.targetDetails = targetDestruction.value;
  }
  if (coordinates.value) {
    values.push(`Координати: ${coordinates.value}`);
    linkedObject.coordinates = coordinates.value;
  }

  if (square.value) {
    values.push(`Квадрат: ${square.value}`);
    linkedObject.square = square.value;
  }

  emit(
    'input',
    values,
    Object.keys(linkedObject).length ? linkedObject : undefined,
  );
}
</script>
