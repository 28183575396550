import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, renderSlot as _renderSlot, createTextVNode as _createTextVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

import { rules } from '@/utils/rules';
import { LinkedObjectType } from '@/types/events';
import { useDisplay } from 'vuetify';


export default /*@__PURE__*/_defineComponent({
  __name: 'EventInput',
  props: /*@__PURE__*/_mergeModels({
    label: {},
    type: {},
    displayType: {},
    linkedObject: {}
  }, {
    "modelValue": { required: true },
    "modelModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(['input'], ["update:modelValue"]),
  setup(__props: any, { emit: __emit }) {

const props = __props;

const { smAndUp } = useDisplay();

const model = _useModel(__props, "modelValue");

const emit = __emit;

function proceed() {
  const values: string[] = [];
  const linkedObject: Record<string, string> = {};

  const linkedObjectKey = Object.keys(props.linkedObject ?? {}).filter(
    (i) => i !== 'parentObject',
  )[0];

  if (model.value) {
    values.push(`${props.displayType}${model.value}`);
    if (linkedObjectKey) {
      linkedObject[linkedObjectKey] = model.value as string;
    }
  }

  emit(
    'input',
    values,
    Object.keys(linkedObject).length ? linkedObject : undefined,
  );
}

return (_ctx: any,_cache: any) => {
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!

  return (_openBlock(), _createBlock(_component_v_card, { variant: "flat" }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card_text, { class: "pa-0" }, {
        default: _withCtx(() => [
          _createVNode(_component_v_text_field, {
            modelValue: model.value,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((model).value = $event)),
            label: _ctx.label,
            outlined: "",
            "hide-details": "auto",
            rules: [_unref(rules).maxLength(100)]
          }, null, 8, ["modelValue", "label", "rules"])
        ]),
        _: 1
      }),
      (_ctx.type === 'input-optional')
        ? (_openBlock(), _createBlock(_component_v_card_actions, {
            key: 0,
            class: "d-flex justify-end mt-0"
          }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default"),
              _createVNode(_component_v_btn, {
                class: "custom-green-btn px-10",
                onClick: proceed,
                ref: "buttonExposeRef",
                size: _unref(smAndUp) ? 'large' : 'default'
              }, {
                default: _withCtx(() => _cache[1] || (_cache[1] = [
                  _createTextVNode("Далі")
                ])),
                _: 1
              }, 8, ["size"])
            ]),
            _: 3
          }))
        : _createCommentVNode("", true)
    ]),
    _: 3
  }))
}
}

})