import { defineComponent as _defineComponent } from 'vue'
import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, unref as _unref, vModelText as _vModelText, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "center-content" }
const _hoisted_2 = { class: "status-section" }
const _hoisted_3 = { class: "time-picker-container" }

import { ref, reactive, watch, onMounted, onUnmounted, computed } from 'vue';
import { EventNotes, DayIndicator } from '@/types/events';
import {
  getCurrentTime,
  isTimeMoreThanOneHourInFuture,
} from '@/utils/timeUtils';
import { useEventCreationStore } from '@/stores/eventCreation';

interface Props {
  showAllies: boolean;
  showEnemies: boolean;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'EventNotesForm',
  props: {
    showAllies: { type: Boolean, default: false },
    showEnemies: { type: Boolean, default: false }
  },
  setup(__props: any) {

const eventStore = useEventCreationStore();



const statuses = [
  '4.5.0',
  '4.5.0, о/с цілий',
  '4.5.0, о/с та ОВТ цілі',
] as const;

const time = ref(getCurrentTime());
const notesData = reactive<EventNotes>({
  allies: '',
  enemies: '',
  details: '',
  status: '',
});

const isTimeMoreThanOneHourInFutureComputed = computed(() => {
  return eventStore.dayIndicator === DayIndicator.PREVIOUS
    ? false
    : isTimeMoreThanOneHourInFuture(time.value);
});

function handleDayIndicatorChange(dayIndicator: DayIndicator) {
  eventStore.$patch({
    dayIndicator,
  });
}

function handleStatusChange(status: string | null) {
  notesData.status = status ?? '';
}

function formatNotes(notes: EventNotes): string {
  const lines = [
    notes.allies ? `Кількість о/с на позиції: ${notes.allies.trim()}` : '',
    notes.enemies ? `Кількість о/с противника: ${notes.enemies.trim()}` : '',
    notes.details ? `Додатково: ${notes.details.trim()}` : '',
    notes.status ? `Статус: ${notes.status.trim()}` : '',
  ]
    .filter(Boolean)
    .join('\n');

  return lines;
}

function resetState() {
  notesData.allies = '';
  notesData.enemies = '';
  notesData.details = '';
  notesData.status = '';
  eventStore.$patch({
    notesMessage: '',
    dayIndicator: DayIndicator.CURRENT,
  });
}

onMounted(() => {
  resetState();
});

onUnmounted(() => {
  resetState();
});

watch(notesData, (notes) => {
  eventStore.$patch({
    notesMessage: `\n${formatNotes(notes)}`,
  });
});

watch(time, (time) => {
  eventStore.$patch({
    time,
  });
});

return (_ctx: any,_cache: any) => {
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_textarea = _resolveComponent("v-textarea")!
  const _component_v_radio = _resolveComponent("v-radio")!
  const _component_v_radio_group = _resolveComponent("v-radio-group")!
  const _component_v_tab = _resolveComponent("v-tab")!
  const _component_v_tabs = _resolveComponent("v-tabs")!
  const _component_v_container = _resolveComponent("v-container")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (isTimeMoreThanOneHourInFutureComputed.value)
      ? (_openBlock(), _createBlock(_component_v_alert, {
          key: 0,
          type: "warning",
          closable: false
        }, {
          default: _withCtx(() => _cache[6] || (_cache[6] = [
            _createTextVNode(" Час обраний в майбутньому! ")
          ])),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.showAllies)
      ? (_openBlock(), _createBlock(_component_v_textarea, {
          key: 1,
          modelValue: notesData.allies,
          "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((notesData.allies) = $event)),
          label: "Кількість о/с на позиції",
          outlined: "",
          rows: "2",
          class: "full-width",
          "hide-details": ""
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    (_ctx.showEnemies)
      ? (_openBlock(), _createBlock(_component_v_textarea, {
          key: 2,
          modelValue: notesData.enemies,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((notesData.enemies) = $event)),
          label: "Кільскість o/c противника",
          outlined: "",
          rows: "2",
          class: "full-width",
          "hide-details": ""
        }, null, 8, ["modelValue"]))
      : _createCommentVNode("", true),
    _createVNode(_component_v_textarea, {
      modelValue: notesData.details,
      "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((notesData.details) = $event)),
      label: "Деталі",
      outlined: "",
      rows: "2",
      class: "full-width",
      "hide-details": ""
    }, null, 8, ["modelValue"]),
    _createElementVNode("div", _hoisted_2, [
      _cache[7] || (_cache[7] = _createElementVNode("label", null, "Статус", -1)),
      _createVNode(_component_v_radio_group, {
        "model-value": notesData.status,
        "hide-details": ""
      }, {
        default: _withCtx(() => [
          (_openBlock(), _createElementBlock(_Fragment, null, _renderList(statuses, (status) => {
            return _createVNode(_component_v_radio, {
              key: status,
              label: status,
              value: status,
              onClick: ($event: any) => (handleStatusChange(notesData.status === status ? '' : status))
            }, null, 8, ["label", "value", "onClick"])
          }), 64))
        ]),
        _: 1
      }, 8, ["model-value"])
    ]),
    _createVNode(_component_v_tabs, {
      "model-value": _unref(eventStore).dayIndicator,
      "align-tabs": "center",
      color: "var(--default-color)",
      density: "compact",
      mandatory: "force"
    }, {
      default: _withCtx(() => [
        _createVNode(_component_v_tab, {
          value: _unref(DayIndicator).PREVIOUS,
          onClick: _cache[3] || (_cache[3] = () => handleDayIndicatorChange(_unref(DayIndicator).PREVIOUS))
        }, {
          default: _withCtx(() => _cache[8] || (_cache[8] = [
            _createTextVNode("Вчора")
          ])),
          _: 1
        }, 8, ["value"]),
        _createVNode(_component_v_tab, {
          value: _unref(DayIndicator).CURRENT,
          onClick: _cache[4] || (_cache[4] = () => handleDayIndicatorChange(_unref(DayIndicator).CURRENT))
        }, {
          default: _withCtx(() => _cache[9] || (_cache[9] = [
            _createTextVNode("Сьогодні")
          ])),
          _: 1
        }, 8, ["value"])
      ]),
      _: 1
    }, 8, ["model-value"]),
    _createVNode(_component_v_container, {
      class: "pa-0",
      fluid: ""
    }, {
      default: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _withDirectives(_createElementVNode("input", {
            type: "time",
            id: "time_picker",
            "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((time).value = $event)),
            name: "time",
            required: ""
          }, null, 512), [
            [_vModelText, time.value]
          ])
        ])
      ]),
      _: 1
    })
  ]))
}
}

})