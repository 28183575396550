import User from '../models/User';

export function formatMGRS(mgrsString) {
  if (!mgrsString || mgrsString.length < 5) return mgrsString;

  const zoneNumber = mgrsString.slice(0, 2); // First two characters
  const latitudeBand = mgrsString[2]; // Third character
  const gridSquare = mgrsString.slice(3, 5); // Fourth and fifth characters

  const remainingString = mgrsString.slice(5); // Everything after gridSquare
  const halfLength = Math.floor(remainingString.length / 2); // Divide evenly

  const easting = remainingString.slice(0, halfLength);
  const northing = remainingString.slice(halfLength);

  return `${zoneNumber}${latitudeBand} ${gridSquare} ${easting} ${northing}`;
}
