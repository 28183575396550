<template>
  <div class="d-flex align-center justify-space-between ga-1 mb-2">
    <h4 class="headline">Попередній перегляд</h4>
    <div class="d-flex align-center ga-2">
      <v-btn
        v-if="!isEditable"
        size="x-small"
        variant="outlined"
        icon="mdi-pencil"
        @click="handleEditable"
      ></v-btn>
      <v-btn
        v-else
        size="x-small"
        variant="outlined"
        icon="mdi-refresh"
        class="mobile-refresh-action"
        @click="handleEditable"
      ></v-btn>

      <v-btn
        size="x-small"
        @click="copyToClipboard(eventStore.editMessage || chatData)"
        variant="outlined"
        :icon="isCopied ? 'mdi-check-bold' : 'mdi-content-copy'"
      ></v-btn>
    </div>
  </div>

  <v-textarea
    v-if="isEditable"
    class="pa-0 ma-0 custom-textarea"
    label=""
    variant="outlined"
    v-model="eventStore.editMessage"
    rows="5"
    max-rows="16"
    auto-grow
    density="compact"
    hide-details
  ></v-textarea>

  <p
    class="preview-text"
    v-else
    style="white-space: break-spaces; letter-spacing: 0.15px"
    @click="handleEditable"
  >
    {{ chatData }}
  </p>

  <div
    class="d-flex align-center justify-space-between ga-1 mt-2 action-box"
    v-if="isEditable"
  >
    <v-btn class="custom-default-btn" @click="handleEditable">Скинути</v-btn>
    <v-btn
      :disabled="eventStore.loading"
      @click="$emit('handleSubmit')"
      class="custom-green-btn"
    >
      Створити подію
    </v-btn>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import type { Position } from '@/types/events';
import { useEventCreationStore } from '@/stores/eventCreation';

interface Props {
  position: Position;
  eventMessage: string;
}

defineEmits<{
  handleSubmit: () => void;
}>();

const props = defineProps<Props>();

const eventStore = useEventCreationStore();

const isCopied = ref(false);
const isEditable = ref(false);

const chatData = computed(() =>
  eventStore.formattedChatData(props.position, props.eventMessage),
);

async function copyToClipboard(textToCopy: string) {
  await navigator.clipboard.writeText(textToCopy);
  isCopied.value = true;

  setTimeout(() => {
    isCopied.value = false;
  }, 2000);
}

function handleEditable() {
  isEditable.value = !isEditable.value;

  eventStore.$patch({
    editMessage: chatData.value,
  });
}

function reset() {
  eventStore.$patch({
    editMessage: '',
  });
}

onMounted(() => {
  reset();
});

onUnmounted(() => {
  reset();
});

watch(
  () => eventStore.notesMessage,
  () => {
    reset();
    isEditable.value = false;
  },
);
</script>

<style scoped>
.action-box {
  @media (max-width: 970px) {
    display: none !important;
  }
}

.mobile-refresh-action {
  display: none;

  @media (max-width: 970px) {
    display: flex;
  }
}

.custom-textarea {
  & >>> .v-field__input {
    padding-inline: 8px !important;
  }
}

.preview-text {
  border: 1px solid transparent;
  padding: 7px;
}
</style>
