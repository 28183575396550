import Squadron from './Squadron';

class Position {
  constructor(data) {
    this.id = data.id;
    this.name = data.name;
    this.description = data.description;
    this.isUnderAttack = data.isUnderAttack;
    this.isUnderFire = data.isUnderFire;
    this.isDeleted = data.isDeleted;
    this.createdAt = data.createdAt;
    this.updatedAt = data.updatedAt;
    this.squadronId = data.squadronId;
    this.isGroup = data.isGroup;
    this.isCustomPosition = data.isCustomPosition ?? false;
    this.positionName = data.positionName;
    this.squadron = this.squadronId ? new Squadron(data.squadron) : null;
  }
}

export default Position;
