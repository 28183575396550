import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, renderSlot as _renderSlot, unref as _unref } from "vue"

const _hoisted_1 = ["value", "onClick"]
const _hoisted_2 = ["value", "onClick"]

import { ref, computed } from 'vue';
import { useDisplay } from 'vuetify';
import { EventPath } from '@/types/events';
import { useEventsSessionStore } from '@/stores/eventSession';


export default /*@__PURE__*/_defineComponent({
  __name: 'EventLastCreated',
  props: /*@__PURE__*/_mergeModels({
    lastEventPaths: {}
  }, {
    "show": {
  type: Boolean,
  required: true,
},
    "showModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(['goTo'], ["update:show"]),
  setup(__props: any, { expose: __expose, emit: __emit }) {

const eventSessionStore = useEventsSessionStore();

const { smAndUp } = useDisplay();

const emit = __emit;

const props = __props;

const show = _useModel(__props, 'show');

const canShowProceedButton = computed(() => {
  return props.lastEventPaths.some(
    (item) =>
      item.canShowEventPath === false && item.startCreatedAtEventPath === true,
  );
});

const buttonExposeRef = ref<HTMLButtonElement | null>(null);

__expose({
  buttonExposeRef,
});

function goTo(index: number, startCreatedAtEventPath?: boolean) {
  if (startCreatedAtEventPath) {
    const lastEventPaths = [...props.lastEventPaths].splice(0, index);
    emit('goTo', lastEventPaths);
    show.value = false;
  }
}

function proceed() {
  const findIndex = props.lastEventPaths.findIndex(
    (item) =>
      item.canShowEventPath === false && item.startCreatedAtEventPath === true,
  );
  if (findIndex !== -1) {
    goTo(findIndex, true);
  }
}

function reset() {
  eventSessionStore.reset();
  show.value = false;
}

return (_ctx: any,_cache: any) => {
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    "model-value": show.value,
    opacity: "0.9",
    "max-width": "600px"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_text, { class: "pt-3 w-auto" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_list, null, {
                default: _withCtx(() => [
                  _cache[1] || (_cache[1] = _createElementVNode("h3", { class: "mb-3" }, "Використати попередні дані?", -1)),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.lastEventPaths, (item, index) => {
                    return (_openBlock(), _createElementBlock(_Fragment, { key: index }, [
                      (item.selectDisplayType?.length && item?.canShowEventPath)
                        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 0 }, _renderList(item.selectDisplayType, (i) => {
                            return (_openBlock(), _createElementBlock("div", {
                              class: "d-flex align-center mb-3",
                              key: i,
                              value: i,
                              onClick: ($event: any) => (goTo(index, item.startCreatedAtEventPath))
                            }, [
                              _createVNode(_component_v_chip, {
                                size: "large",
                                text: i,
                                variant: "outlined",
                                class: "py-1",
                                style: {"text-wrap":"wrap","height":"fit-content"}
                              }, null, 8, ["text"]),
                              (item.startCreatedAtEventPath)
                                ? (_openBlock(), _createBlock(_component_v_icon, {
                                    key: 0,
                                    class: "cursor-pointer ml-3"
                                  }, {
                                    default: _withCtx(() => _cache[0] || (_cache[0] = [
                                      _createTextVNode("mdi-pencil")
                                    ])),
                                    _: 1
                                  }))
                                : _createCommentVNode("", true)
                            ], 8, _hoisted_1))
                          }), 128))
                        : (!!item.displayType?.trim() && item?.canShowEventPath)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 1,
                              value: item,
                              onClick: ($event: any) => (goTo(index, item.startCreatedAtEventPath))
                            }, [
                              (item.displayType)
                                ? (_openBlock(), _createBlock(_component_v_chip, {
                                    key: 0,
                                    size: "large",
                                    variant: "outlined",
                                    class: "py-1",
                                    style: {"text-wrap":"wrap","height":"fit-content"}
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode(_toDisplayString(item.displayType), 1)
                                    ]),
                                    _: 2
                                  }, 1024))
                                : _createCommentVNode("", true)
                            ], 8, _hoisted_2))
                          : _createCommentVNode("", true)
                    ], 64))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, { class: "d-flex justify-end mt-0 ga-3 px-sm-6" }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "default"),
              _createVNode(_component_v_btn, {
                class: "custom-gray-btn",
                onClick: reset,
                ref_key: "buttonExposeRef",
                ref: buttonExposeRef,
                size: _unref(smAndUp) ? 'large' : 'default'
              }, {
                default: _withCtx(() => _cache[2] || (_cache[2] = [
                  _createTextVNode("Скинути")
                ])),
                _: 1
              }, 8, ["size"]),
              (canShowProceedButton.value)
                ? (_openBlock(), _createBlock(_component_v_btn, {
                    key: 0,
                    class: "custom-green-btn px-10",
                    onClick: proceed,
                    ref_key: "buttonExposeRef",
                    ref: buttonExposeRef,
                    size: _unref(smAndUp) ? 'large' : 'default'
                  }, {
                    default: _withCtx(() => _cache[3] || (_cache[3] = [
                      _createTextVNode("Так")
                    ])),
                    _: 1
                  }, 8, ["size"]))
                : _createCommentVNode("", true)
            ]),
            _: 3
          })
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["model-value"]))
}
}

})