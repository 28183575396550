<template>
  <div class="wrapper">
    <v-dialog v-model="showEventTypeDialog" max-width="600px">
      <event-type-selection
        :dialog="showEventTypeDialog"
        :position="resolveUserName"
        :currentOption="currentOption"
        :currentOptions="events"
        @update:dialog="showEventTypeDialog = $event"
        @event-success="handleEventSuccess"
        @event-error="showAlert($event, 'error')"
        @close="showEventTypeDialog = false"
      />
    </v-dialog>

    <v-list class="list-wrapper elevation-3 rounded-sm" lines="one">
      <v-alert
        class="mb-3"
        v-if="alert.show"
        :type="alert.type"
        dismissible
        @update:modelValue="alert.show = false"
      >
        {{ alert.message }}
      </v-alert>
      <h3 class="mb-3 text-center">Оберіть тип події</h3>
      <template v-for="event in events" :key="event.id">
        <v-list-item
          @click="handleEventSelection(event)"
          :title="event.title ?? event.name"
        ></v-list-item>
      </template>
    </v-list>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useAdminStore } from '@/stores/admin';
import { usePositionsStore } from '@/stores/positions';
import EventTypeSelection from '@/views/EventCreationDialog.vue';
import { eventConfig } from '@/config/configResponse';
import { EventType, UavPilotEvents } from '@/types/events';

const alert = ref({
  show: false,
  message: '',
  type: 'info',
});

const adminStore = useAdminStore();
const positionsStore = usePositionsStore();

const showEventTypeDialog = ref(false);
const currentOption = ref<EventType>();

const events = computed(() =>
  eventConfig.filter((i) => i.id === UavPilotEvents.UAV_MISSION_MAVIC_AUTEL_ID),
);

const resolveUserName = computed(() =>
  positionsStore.createCustomPosition(adminStore.currentUser!.callsign),
);

function handleEventSelection(event: EventType) {
  currentOption.value = event;
  showEventTypeDialog.value = true;
}

function handleEventSuccess(message: string) {
  showAlert(message, 'success');
}

function showAlert(message: string, type: 'success' | 'error') {
  alert.value = {
    show: true,
    message,
    type,
  };

  setTimeout(() => {
    alert.value.show = false;
  }, 3000);
}
</script>

<style scoped>
.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: auto;
  height: 100%;
  padding: 16px;
}

.list-wrapper {
  max-width: 600px;
  width: 100%;
  padding: 12px;
}
</style>
