<template>
  <v-card variant="flat">
    <v-card-text class="pa-0">
      <v-responsive class="mx-auto" width="100%">
        <v-text-field label="Витрати" v-model="expenditure" type="text" />
      </v-responsive>
      <v-responsive class="mx-auto" width="100%">
        <v-text-field label="Залишок" v-model="balance" type="text" />
      </v-responsive>
    </v-card-text>
    <v-card-actions class="d-flex justify-end mt-0">
      <slot />
      <v-btn
        class="custom-green-btn px-10"
        @click="proceed"
        :disabled="disabled"
        ref="buttonExposeRef"
        :size="smAndUp ? 'large' : 'default'"
        >Далі</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useDisplay } from 'vuetify';

const { smAndUp } = useDisplay();

const emit = defineEmits(['input']);

const expenditure = ref('');
const balance = ref('');
const disabled = computed(() => !expenditure.value || !balance.value);
const buttonExposeRef = ref<HTMLButtonElement | null>(null);

defineExpose({
  buttonExposeRef,
});

function proceed() {
  const values = [];
  if (expenditure.value) {
    values.push(`Витрати: ${expenditure.value}`);
  }

  if (balance.value) {
    values.push(`Залишок: ${balance.value}`);
  }

  emit('input', values);
}
</script>
