<template>
  <v-data-table-server
    :headers="headers"
    :items="eventsStore.formattedUavEvents"
    :items-length="eventsStore.uavEventsCount"
    :items-per-page="itemsPerPageLocal"
    :loading="eventsStore.loading"
    :header-props="{ style: 'font-weight: bold; background-color: #f5f5f5;' }"
    class="elevation-1"
    item-value="id"
    @update:options="fetchEvents"
    :items-per-page-options="[10, 25, 50, 100]"
  >
  </v-data-table-server>
</template>

<script setup>
import { ref, watch } from 'vue';
import { useEventsStore } from '@/stores/events';

const eventsStore = useEventsStore();
const props = defineProps({
  startDate: {
    type: String || null,
  },
  endDate: {
    type: String || null,
  },
});
const itemsPerPageLocal = ref(50);

watch(
  () => [props.startDate, props.endDate],
  () => {
    eventsStore.fetchUavEvents(
      1,
      itemsPerPageLocal.value,
      props.startDate,
      props.endDate,
    );
  },
);

const headers = [
  { title: 'Системний час (UTC)', key: 'createdAt' },
  { title: 'Час доповіді', key: 'time' },
  { title: 'Місія', key: 'mission' },
  { title: 'Підрозділ', key: 'battalion' },
  { title: 'Пілот', key: 'reporter' },
  { title: 'Засіб', key: 'uavType' },
  { title: 'БК', key: 'ammunitionType' },
  { title: 'Ціль', key: 'target' },
  { title: 'Опис цілі', key: 'targetDetails' },
  { title: '300', key: 'wounded' },
  { title: '~300', key: 'possibleWounded' },
  { title: '200', key: 'killed' },
  { title: '~200', key: 'possibleKilled' },
  { title: 'Результат', key: 'result' },
  { title: 'Деталі', key: 'resultDetails' },
  { title: 'Координати', key: 'coordinates' },
  { title: 'Квадрат', key: 'square' },
  { title: 'Стрім', key: 'stream' },
  { title: 'Нотатки', key: 'notes' },
];

const fetchEvents = ({ page, itemsPerPage }) => {
  itemsPerPageLocal.value = itemsPerPage;
  eventsStore.fetchUavEvents(
    page,
    itemsPerPageLocal.value,
    props.startDate,
    props.endDate,
  );
};
</script>

<style scoped>
.mgrs-column-cell {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

:deep(.v-data-table-header th) {
  font-weight: bold !important;
  background-color: #f5f5f5;
  text-align: center;
}
</style>
