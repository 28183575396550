<template>
  <v-container class="admin-container" fluid>
    <v-card>
      <v-card-text>
        <!-- Global Success Alert -->
        <NotificationAlert
          v-if="adminStore.alert.show"
          :show="adminStore.alert.show"
          :type="adminStore.alert.type"
          :message="adminStore.alert.message"
          @update:show="adminStore.alert.show = $event"
        />

        <!-- User Info Section -->
        <v-container class="user-info-create-container">
          <div v-if="adminStore.currentUser">
            <h2 class="mb-4">Інформація про користувача</h2>
            <v-form class="user-info-form">
              <v-row>
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    label="Ім'я користувача"
                    v-model="adminStore.currentUser.username"
                    readonly
                  />
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    label="Позивний"
                    v-model="adminStore.currentUser.callsign"
                    readonly
                  />
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    label="Пункт управління"
                    :model-value="userSquadron"
                    readonly
                  />
                </v-col>
                <v-col cols="12" sm="6" class="py-0">
                  <v-text-field
                    label="Підрозділ"
                    :model-value="userBattalion"
                    readonly
                  />
                </v-col>
              </v-row>
            </v-form>

            <!-- Action Buttons Section -->
            <v-container class="pa-0">
              <v-row class="button-group">
                <v-col
                  cols="12"
                  sm="6"
                  v-if="
                    adminStore.currentUser.role === 'admin' ||
                    adminStore.currentUser.role === 'battalion_admin'
                  "
                >
                  <v-btn
                    @click="dialogs.openUserDialog()"
                    color="primary"
                    block
                    class="custom-btn"
                  >
                    Новий користувач
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  v-if="
                    ['admin', 'battalion_admin', 'squadron_admin'].includes(
                      adminStore.currentUser.role,
                    )
                  "
                >
                  <v-btn
                    @click="dialogs.openPositionDialog()"
                    color="primary"
                    block
                    class="custom-btn"
                  >
                    Нова позиція, група, позивний
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  v-if="
                    adminStore.currentUser.role === 'admin' ||
                    adminStore.currentUser.role === 'battalion_admin'
                  "
                >
                  <v-btn
                    @click="dialogs.openSquadronDialog()"
                    color="primary"
                    block
                    class="custom-btn"
                  >
                    Новий Пункт управління
                  </v-btn>
                </v-col>

                <v-col
                  cols="12"
                  sm="6"
                  v-if="adminStore.currentUser.role === 'admin'"
                >
                  <v-btn
                    @click="dialogs.openBattalionDialog()"
                    color="primary"
                    block
                    class="custom-btn"
                  >
                    Новий Підрозділ
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>

            <v-divider class="my-4" />

            <!-- Navigation Tabs -->
            <v-container class="pa-0">
              <v-row class="button-group">
                <v-col cols="12" sm="3">
                  <v-btn
                    @click="adminStore.activeView = 'squadrons'"
                    block
                    class="custom-btn"
                    :class="{
                      'active-tab': adminStore.activeView === 'squadrons',
                    }"
                  >
                    Пункти управління
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-btn
                    @click="adminStore.activeView = 'battalions'"
                    block
                    class="custom-btn"
                    :class="{
                      'active-tab': adminStore.activeView === 'battalions',
                    }"
                  >
                    Підрозділи
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-btn
                    @click="adminStore.activeView = 'positions'"
                    block
                    class="custom-btn"
                    :class="{
                      'active-tab': adminStore.activeView === 'positions',
                    }"
                  >
                    Позиції, групи, позивні
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="3">
                  <v-btn
                    @click="adminStore.activeView = 'users'"
                    block
                    class="custom-btn"
                    :class="{
                      'active-tab': adminStore.activeView === 'users',
                    }"
                  >
                    Користувачі
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-container>

        <!-- Filtering Section -->
        <v-row v-if="showFilters" class="my-4">
          <v-col cols="12" sm="6">
            <v-select
              label="Фільтрувати по Підрозділу"
              :items="adminStore.battalionsFilterItems"
              v-model="adminStore.selectedBattalion"
              item-title="name"
              item-value="id"
              clearable
              hide-details
            />
          </v-col>
          <v-col cols="12" sm="6" v-if="adminStore.activeView === 'positions'">
            <v-select
              label="Фільтрувати по Пункту управління"
              :items="adminStore.positionsSquadronsFilterItems"
              v-model="adminStore.selectedSquadron"
              item-title="display"
              item-value="id"
              clearable
              hide-details
            />
          </v-col>
        </v-row>

        <!-- Data Tables Section -->
        <div class="table-container">
          <!-- Squadrons Table -->
          <div v-if="adminStore.activeView === 'squadrons'">
            <h2 class="my-4">Список Пунктів управління</h2>
            <v-data-table
              :headers="squadronHeaders"
              :items="adminStore.filteredSquadrons"
              class="mb-4"
              item-value="id"
              sticky
            >
              <template v-slot:[`item.name`]="{ item }">
                {{ item.name }}
                <span class="battalion-name">
                  ({{ item.battalionName ?? '' }})
                </span>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div class="actions-container">
                  <v-btn
                    class="custom-yellow-btn"
                    v-if="canEditSquadrons"
                    @click="dialogs.openSquadronDialog(item)"
                  >
                    Редагувати
                  </v-btn>
                  <v-btn
                    class="custom-red-btn"
                    v-if="canEditSquadrons"
                    @click="confirmDelete('squadron', item)"
                  >
                    Видалити
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </div>

          <!-- Battalions Table -->
          <div v-else-if="adminStore.activeView === 'battalions'">
            <h2 class="my-4">Список Підрозділів</h2>
            <v-data-table
              :headers="battalionHeaders"
              :items="adminStore.battalions"
              class="mb-4"
              sticky
            >
              <template v-slot:[`item.signalChats`]="{ item }">
                <div
                  v-if="item.signalChatsData?.length"
                  class="chat-names-container"
                >
                  <v-chip
                    v-for="chat in item.signalChatsData"
                    :key="`signal-${chat.name}`"
                    class="chat-name-chip"
                    :color="`${chat.systemChat ? 'primary' : ''}`"
                    @click="
                      dialogs.openChatConfigDialog('signal', item, chat.name)
                    "
                  >
                    {{ chat.name }}
                  </v-chip>
                </div>
              </template>

              <template v-slot:[`item.whatsappChats`]="{ item }">
                <div
                  v-if="item.whatsappChatsData?.length"
                  class="chat-names-container"
                >
                  <v-chip
                    v-for="chat in item.whatsappChatsData"
                    :key="`whatsapp-${chat.name}`"
                    class="chat-name-chip"
                    :color="`${chat.systemChat ? 'primary' : ''}`"
                    @click="
                      dialogs.openChatConfigDialog('whatsapp', item, chat.name)
                    "
                  >
                    {{ chat.name }}
                  </v-chip>
                </div>
              </template>

              <template v-slot:[`item.actions`]="{ item }">
                <div class="actions-container">
                  <v-btn
                    class="custom-yellow-btn"
                    v-if="canEditBattalions"
                    @click="dialogs.openBattalionDialog(item)"
                  >
                    Редагувати
                  </v-btn>
                  <v-btn
                    class="custom-red-btn"
                    v-if="canEditBattalions"
                    @click="confirmDelete('battalion', item)"
                  >
                    Видалити
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </div>

          <!-- Positions Tables -->
          <div v-else-if="adminStore.activeView === 'positions'">
            <h2 class="my-4">Список позицій, груп, позивних</h2>

            <!-- Positions -->
            <h3>Позиції</h3>
            <v-data-table
              :headers="positionHeaders"
              :items="regularPositions"
              class="mb-4"
              item-value="id"
              sticky
            >
              <template v-slot:[`item.name`]="{ item }">
                {{ item.name }}
                <span v-if="item.description" class="description">
                  ({{ item.description }})
                </span>
              </template>
              <template v-slot:[`item.squadron`]="{ item }">
                {{ adminStore.getSquadronWithBattalion(item.squadron) }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div class="actions-container">
                  <v-btn
                    class="custom-yellow-btn"
                    v-if="canEditPositions"
                    @click="dialogs.openPositionDialog(item)"
                  >
                    Редагувати
                  </v-btn>
                  <v-btn
                    class="custom-red-btn"
                    v-if="canEditPositions"
                    @click="confirmDelete('position', item)"
                  >
                    Видалити
                  </v-btn>
                </div>
              </template>
            </v-data-table>

            <!-- Groups -->
            <h3>Групи та позивні</h3>
            <v-data-table
              :headers="positionHeaders"
              :items="groupPositions"
              class="mb-4"
              item-value="id"
              sticky
            >
              <template v-slot:[`item.name`]="{ item }">
                {{ item.name }}
                <span v-if="item.description" class="description">
                  ({{ item.description }})
                </span>
              </template>
              <template v-slot:[`item.squadron`]="{ item }">
                {{ adminStore.getSquadronWithBattalion(item.squadron) }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div class="actions-container">
                  <v-btn
                    class="custom-yellow-btn"
                    v-if="canEditPositions"
                    @click="dialogs.openPositionDialog(item)"
                  >
                    Редагувати
                  </v-btn>
                  <v-btn
                    class="custom-red-btn"
                    v-if="canEditPositions"
                    @click="confirmDelete('position', item)"
                  >
                    Видалити
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </div>

          <!-- Users Table -->
          <div v-else-if="adminStore.activeView === 'users'">
            <h2 class="my-4">Список користувачів</h2>
            <v-data-table
              :headers="userHeaders"
              :items="adminStore.filteredUsers"
              class="mb-4"
              item-value="id"
              sticky
            >
              <template v-slot:[`item.squadron`]="{ item }">
                {{ adminStore.getSquadronWithBattalion(item.squadron) }}
              </template>
              <template v-slot:[`item.role`]="{ item }">
                {{ adminStore.roleTranslations[item.role] || 'Unknown' }}
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div class="actions-container">
                  <v-btn
                    class="custom-yellow-btn"
                    v-if="canEditUsers"
                    @click="dialogs.openUserDialog(item)"
                  >
                    Редагувати
                  </v-btn>
                  <v-btn
                    class="custom-red-btn"
                    v-if="canEditUsers"
                    @click="confirmDelete('user', item)"
                  >
                    Видалити
                  </v-btn>
                </div>
              </template>
            </v-data-table>
          </div>

          <v-alert v-else type="info" prominent>
            Завантаження інформації про користувача...
          </v-alert>
        </div>
      </v-card-text>
    </v-card>
  </v-container>

  <!-- Dialogs Section -->
  <!-- User Dialog -->
  <CreateEditUserDialog
    v-if="dialogs.dialogStates.userDialog"
    :dialog-visible="dialogs.dialogStates.userDialog"
    :is-editing="dialogs.isEditMode.user"
    :form="dialogs.userForm"
    :available-squadrons="adminStore.allSquadronItems"
    :available-roles="adminStore.getAvailableRoles"
    @update:dialog-visible="(val) => (dialogs.dialogStates.userDialog = val)"
    @save="handleUserSave"
  />

  <!-- Squadron Dialog -->
  <CreateEditSquadronDialog
    :dialog-visible="dialogs.dialogStates.squadronDialog"
    :is-editing="dialogs.isEditMode.squadron"
    :form="dialogs.squadronForm"
    :available-battalions="adminStore.activeBattalionItems"
    @update:dialog-visible="
      (val) => (dialogs.dialogStates.squadronDialog = val)
    "
    @save="handleSquadronSave"
  />

  <!-- Battalion Dialog -->
  <CreateEditBattalionDialog
    v-if="dialogs.dialogStates.battalionDialog"
    :dialog-visible="dialogs.dialogStates.battalionDialog"
    :is-editing="dialogs.isEditMode.battalion"
    :form="dialogs.battalionForm"
    :new-signal-chat="dialogs.newSignalChat"
    :new-whatsapp-chat="dialogs.newWhatsappChat"
    @update:dialog-visible="
      (val) => (dialogs.dialogStates.battalionDialog = val)
    "
    @save="handleBattalionSave"
    @add-signal-chat="dialogs.addSignalChat"
    @add-whatsapp-chat="dialogs.addWhatsappChat"
    @remove-chat="dialogs.removeChatFromArray"
  />

  <!-- Position Dialog -->
  <CreateEditPositionDialog
    :dialog-visible="dialogs.dialogStates.positionDialog"
    :is-editing="dialogs.isEditMode.position"
    :form="dialogs.positionForm"
    :available-squadrons="adminStore.activeNonServiceSquadronItems"
    @update:dialog-visible="
      (val) => (dialogs.dialogStates.positionDialog = val)
    "
    @save="handlePositionSave"
  />

  <!-- Chat Config Dialog -->
  <ChatConfigDialog
    v-if="dialogs.dialogStates.chatConfigDialog"
    :dialog-visible="dialogs.dialogStates.chatConfigDialog"
    :chat-name="dialogs.selectedChat?.value?.name"
    :chat-type="dialogs.selectedChat?.value?.type"
    :config="dialogs.selectedChat?.value?.config"
    @update:dialog-visible="
      (val) => (dialogs.dialogStates.chatConfigDialog = val)
    "
    @save="handleChatConfigSave"
  />

  <!-- Confirm Delete Dialog -->
  <v-dialog v-model="confirmDialog.show" max-width="500px">
    <v-card>
      <v-card-title>Підтвердити видалення</v-card-title>
      <v-card-text>
        Ви впевнені, що хочете видалити цей елемент? Ця дія не може бути
        скасована.
      </v-card-text>
      <v-card-actions>
        <v-spacer />
        <v-btn class="custom-default-btn" @click="confirmDialog.show = false">
          Скасувати
        </v-btn>
        <v-btn class="custom-red-btn" @click="handleConfirmDelete">
          Видалити
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, toRaw } from 'vue';
import apiClient from '@/services/axios';
import { useAdminStore } from '@/stores/admin';
import { useAdminDialogs } from '@/composables/useAdminDialogs';
import type { User, Battalion, Position } from '@/types/admin';

// Import dialog components
import CreateEditUserDialog from '@/components/Admin/Dialogs/CreateEditUserDialog.vue';
import CreateEditSquadronDialog from '@/components/Admin/Dialogs/CreateEditSquadronDialog.vue';
import CreateEditBattalionDialog from '@/components/Admin/Dialogs/CreateEditBattalionDialog.vue';
import CreateEditPositionDialog from '@/components/Admin/Dialogs/CreateEditPositionDialog.vue';
import ChatConfigDialog from '@/components/Admin/Dialogs/ChatConfigDialog.vue';

import NotificationAlert from '@/components/NotificationAlert.vue';

// Initialize store and composables
const adminStore = useAdminStore();
const dialogs = useAdminDialogs();

// Confirm dialog state
const confirmDialog = ref({
  show: false,
  item: null as any,
  type: '' as 'user' | 'squadron' | 'battalion' | 'position',
});

// Computed properties
const showFilters = computed(
  () =>
    adminStore.activeView === 'squadrons' ||
    adminStore.activeView === 'positions',
);

const userSquadron = computed(
  () =>
    adminStore.squadrons.find(
      (sq) => sq.id === adminStore.currentUser?.squadronId,
    )?.name || '',
);

const userBattalion = computed(
  () =>
    adminStore.battalions.find(
      (b) => b.id === adminStore.currentUser?.squadron?.battalionId,
    )?.name || '',
);

const regularPositions = computed(() =>
  adminStore.filteredPositions.filter((position) => !position.isGroup),
);

const groupPositions = computed(() =>
  adminStore.filteredPositions.filter((position) => position.isGroup),
);

// Permission computed properties
const canEditUsers = computed(
  () => adminStore.currentUser?.role !== 'squadron_admin',
);

const canEditSquadrons = computed(
  () => adminStore.currentUser?.role !== 'squadron_admin',
);

const canEditBattalions = computed(
  () => adminStore.currentUser?.role !== 'squadron_admin',
);

const canEditPositions = computed(() =>
  ['admin', 'battalion_admin', 'squadron_admin'].includes(
    adminStore.currentUser?.role || '',
  ),
);

// Table headers
const squadronHeaders = [
  {
    title: 'Ім`я',
    key: 'name',
    width: '80%',
  },
  {
    title: 'Дії',
    key: 'actions',
    sortable: false,
    width: '20%',
  },
];

const battalionHeaders = [
  {
    title: 'Ім`я',
    key: 'name',
    width: '20%',
  },
  {
    title: 'Signal чат',
    key: 'signalChats',
    width: '30%',
  },
  {
    title: 'WhatsApp чат',
    key: 'whatsappChats',
    width: '30%',
  },
  {
    title: 'Дії',
    key: 'actions',
    sortable: false,
    width: '20%',
  },
];

const positionHeaders = [
  {
    title: 'Назва (Опис)',
    key: 'name',
    width: '55%',
  },
  {
    title: 'Підрозділ',
    key: 'squadron',
    width: '25%',
  },
  {
    title: 'Дії',
    key: 'actions',
    sortable: false,
    width: '20%',
  },
];

const userHeaders = [
  {
    title: 'Ім`я користувача',
    key: 'username',
    width: '15%',
  },
  {
    title: 'Роль',
    key: 'role',
    width: '20%',
  },
  {
    title: 'Позивний',
    key: 'callsign',
    width: '15%',
  },
  {
    title: 'Підрозділ',
    key: 'squadron',
    width: '30%',
  },
  {
    title: 'Дії',
    key: 'actions',
    sortable: false,
    width: '20%',
  },
];

// Save handlers - Updated with proper dialog state handling
async function handleUserSave(isEdit: boolean, formData: User) {
  try {
    const userData = {
      username: formData.username,
      password: formData.password,
      role: formData.role,
      callsign: formData.callsign,
      squadronId: formData.squadronId,
    };

    if (isEdit && formData.id) {
      await adminStore.updateUser(formData.id, userData);
    } else {
      await adminStore.createUser(userData);
    }

    dialogs.dialogStates.userDialog = false;
    dialogs.resetUserForm();
  } catch (error) {
    console.error('Error saving user:', error);
  }
}

async function handleSquadronSave(isEdit: boolean) {
  try {
    if (isEdit) {
      await adminStore.updateSquadron(
        dialogs.squadronForm.id!,
        dialogs.squadronForm,
      );
    } else {
      await adminStore.createSquadron(dialogs.squadronForm);
    }
    dialogs.dialogStates.squadronDialog = false;
    dialogs.resetSquadronForm();
  } catch (error) {
    console.error('Error saving squadron:', error);
  }
}

function formatChatData(chatData) {
  return chatData.map((chat) => {
    if (typeof chat === 'object' && 'name' in chat) {
      return chat; // Already formatted correctly
    } else {
      // Format to match the expected structure
      return { name: chat, config: null };
    }
  });
}

async function handleBattalionSave(isEdit: boolean, formData: Battalion) {
  // Format chats before saving
  const dataToSave = toRaw(formData);
  dataToSave.signalChatsData = formatChatData(dataToSave.signalChatsData);
  dataToSave.whatsappChatsData = formatChatData(dataToSave.whatsappChatsData);

  try {
    if (isEdit && formData.id) {
      await adminStore.updateBattalion(formData.id, dataToSave);
    } else {
      await adminStore.createBattalion(dataToSave);
    }
    dialogs.dialogStates.battalionDialog = false;
    dialogs.resetBattalionForm();

    indexSignalChats();
  } catch (error) {
    console.error('Error saving battalion:', error);
  }
}

async function handlePositionSave(isEdit: boolean, formData: Position) {
  try {
    const positionData = {
      name: formData.name,
      description: formData.description,
      squadronId: formData.squadronId,
      isGroup: formData.isGroup,
    };

    if (isEdit && formData.id) {
      await adminStore.updatePosition(formData.id, positionData);
    } else {
      await adminStore.createPosition(positionData);
    }

    dialogs.dialogStates.positionDialog = false;
    dialogs.resetPositionForm();
  } catch (error) {
    console.error('Error saving position:', error);
  }
}

async function handleChatConfigSave(config: any) {
  if (!dialogs.selectedChat.value) return;

  const { battalion, type, name } = dialogs.selectedChat.value;
  try {
    await adminStore.updateChatConfig(battalion.id, type, name, config);
    dialogs.dialogStates.chatConfigDialog = false;
    dialogs.selectedChat.value = null;
  } catch (error) {
    console.error('Error saving chat config:', error);
  }
}

// Delete confirmation handlers
function confirmDelete(
  type: 'user' | 'squadron' | 'battalion' | 'position',
  item: any,
) {
  confirmDialog.value = {
    show: true,
    item,
    type,
  };
}

async function handleConfirmDelete() {
  const { type, item } = confirmDialog.value;
  try {
    switch (type) {
      case 'user':
        await adminStore.deleteUser(item.id);
        break;
      case 'squadron':
        if (adminStore.currentUser?.squadron?.id == item.id) {
          alert('Неможливо видалити пункт управління, до якого ви належите');
        } else await adminStore.deleteSquadron(item.id);
        break;
      case 'battalion':
        await adminStore.deleteBattalion(item.id);
        break;
      case 'position':
        await adminStore.deletePosition(item.id);
        break;
    }
  } catch (error) {
    console.error(`Error deleting ${type}:`, error);
  } finally {
    confirmDialog.value.show = false;
  }
}

async function indexSignalChats() {
  try {
    const resposne = await apiClient.indexSignalChats();

    if (resposne.data.status !== 200) {
      throw new Error('Failed to index signal chats');
    }
  } catch (error) {
    console.error('Error indexing signal chats:', error);
    adminStore.showAlert('Помилка індексації чатів', 'error');
  }
}

// Lifecycle hooks
onMounted(async () => {
  console.log('AdminView mounted');
  // Make sure all dialogs are closed initially
  dialogs.resetAllDialogs();
});
</script>

<style scoped>
.admin-container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0;
  background-color: inherit;
}

.user-info-create-container {
  margin-bottom: 20px;
}

.table-container {
  overflow-x: auto;
  white-space: nowrap;
}

.actions-container {
  display: flex;
  gap: 8px;
}

.custom-btn {
  font-weight: bold;
  background-color: #061a28;
  color: white;
  border-radius: 8px;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  width: 100%;
  padding: 8px 0;
  font-size: 14px;
}

.custom-btn:hover,
.custom-btn.active-tab {
  background-color: #ffa000 !important;
}

.custom-yellow-btn {
  background-color: #ffa000;
  color: white;
}

.custom-red-btn {
  background-color: #d32f2f;
  color: white;
}

.chat-names-container {
  display: flex;
  flex-wrap: wrap;
  gap: 4px;
}

.chat-name-chip {
  margin: 2px 0;
  max-width: 150px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.battalion-name {
  color: #666;
  font-size: 0.9em;
}

.description {
  color: #666;
  font-size: 0.9em;
  font-style: italic;
}

.user-info-create-container {
  padding: 0;
}

@media (max-width: 768px) {
  .v-text-field {
    font-size: 14px;
  }

  .v-text-field input {
    padding: 8px;
  }

  .v-btn.custom-btn {
    padding: 10px 0;
    font-size: 12px;
  }

  .table-container {
    margin-top: 20px;
  }

  .button-group {
    margin-top: 10px;
  }
}
</style>
