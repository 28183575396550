import { useModel as _useModel, mergeModels as _mergeModels, defineComponent as _defineComponent } from 'vue'
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "azimuth-section" }
const _hoisted_2 = ["x2", "y2"]
const _hoisted_3 = ["x", "y"]
const _hoisted_4 = ["x2", "y2"]
const _hoisted_5 = ["points", "transform"]
const _hoisted_6 = ["d"]

import { computed } from 'vue';


export default /*@__PURE__*/_defineComponent({
  __name: 'AzimuthCircle',
  props: /*@__PURE__*/_mergeModels({
  azimuthError: Boolean,
}, {
    "isAzimuthUnknown": {},
    "isAzimuthUnknownModifiers": {},
    "azimuth": {},
    "azimuthModifiers": {},
  }),
  emits: /*@__PURE__*/_mergeModels(['validateAzimuth'], ["update:isAzimuthUnknown", "update:azimuth"]),
  setup(__props, { emit: __emit }) {

const emit = __emit;

const isAzimuthUnknown = _useModel(__props, 'isAzimuthUnknown');
const azimuth = _useModel(__props, 'azimuth');



const showAzimuthArrow = computed(
  () =>
    !isAzimuthUnknown.value && !isNaN(Number(azimuth.value)) && azimuth.value,
);

const polugonArrowRotate = computed(
  () =>
    `rotate(180 ${
      100 + 75 * Math.cos(((Number(azimuth.value) - 90) * Math.PI) / 180)
    } ${100 + 75 * Math.sin(((Number(azimuth.value) - 90) * Math.PI) / 180)})`,
);

const computeSectorPath = (startAngle, endAngle, radius = 70) => {
  const startX = 100 + radius * Math.cos(((startAngle - 90) * Math.PI) / 180);
  const startY = 100 + radius * Math.sin(((startAngle - 90) * Math.PI) / 180);
  const endX = 100 + radius * Math.cos(((endAngle - 90) * Math.PI) / 180);
  const endY = 100 + radius * Math.sin(((endAngle - 90) * Math.PI) / 180);

  const largeArcFlag = endAngle - startAngle > 180 ? 1 : 0;

  return `M100,100 L${startX},${startY} A${radius},${radius} 0 ${largeArcFlag},1 ${endX},${endY} Z`;
};

const sectorPaths = computed(() => {
  const rangeAzimuth = azimuth.value.split('-');

  if (rangeAzimuth.length !== 2) return [];

  return [computeSectorPath(rangeAzimuth[0], rangeAzimuth[1])];
});

const markerAngles = [0, 30, 60, 90, 120, 150, 180, 210, 240, 270, 300, 330];

const setAzimuth = (event: MouseEvent) => {
  if (isAzimuthUnknown.value) return;

  const target = event.target as SVGElement;
  const { left, top, width, height } = target.getBoundingClientRect();

  const centerX = left + width / 2;
  const centerY = top + height / 2;

  const deltaX = event.clientX - centerX;
  const deltaY = event.clientY - centerY;

  const distanceFromCenter = Math.sqrt(deltaX ** 2 + deltaY ** 2);

  const threshold = 20;

  if (distanceFromCenter > threshold) {
    const radians = Math.atan2(deltaY, deltaX);
    const degrees = (((radians * (180 / Math.PI) + 360) % 360) + 90) % 360;

    azimuth.value = Math.round(degrees).toString();
    emit('validateAzimuth');
  }
};

const getArrowHeadPoints = () => {
  if (isAzimuthUnknown.value || isNaN(Number(azimuth.value))) {
    return '0,0 0,0 0,0'; // Return invisible points
  }

  const length = 80;
  const arrowSize = 10;
  const angle = parseFloat(azimuth.value) - 90;
  const x = 100 + length * Math.cos((angle * Math.PI) / 180);
  const y = 100 + length * Math.sin((angle * Math.PI) / 180);

  const leftX = x - arrowSize * Math.cos(((angle + 150) * Math.PI) / 180);
  const leftY = y - arrowSize * Math.sin(((angle + 150) * Math.PI) / 180);
  const rightX = x - arrowSize * Math.cos(((angle - 150) * Math.PI) / 180);
  const rightY = y - arrowSize * Math.sin(((angle - 150) * Math.PI) / 180);

  return `${x},${y} ${leftX},${leftY} ${rightX},${rightY}`;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(), _createElementBlock("svg", {
      class: "azimuth-circle elevation-3",
      viewBox: "0 0 200 200",
      onClick: setAzimuth,
      style: _normalizeStyle({ opacity: isAzimuthUnknown.value ? '0.6' : '1' })
    }, [
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(markerAngles, (angle) => {
        return _createElementVNode("line", {
          key: angle,
          x1: 100,
          y1: 100,
          x2: 100 + 70 * Math.cos(((angle - 90) * Math.PI) / 180),
          y2: 100 + 70 * Math.sin(((angle - 90) * Math.PI) / 180),
          stroke: "black",
          "stroke-dasharray": "4"
        }, null, 8, _hoisted_2)
      }), 64)),
      (_openBlock(), _createElementBlock(_Fragment, null, _renderList(markerAngles, (angle) => {
        return _createElementVNode("text", {
          key: 'text' + angle,
          x: 100 + 85 * Math.cos(((angle - 90) * Math.PI) / 180),
          y: 100 + 85 * Math.sin(((angle - 90) * Math.PI) / 180),
          "font-size": "12",
          "text-anchor": "middle",
          "dominant-baseline": "middle"
        }, _toDisplayString(angle), 9, _hoisted_3)
      }), 64)),
      (showAzimuthArrow.value)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createElementVNode("line", {
              x1: 100,
              y1: 100,
              x2: 100 + 65 * Math.cos(((Number(azimuth.value) - 90) * Math.PI) / 180),
              y2: 100 + 65 * Math.sin(((Number(azimuth.value) - 90) * Math.PI) / 180),
              stroke: "var(--primary-arrow-color)",
              "stroke-width": "2"
            }, null, 8, _hoisted_4),
            _createElementVNode("polygon", {
              points: getArrowHeadPoints(),
              fill: "var(--primary-arrow-color)",
              transform: polugonArrowRotate.value
            }, null, 8, _hoisted_5)
          ], 64))
        : _createCommentVNode("", true),
      (!__props.azimuthError)
        ? (_openBlock(true), _createElementBlock(_Fragment, { key: 1 }, _renderList(sectorPaths.value, (path, index) => {
            return (_openBlock(), _createElementBlock("path", {
              "data-pw": "range-azimuth-path",
              key: index,
              d: path,
              fill: "var(--primary-color)"
            }, null, 8, _hoisted_6))
          }), 128))
        : _createCommentVNode("", true)
    ], 4))
  ]))
}
}

})