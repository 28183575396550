import _isInteger from 'lodash.isinteger';

export const rules = {
  requiredWithoutMessage: (value: string) => {
    return !!value;
  },
  required: (value: string) => !!value || "Обов'язкове поле",
  maxLength: (max: number) => (value: string) =>
    value.length <= max || `Максимальна довжина ${max} символів`,
  isInteger: (value: string) => _isInteger(value) || 'Ціле число',

  isGreaterThanZero(value: string) {
    return (_isInteger(value) && Number(value) > 0) || 'Ціле число більше нуля';
  },
};
