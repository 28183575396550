import { LocaleMessages } from 'vuetify';
import { uk as uk_vuetify } from 'vuetify/locale';

const uk: LocaleMessages = {
  ...uk_vuetify,
  open: 'Відкрити',
  close: 'Закрити',
  datePicker: {
    header: 'Оберіть дату',
    title: 'Оберіть дату',
    cancel: 'Скасувати',
    confirm: 'Підтвердити',
    selectDate: 'Оберіть дату',
    selectTime: 'Оберіть час',
  },
  dataFooter: {
    itemsPerPageText: 'Рядків на сторінці:',
    itemsPerPageAll: 'Всі',
    nextPage: 'Наступна сторінка',
    prevPage: 'Попередня сторінка',
    firstPage: 'Перша сторінка',
    lastPage: 'Остання сторінка',
    pageText: '{0}-{1} з {2}',
  },
  dataIterator: {
    loadingText: 'Завантаження...',
  },
  pagination: {
    ariaLabel: {
      root: 'Пагінація',
    },
  },
};

export default uk;
