import { defineComponent as _defineComponent } from 'vue'
import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, withCtx as _withCtx, Fragment as _Fragment } from "vue"

const _hoisted_1 = { class: "d-flex align-center justify-space-between ga-1 mb-2" }
const _hoisted_2 = { class: "d-flex align-center ga-2" }
const _hoisted_3 = {
  key: 2,
  class: "d-flex align-center justify-space-between ga-1 mt-2 action-box"
}

import { computed, onMounted, onUnmounted, ref, watch } from 'vue';
import type { Position } from '@/types/events';
import { useEventCreationStore } from '@/stores/eventCreation';

interface Props {
  position: Position;
  eventMessage: string;
}


export default /*@__PURE__*/_defineComponent({
  __name: 'EventPreviewContent',
  props: {
    position: {},
    eventMessage: {}
  },
  emits: ["handleSubmit"],
  setup(__props: any) {



const props = __props;

const eventStore = useEventCreationStore();

const isCopied = ref(false);
const isEditable = ref(false);

const chatData = computed(() =>
  eventStore.formattedChatData(props.position, props.eventMessage),
);

async function copyToClipboard(textToCopy: string) {
  await navigator.clipboard.writeText(textToCopy);
  isCopied.value = true;

  setTimeout(() => {
    isCopied.value = false;
  }, 2000);
}

function handleEditable() {
  isEditable.value = !isEditable.value;

  eventStore.$patch({
    editMessage: chatData.value,
  });
}

function reset() {
  eventStore.$patch({
    editMessage: '',
  });
}

onMounted(() => {
  reset();
});

onUnmounted(() => {
  reset();
});

watch(
  () => eventStore.notesMessage,
  () => {
    reset();
    isEditable.value = false;
  },
);

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_textarea = _resolveComponent("v-textarea")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", _hoisted_1, [
      _cache[3] || (_cache[3] = _createElementVNode("h4", { class: "headline" }, "Попередній перегляд", -1)),
      _createElementVNode("div", _hoisted_2, [
        (!isEditable.value)
          ? (_openBlock(), _createBlock(_component_v_btn, {
              key: 0,
              size: "x-small",
              variant: "outlined",
              icon: "mdi-pencil",
              onClick: handleEditable
            }))
          : (_openBlock(), _createBlock(_component_v_btn, {
              key: 1,
              size: "x-small",
              variant: "outlined",
              icon: "mdi-refresh",
              class: "mobile-refresh-action",
              onClick: handleEditable
            })),
        _createVNode(_component_v_btn, {
          size: "x-small",
          onClick: _cache[0] || (_cache[0] = ($event: any) => (copyToClipboard(_unref(eventStore).editMessage || chatData.value))),
          variant: "outlined",
          icon: isCopied.value ? 'mdi-check-bold' : 'mdi-content-copy'
        }, null, 8, ["icon"])
      ])
    ]),
    (isEditable.value)
      ? (_openBlock(), _createBlock(_component_v_textarea, {
          key: 0,
          class: "pa-0 ma-0 custom-textarea",
          label: "",
          variant: "outlined",
          modelValue: _unref(eventStore).editMessage,
          "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_unref(eventStore).editMessage) = $event)),
          rows: "5",
          "max-rows": "16",
          "auto-grow": "",
          density: "compact",
          "hide-details": ""
        }, null, 8, ["modelValue"]))
      : (_openBlock(), _createElementBlock("p", {
          key: 1,
          class: "preview-text",
          style: {"white-space":"break-spaces","letter-spacing":"0.15px"},
          onClick: handleEditable
        }, _toDisplayString(chatData.value), 1)),
    (isEditable.value)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_v_btn, {
            class: "custom-default-btn",
            onClick: handleEditable
          }, {
            default: _withCtx(() => _cache[4] || (_cache[4] = [
              _createTextVNode("Скинути")
            ])),
            _: 1
          }),
          _createVNode(_component_v_btn, {
            disabled: _unref(eventStore).loading,
            onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('handleSubmit'))),
            class: "custom-green-btn"
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode(" Створити подію ")
            ])),
            _: 1
          }, 8, ["disabled"])
        ]))
      : _createCommentVNode("", true)
  ], 64))
}
}

})