import { defineComponent as _defineComponent } from 'vue'
import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, unref as _unref, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "small-text" }

import { ref, computed } from 'vue';
import { useAdminStore } from '@/stores/admin';
import { logout } from '@/services/keycloak';
import version from '@/version';
import { UserRole } from '@/types/admin';

// State

export default /*@__PURE__*/_defineComponent({
  __name: 'DashboardView',
  setup(__props) {

const adminStore = useAdminStore();
const drawer = ref(false);

// Navigation tabs configuration
const tabs = [
  {
    name: 'Event',
    title: 'Події',
    icon: 'mdi-calendar',
    access: (role: UserRole) =>
      [
        UserRole.user,
        UserRole.admin,
        UserRole.battalion_admin,
        UserRole.squadron_admin,
      ].includes(role),
  },
  {
    name: 'Stats',
    title: 'Статистика',
    icon: 'mdi-chart-bar',
    access: (role: UserRole) =>
      [
        UserRole.stat,
        UserRole.admin,
        UserRole.battalion_admin,
        UserRole.squadron_admin,
      ].includes(role),
  },
  {
    name: 'UAVStats',
    title: 'Статистика БПЛА',
    icon: 'mdi-chart-bar',
    access: (role: UserRole) =>
      [UserRole.uav_stat, UserRole.admin, UserRole.battalion_admin].includes(
        role,
      ),
  },
  {
    name: 'Admin',
    title: 'Адміністрування',
    icon: 'mdi-account-cog',
    access: (role: UserRole) =>
      [
        UserRole.admin,
        UserRole.battalion_admin,
        UserRole.squadron_admin,
      ].includes(role),
  },
];

// Computed
const availableTabs = computed(() => {
  return tabs.filter(
    (tab) =>
      adminStore.currentUser?.role && tab.access(adminStore.currentUser?.role),
  );
});

return (_ctx: any,_cache: any) => {
  const _component_v_app_bar_nav_icon = _resolveComponent("v-app-bar-nav-icon")!
  const _component_v_toolbar_title = _resolveComponent("v-toolbar-title")!
  const _component_v_icon = _resolveComponent("v-icon")!
  const _component_v_chip = _resolveComponent("v-chip")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_app_bar = _resolveComponent("v-app-bar")!
  const _component_v_list_item_title = _resolveComponent("v-list-item-title")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_list = _resolveComponent("v-list")!
  const _component_v_navigation_drawer = _resolveComponent("v-navigation-drawer")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_v_main = _resolveComponent("v-main")!
  const _component_v_app = _resolveComponent("v-app")!

  return (_openBlock(), _createBlock(_component_v_app, null, {
    default: _withCtx(() => [
      _createVNode(_component_v_app_bar, {
        app: "",
        elevation: "4"
      }, {
        default: _withCtx(() => [
          (availableTabs.value.length > 1)
            ? (_openBlock(), _createBlock(_component_v_app_bar_nav_icon, {
                key: 0,
                onClick: _cache[0] || (_cache[0] = ($event: any) => (drawer.value = !drawer.value))
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_v_toolbar_title, null, {
            default: _withCtx(() => [
              _cache[3] || (_cache[3] = _createTextVNode("A2 ")),
              _createElementVNode("span", _hoisted_1, "v." + _toDisplayString(_unref(version)), 1)
            ]),
            _: 1
          }),
          (_unref(adminStore).currentUser)
            ? (_openBlock(), _createBlock(_component_v_chip, {
                key: 1,
                color: "primary",
                "text-color": "white",
                class: "mr-4"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_icon, { start: "" }, {
                    default: _withCtx(() => _cache[4] || (_cache[4] = [
                      _createTextVNode("mdi-account")
                    ])),
                    _: 1
                  }),
                  _createTextVNode(" " + _toDisplayString(_unref(adminStore).currentUser.callsign), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true),
          _createVNode(_component_v_btn, {
            onClick: _unref(logout),
            color: "primary",
            class: "custom-btn"
          }, {
            default: _withCtx(() => _cache[5] || (_cache[5] = [
              _createTextVNode(" Вихід ")
            ])),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }),
      (availableTabs.value.length > 1)
        ? (_openBlock(), _createBlock(_component_v_navigation_drawer, {
            key: 0,
            modelValue: drawer.value,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((drawer).value = $event)),
            app: ""
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_list, { dense: "" }, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(availableTabs.value, (tab) => {
                    return (_openBlock(), _createBlock(_component_v_list_item, {
                      key: tab.name,
                      to: { name: tab.name },
                      onClick: _cache[1] || (_cache[1] = ($event: any) => (drawer.value = false)),
                      value: tab.name
                    }, {
                      prepend: _withCtx(() => [
                        _createVNode(_component_v_icon, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(tab.icon), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      default: _withCtx(() => [
                        _createVNode(_component_v_list_item_title, null, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(tab.title), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["to", "value"]))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"]))
        : _createCommentVNode("", true),
      _createVNode(_component_v_main, null, {
        default: _withCtx(() => [
          _createVNode(_component_router_view)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}
}

})