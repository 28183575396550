import axios from 'axios';
import { logout } from './keycloak';
import { loadEnv } from '@/services/envService';

const axiosClientPromise = loadEnv().then((envData) => {
  const baseURL = envData.VUE_APP_IP;

  const axiosClient = axios.create({
    baseURL: `${baseURL}/api`,
    headers: { 'Content-Type': 'application/json' },
  });

  axiosClient.interceptors.request.use(
    (config) => {
      const token = sessionStorage.getItem('accessToken');
      if (token) {
        config.headers.Authorization = `Bearer ${token}`;
      }
      return config;
    },
    (error) => {
      if (error.response?.status === 401) return logout();
      return Promise.reject(error);
    },
  );

  axiosClient.interceptors.response.use(
    (response) => response,
    (error) => {
      console.error('Error:', error);
      if (error.response?.status === 401) return logout();
      return Promise.reject(error);
    },
  );

  return axiosClient;
});

export class ApiClient {
  constructor(axiosClientPromise) {
    this.axiosClientPromise = axiosClientPromise;
  }

  async getAxiosClient() {
    return await this.axiosClientPromise;
  }

  async getSquadrons() {
    const client = await this.getAxiosClient();
    return client.get('/squadrons');
  }

  async createSquadron(data) {
    const client = await this.getAxiosClient();
    return client.post('/squadrons', data);
  }

  async editSquadron(id, data) {
    const client = await this.getAxiosClient();
    return client.patch(`/squadrons/${id}`, data);
  }

  async removeSquadron(id) {
    const client = await this.getAxiosClient();
    return client.delete(`/squadrons/${id}`);
  }

  async getBatallions() {
    const client = await this.getAxiosClient();
    return client.get('/battalions');
  }

  async createBatallion(data) {
    const client = await this.getAxiosClient();
    return client.post('/battalions', data);
  }

  async editBatallion(id, data) {
    const client = await this.getAxiosClient();
    return client.patch(`/battalions/${id}`, data);
  }

  async removeBatallion(id) {
    const client = await this.getAxiosClient();
    return client.delete(`/battalions/${id}`);
  }

  async getPositions() {
    const client = await this.getAxiosClient();
    return client.get('/positions');
  }

  async createPosition(data) {
    const client = await this.getAxiosClient();
    return client.post('/positions', data);
  }

  async editPosition(id, data) {
    const client = await this.getAxiosClient();
    return client.patch(`/positions/${id}`, data);
  }

  async removePosition(id) {
    const client = await this.getAxiosClient();
    return client.delete(`/positions/${id}`);
  }

  async getAllUsers() {
    const client = await this.getAxiosClient();
    return client.get('/users');
  }

  async createUser(data) {
    const client = await this.getAxiosClient();
    return client.post('/users', data);
  }

  async editUser(id, data) {
    const client = await this.getAxiosClient();
    return client.patch(`/users/${id}`, data);
  }

  async removeUser(id) {
    const client = await this.getAxiosClient();
    return client.delete(`/users/${id}`);
  }

  async getEvents(page, limit, startDate, endDate) {
    const client = await this.getAxiosClient();
    return client.get('/events', {
      params: {
        page,
        limit,
        startDate,
        endDate,
      },
    });
  }

  async getUavEvents(page, limit, startDate, endDate) {
    const client = await this.getAxiosClient();
    return client.get('/events/uav', {
      params: {
        page,
        limit,
        startDate,
        endDate,
      },
    });
  }

  async createEvent(data) {
    const client = await this.getAxiosClient();
    return client.post('/events', data);
  }

  async indexSignalChats() {
    const client = await this.getAxiosClient();
    return client.get('/index-signal-chats');
  }

  async getMe() {
    const client = await this.getAxiosClient();
    return client.get('/users/me');
  }

  async getVersion() {
    const client = await this.getAxiosClient();
    return client.get('/ui-version');
  }

  async handleExportStats(url, { startDate, endDate, fileName }) {
    const apiUrl = `${url}?startDate=${startDate}&endDate=${endDate}`;
    const client = await this.getAxiosClient();
    try {
      const response = await client.get(apiUrl, {
        responseType: 'blob',
        headers: {
          Accept:
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        },
      });

      const blob = new Blob([response.data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `${fileName}.xlsx`;
      link.click();
    } catch (e) {
      console.error('Error exporting events:', e);
    }
  }
}

export default new ApiClient(axiosClientPromise);
