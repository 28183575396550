import { defineStore } from 'pinia';
import { EventPath } from '@/types/events';

interface EventSessionState {
  valid: boolean | null;
  lastEventPaths: {
    [key: string]: EventPath[];
  };
}

export const useEventsSessionStore = defineStore('eventsSession', {
  state: (): EventSessionState => ({
    valid: null,
    lastEventPaths: {},
  }),

  getters: {
    lastEventPath: (state) => (key: string) => {
      return state.lastEventPaths[key] ?? [];
    },
  },

  actions: {
    setPath(key: string, path: EventPath[]) {
      this.lastEventPaths[key] = path; // Оновлюємо шлях для певного ключа
    },

    reset() {
      this.valid = true;
      this.lastEventPaths = {};
    },
  },
});
