<template>
  <v-card variant="flat">
    <v-card-text class="pa-0">
      <event-model-count
        label="Модель"
        v-model:ammoModel="ammoModel"
        v-model:ammoAmount="ammoAmount"
      />
    </v-card-text>
    <v-card-actions class="d-flex justify-end mt-0">
      <slot />
      <v-btn
        class="custom-green-btn px-10"
        @click="proceed"
        :disabled="disabled"
        ref="buttonExposeRef"
        :size="smAndUp ? 'large' : 'default'"
        >Далі</v-btn
      >
    </v-card-actions>
  </v-card>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useDisplay } from 'vuetify';
import EventModelCount from '@/components/EventCreation/EventInputModelCount.vue';

const { smAndUp } = useDisplay();

const emit = defineEmits(['input']);

const props = defineProps<{
  ammoType: string;
}>();

const ammoModel = ref('');
const ammoAmount = ref(1);
const disabled = computed(() => !(ammoAmount.value > 0));
const buttonExposeRef = ref<HTMLButtonElement | null>(null);

defineExpose({
  buttonExposeRef,
});

type LinkedObjectType = {
  ammunitionDetails?: Array<{
    ammoType: string;
    ammoModel: string;
    ammoAmount: number;
  }>;
};

function proceed() {
  const values: string[] = [];
  const linkedObject: LinkedObjectType = {};

  linkedObject.ammunitionDetails = [
    {
      ammoAmount: ammoAmount.value,
      ammoModel: ammoModel.value,
      ammoType: props.ammoType,
    },
  ];

  emit(
    'input',
    values,
    Object.keys(linkedObject).length ? linkedObject : undefined,
  );
}
</script>
