import { defineStore } from 'pinia';
import Position from '@/models/Position';

const REMINDER_INTERVALS = [10, 30, 60];

export function playBeepSound() {
  const audioCtx = new (window.AudioContext || window.webkitAudioContext)();
  const oscillator = audioCtx.createOscillator();
  const gainNode = audioCtx.createGain();

  oscillator.connect(gainNode);
  gainNode.connect(audioCtx.destination);

  oscillator.type = 'square';
  oscillator.frequency.setValueAtTime(440, audioCtx.currentTime);
  gainNode.gain.setValueAtTime(0.1, audioCtx.currentTime);

  oscillator.start();
  oscillator.stop(audioCtx.currentTime + 0.2);
}

export const useStore = defineStore('store', {
  state: () => ({
    activePositions: JSON.parse(localStorage.getItem('activePositions')) || [],
    positionTimers: {}, // New map to track timers by position ID
  }),
  actions: {
    addOrUpdatePosition(positionData) {
      const existingIndex = this.activePositions.findIndex(
        (p) => p.id === positionData.id,
      );
      const position = new Position(positionData);

      if (existingIndex > -1) {
        this.activePositions[existingIndex] = position;

        if (!position.isUnderAttack && !position.isUnderFire) {
          this.clearTimers(position.id);
        }
      } else {
        this.activePositions.push(position);
      }

      if (position.isUnderAttack || position.isUnderFire) {
        this.scheduleReminders(position);
      }

      this.persistToLocalStorage();
    },

    removePosition(positionId) {
      this.clearTimers(positionId);

      const index = this.activePositions.findIndex((p) => p.id === positionId);
      if (index > -1) {
        this.activePositions.splice(index, 1);
        this.persistToLocalStorage();
      }
    },

    clearAll() {
      Object.keys(this.positionTimers).forEach((positionId) => {
        this.clearTimers(positionId);
      });

      this.activePositions = [];
      localStorage.removeItem('activePositions');
    },

    scheduleReminders(position) {
      this.clearTimers(position.id);

      const timers = REMINDER_INTERVALS.map((minutes) =>
        setTimeout(() => {
          playBeepSound();
          const status = [
            position.isUnderAttack && 'штурм',
            position.isUnderFire && 'обстріл',
          ]
            .filter(Boolean)
            .join(' або ');
          alert(`Чи припинилися ${status} позиції "${position.name}"`);
        }, minutes * 60 * 1000),
      );

      this.positionTimers[position.id] = timers;
    },

    clearTimers(positionId) {
      if (this.positionTimers[positionId]) {
        this.positionTimers[positionId].forEach((timer) => {
          clearTimeout(timer);
        });
        delete this.positionTimers[positionId];
      }
    },

    persistToLocalStorage() {
      localStorage.setItem(
        'activePositions',
        JSON.stringify(this.activePositions),
      );
    },
  },
});
