import { defineComponent as _defineComponent } from 'vue'
import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "d-flex align-stretch align-md-start justify-center justify-md-space-between ga-1 ga-md-3 flex-column-reverse flex-md-row" }
const _hoisted_2 = { class: "input-section" }
const _hoisted_3 = { "data-pw": "Не можливо визначити азимут" }
const _hoisted_4 = { class: "distance-section" }
const _hoisted_5 = { "data-pw": "Не можливо визначити дистанцію" }
const _hoisted_6 = { class: "map-container" }

import { ref, watch, nextTick, computed } from 'vue';
import { LMap, LTileLayer, LMarker } from 'vue3-leaflet';
import type { LatLng } from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { formatMGRS } from '@/services/utils';
import { forward } from 'mgrs';
import { EventLocation } from '@/types/events';
import { useEventCreationStore } from '@/stores/eventCreation';
import AzimuthCircle from '@/components/AzimuthCircle.vue';
import { useDisplay } from 'vuetify';

interface Props {
  dialog: boolean;
}

const imageryUrl =
  'https://server.arcgisonline.com/ArcGIS/rest/services/World_Imagery/MapServer/tile/{z}/{y}/{x}';
const imageryAttribution =
  'Tiles &copy; Esri &mdash; Source: Esri, i-cubed, USDA, USGS, AEX, GeoEye, Getmapping, Aerogrid, IGN, IGP, UPR-EGP, and the GIS User Community';
const labelsUrl =
  'https://server.arcgisonline.com/ArcGIS/rest/services/Reference/World_Boundaries_and_Places/MapServer/tile/{z}/{y}/{x}';
const labelsAttribution = 'Tiles &copy; Esri';

// Map methods

export default /*@__PURE__*/_defineComponent({
  __name: 'MapSelectionView',
  props: {
    dialog: { type: Boolean }
  },
  emits: ["update:dialog", "location-selected"],
  setup(__props: any, { expose: __expose, emit: __emit }) {

const { smAndUp, smAndDown } = useDisplay();

const props = __props;

const eventStore = useEventCreationStore();

const emit = __emit;

// Refs
const activePanel = ref(0);
const zoom = ref(15);
const center = ref<[number, number]>([48.0159416, 37.7962839]);
const marker = ref<LatLng | null>(null);
const map = ref<any>(null);
const mapVisible = ref(false);

const distance = ref('');
const azimuth = ref('');
const azimuthError = ref(false);
const distanceError = ref(false);
const isDistanceUnknown = ref(false);
const isAzimuthUnknown = ref(false);
const proceedDisabledButton = ref(false);

const buttonExposeRef = ref<HTMLButtonElement | null>(null);

const ticks = computed(() => {
  if (smAndDown.value) {
    return {
      0: '0',
      1500: '1000',
      3000: '>3000',
    };
  }

  return {
    0: '0',
    500: '500',
    1000: '1000',
    1500: '1500',
    2000: '2000',
    2500: '2500',
    3000: '>3000',
  };
});

__expose({
  buttonExposeRef,
});

// Map constants
const onMapReady = (mapInstance: any) => {
  map.value = mapInstance;
  loadLastLocation();
};

const loadLastLocation = () => {
  try {
    const lastLocationStr = localStorage.getItem('lastLocation');
    if (lastLocationStr) {
      const lastLocation = JSON.parse(lastLocationStr);
      center.value = [lastLocation.lat, lastLocation.lng];
      panToLocation(center.value);
    } else {
      getUserLocation();
    }
  } catch (error) {
    console.error('Error loading last location:', error);
    getUserLocation();
  }
};

const handleDistanceUnknownChange = () => {
  if (isDistanceUnknown.value) {
    distance.value = 'не можливо визначити';
    distanceError.value = false;
  } else {
    distance.value = '';
  }
};

const handleAzimuthUnknownChange = () => {
  if (isAzimuthUnknown.value) {
    azimuth.value = 'не можливо визначити';
    azimuthError.value = false;
  } else {
    azimuth.value = '';
  }
};

const getUserLocation = () => {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (position) => {
        const userLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
        center.value = [userLocation.lat, userLocation.lng];
        panToLocation(center.value);
      },
      () => {
        alert('Неможливо отримати ваші координати, оберіть точку вручну');
      },
    );
  }
};

const panToLocation = (location: [number, number]) => {
  if (map.value) {
    map.value.setView(location, zoom.value);
  }
};

const onMapClick = (event: { latlng: LatLng }) => {
  marker.value = event.latlng;
  center.value = [event.latlng.lat, event.latlng.lng];
  localStorage.setItem('lastLocation', JSON.stringify(marker.value));
};

// Validation methods
const validateAzimuth = () => {
  if (isAzimuthUnknown.value) {
    azimuthError.value = false;
    return;
  }
  if (azimuth.value === '') {
    azimuthError.value = false;
    return;
  }

  const azimuthValue = azimuth.value.toString();
  const singleAzimuthPattern = /^\s*\d{1,3}\s*$/;
  const rangeAzimuthPattern = /^\s*\d{1,3}\s*-\s*\d{1,3}\s*$/;

  const azimuthParts = azimuthValue.split(',').map((part) => part.trim());

  azimuthError.value = azimuthParts.some((part) => {
    if (rangeAzimuthPattern.test(part)) {
      const [minAzimuth, maxAzimuth] = part
        .split('-')
        .map((val) => parseFloat(val.trim()));
      return (
        isNaN(minAzimuth) ||
        isNaN(maxAzimuth) ||
        maxAzimuth <= 0 ||
        maxAzimuth > 360 ||
        minAzimuth >= maxAzimuth
      );
    } else if (singleAzimuthPattern.test(part)) {
      const parsedAzimuth = parseFloat(part);
      return isNaN(parsedAzimuth) || parsedAzimuth < 0 || parsedAzimuth > 360;
    }
    return true;
  });
  azimuth.value = azimuthValue.replace(/\s/g, '');
};

const validateDistance = () => {
  if (isDistanceUnknown.value) {
    distanceError.value = false;
    return;
  }

  if (distance.value === '') {
    distanceError.value = false;
    return;
  }

  const parsedDistance = parseFloat(distance.value);
  distanceError.value =
    isNaN(parsedDistance) ||
    parsedDistance < 0 ||
    !/^\d+([+-]?)$/.test(distance.value);
};

const onAzimuthChange = () => {
  validateAzimuth();
};

const onDistanceChange = () => {
  validateDistance();
};

// Dialog methods
const handleDialogUpdate = (value: boolean) => {
  if (!value) {
    resetState();
  }
  emit('update:dialog', value);
};

const resetState = () => {
  distance.value = '';
  azimuth.value = '';
  marker.value = null;
  mapVisible.value = false;
  activePanel.value = 0;
  distanceError.value = false;
  azimuthError.value = false;
  isDistanceUnknown.value = false;
  isAzimuthUnknown.value = false;
};

const proceed = () => {
  const locationMessage = [];
  let selectedAzimuth: number | string = -1;
  const coordinates = marker.value || { lat: 0, lng: 0 };
  const coordinatesExists = coordinates.lat !== 0 || coordinates.lng !== 0;

  if (isAzimuthUnknown.value) {
    locationMessage.push('Азимут: не можливо визначити');
    azimuth.value = '';
  } else if (azimuth.value !== '') {
    locationMessage.push(`Азимут: ${azimuth.value}`);
  }

  if (
    typeof azimuth.value === 'string' &&
    azimuth.value !== '' &&
    isNaN(+azimuth.value)
  ) {
    selectedAzimuth = azimuth.value;
  } else if (azimuth.value !== '' && !isNaN(+azimuth.value)) {
    selectedAzimuth = parseInt(azimuth.value);
  }

  if (isDistanceUnknown.value) {
    locationMessage.push('Дистанція: не можливо визначити');
    distance.value = '';
  } else if (distance.value !== '') {
    locationMessage.push(`Дистанція: ${distance.value} м`);
  }

  if (coordinatesExists) {
    const mgrs = forward([coordinates.lat, coordinates.lng]);
    locationMessage.push(`Координати: ${formatMGRS(mgrs)}`);
  }

  eventStore.$patch({
    locationMessage: locationMessage.join('\n'),
  });

  emit('location-selected', {
    distance: distance.value !== '' ? parseInt(distance.value) : -1,
    azimuth: selectedAzimuth,
    lat: coordinates.lat || null,
    lng: coordinates.lng || null,
    mgrs: coordinatesExists
      ? forward([coordinates.lng, coordinates.lat])
      : 'N/A',
  });
};

// Watchers
watch(
  () => props.dialog,
  (newValue) => {
    if (!newValue) {
      resetState();
    }
  },
);

watch(activePanel, async (newVal) => {
  if (newVal === 1) {
    mapVisible.value = true;
    await nextTick();
    setTimeout(() => {
      if (map.value) {
        map.value.invalidateSize();
      }
    }, 500);
  } else {
    mapVisible.value = false;
  }
});

watch([azimuthError, distanceError], (value) => {
  if (value[0] || value[1]) {
    proceedDisabledButton.value = true;
  } else {
    proceedDisabledButton.value = false;
  }
});

watch(distance, () => {
  distanceError.value = false;
});

return (_ctx: any,_cache: any) => {
  const _component_v_card_title = _resolveComponent("v-card-title")!
  const _component_v_expansion_panel_title = _resolveComponent("v-expansion-panel-title")!
  const _component_v_text_field = _resolveComponent("v-text-field")!
  const _component_v_checkbox = _resolveComponent("v-checkbox")!
  const _component_v_divider = _resolveComponent("v-divider")!
  const _component_v_slider = _resolveComponent("v-slider")!
  const _component_v_expansion_panel_text = _resolveComponent("v-expansion-panel-text")!
  const _component_v_expansion_panel = _resolveComponent("v-expansion-panel")!
  const _component_v_expansion_panels = _resolveComponent("v-expansion-panels")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    "model-value": _ctx.dialog,
    "onUpdate:modelValue": handleDialogUpdate,
    "max-width": "800",
    persistent: ""
  }, {
    default: _withCtx(() => [
      _createVNode(_component_v_card, null, {
        default: _withCtx(() => [
          _createVNode(_component_v_card_title, { class: "px-6 pb-0 pt-3" }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "title")
            ]),
            _: 3
          }),
          _createVNode(_component_v_card_text, { class: "pt-0 px-6 pb-3" }, {
            default: _withCtx(() => [
              _createVNode(_component_v_expansion_panels, {
                modelValue: activePanel.value,
                "onUpdate:modelValue": _cache[7] || (_cache[7] = ($event: any) => ((activePanel).value = $event)),
                accordion: ""
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_v_expansion_panel, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_expansion_panel_title, { class: "header" }, {
                        default: _withCtx(() => _cache[8] || (_cache[8] = [
                          _createElementVNode("span", { class: "header-text" }, "Азимут та дистанція події", -1)
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_expansion_panel_text, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_1, [
                            _createElementVNode("div", _hoisted_2, [
                              _cache[9] || (_cache[9] = _createElementVNode("div", { class: "text-h6" }, "Азимут", -1)),
                              _cache[10] || (_cache[10] = _createElementVNode("div", { class: "helper-text" }, " Формат азимуту: кілька значень через кому, діапазон через тире, наприклад 70-75, 250, 40-50 ", -1)),
                              _createVNode(_component_v_text_field, {
                                label: "Азимут",
                                modelValue: azimuth.value,
                                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((azimuth).value = $event)),
                                modelModifiers: { trim: true },
                                type: "text",
                                class: "azimuth-input",
                                error: azimuthError.value,
                                hint: azimuthError.value ? 'Азимут некоректний' : '',
                                "hide-details": "auto",
                                onInput: onAzimuthChange,
                                disabled: isAzimuthUnknown.value
                              }, null, 8, ["modelValue", "error", "hint", "disabled"]),
                              _createElementVNode("div", _hoisted_3, [
                                _createVNode(_component_v_checkbox, {
                                  modelValue: isAzimuthUnknown.value,
                                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((isAzimuthUnknown).value = $event)),
                                  label: "Не можливо визначити",
                                  "hide-details": "",
                                  onChange: handleAzimuthUnknownChange
                                }, null, 8, ["modelValue"])
                              ])
                            ]),
                            _createVNode(AzimuthCircle, {
                              azimuthError: azimuthError.value,
                              isAzimuthUnknown: isAzimuthUnknown.value,
                              "onUpdate:isAzimuthUnknown": _cache[2] || (_cache[2] = ($event: any) => ((isAzimuthUnknown).value = $event)),
                              azimuth: azimuth.value,
                              "onUpdate:azimuth": _cache[3] || (_cache[3] = ($event: any) => ((azimuth).value = $event)),
                              onValidateAzimuth: validateAzimuth
                            }, null, 8, ["azimuthError", "isAzimuthUnknown", "azimuth"])
                          ]),
                          _createVNode(_component_v_divider, { class: "my-3" }),
                          _cache[12] || (_cache[12] = _createElementVNode("div", { class: "text-h6" }, "Дистанція", -1)),
                          _cache[13] || (_cache[13] = _createElementVNode("div", { class: "helper-text" }, " Формат дистанції: число, число з + або -, наприклад, 100, 100+, 100- ", -1)),
                          _createElementVNode("div", _hoisted_4, [
                            _createVNode(_component_v_slider, {
                              modelValue: distance.value,
                              "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((distance).value = $event)),
                              disabled: isDistanceUnknown.value,
                              max: 3000,
                              step: 10,
                              color: "rgb(6, 26, 40)",
                              class: "mb-4",
                              "show-ticks": "always",
                              "tick-size": "4",
                              ticks: ticks.value,
                              label: "",
                              "hide-details": ""
                            }, null, 8, ["modelValue", "disabled", "ticks"]),
                            _cache[11] || (_cache[11] = _createElementVNode("div", { class: "input-section" }, null, -1)),
                            _createVNode(_component_v_text_field, {
                              label: "Дистанція",
                              modelValue: distance.value,
                              "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((distance).value = $event)),
                              modelModifiers: { trim: true },
                              type: "text",
                              class: "distance-input",
                              error: distanceError.value,
                              hint: distanceError.value ? 'Дистанція некоректна' : '',
                              "hide-details": "auto",
                              onInput: onDistanceChange,
                              disabled: isDistanceUnknown.value
                            }, null, 8, ["modelValue", "error", "hint", "disabled"]),
                            _createElementVNode("div", _hoisted_5, [
                              _createVNode(_component_v_checkbox, {
                                modelValue: isDistanceUnknown.value,
                                "onUpdate:modelValue": _cache[6] || (_cache[6] = ($event: any) => ((isDistanceUnknown).value = $event)),
                                label: "Не можливо визначити",
                                "hide-details": "",
                                onChange: handleDistanceUnknownChange
                              }, null, 8, ["modelValue"])
                            ])
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_v_expansion_panel, null, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_expansion_panel_title, { class: "header" }, {
                        default: _withCtx(() => _cache[14] || (_cache[14] = [
                          _createElementVNode("span", { class: "header-text" }, "Вибрати на мапі", -1)
                        ])),
                        _: 1
                      }),
                      _createVNode(_component_v_expansion_panel_text, null, {
                        default: _withCtx(() => [
                          _createElementVNode("div", _hoisted_6, [
                            (mapVisible.value)
                              ? (_openBlock(), _createBlock(_unref(LMap), {
                                  key: 0,
                                  zoom: zoom.value,
                                  center: center.value,
                                  ref_key: "map",
                                  ref: map,
                                  onReady: onMapReady,
                                  onClick: onMapClick,
                                  style: {"height":"500px","width":"100%"}
                                }, {
                                  default: _withCtx(() => [
                                    _createVNode(_unref(LTileLayer), {
                                      url: imageryUrl,
                                      attribution: imageryAttribution
                                    }),
                                    _createVNode(_unref(LTileLayer), {
                                      url: labelsUrl,
                                      attribution: labelsAttribution
                                    }),
                                    (marker.value)
                                      ? (_openBlock(), _createBlock(_unref(LMarker), {
                                          key: 0,
                                          "lat-lng": marker.value
                                        }, null, 8, ["lat-lng"]))
                                      : _createCommentVNode("", true)
                                  ]),
                                  _: 1
                                }, 8, ["zoom", "center"]))
                              : _createCommentVNode("", true)
                          ])
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue"])
            ]),
            _: 1
          }),
          _createVNode(_component_v_card_actions, { class: "pt-0 px-6 pb-3" }, {
            default: _withCtx(() => [
              _renderSlot(_ctx.$slots, "action"),
              _createVNode(_component_v_btn, {
                class: "custom-green-btn px-10",
                onClick: proceed,
                disabled: proceedDisabledButton.value,
                size: _unref(smAndUp) ? 'large' : 'default',
                ref_key: "buttonExposeRef",
                ref: buttonExposeRef
              }, {
                default: _withCtx(() => _cache[15] || (_cache[15] = [
                  _createTextVNode("Далі")
                ])),
                _: 1
              }, 8, ["disabled", "size"])
            ]),
            _: 3
          })
        ]),
        _: 3
      })
    ]),
    _: 3
  }, 8, ["model-value"]))
}
}

})