<template>
  <v-dialog
    :model-value="dialogVisible"
    @update:model-value="$emit('update:dialog-visible', $event)"
    max-width="500px"
  >
    <v-card>
      <v-card-title>
        {{
          isEditing
            ? 'Редагувати позицію, групу, позивний'
            : 'Створити позицію, групу, позивний'
        }}
      </v-card-title>

      <v-card-text>
        <v-alert
          v-if="alert.show"
          :type="alert.type"
          dismissible
          @input="alert.show = false"
          transition="scale-transition"
        >
          {{ alert.message }}
        </v-alert>

        <v-form ref="formRef" v-model="formValid">
          <v-text-field
            v-model="localForm.name"
            label="Назва"
            :rules="[(v) => !!v || 'Назва обовязкова']"
            required
          />

          <v-text-field v-model="localForm.description" label="Опис" />

          <v-select
            v-model="localForm.squadronId"
            label="Пункт управління"
            :items="availableSquadrons"
            item-title="display"
            item-value="id"
            :rules="[(v) => !!v || 'Пункт управління обовязковий']"
            required
          />

          <v-checkbox
            v-model="localForm.isGroup"
            label="Група чи позивний"
            :true-value="true"
            :false-value="false"
          />
        </v-form>
      </v-card-text>

      <v-card-actions>
        <v-spacer />
        <v-btn class="custom-red-btn" @click="handleClose"> Відмінити </v-btn>
        <v-btn
          class="custom-green-btn"
          @click="handleSave"
          :disabled="!formValid"
        >
          Зберегти
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, reactive, watch } from 'vue';
import type { FormState } from '@/types/admin';

const props = defineProps<{
  dialogVisible: boolean;
  isEditing: boolean;
  form: FormState['position'];
  availableSquadrons: Array<{ id: string; display: string }>;
}>();

const emit = defineEmits<{
  (e: 'update:dialog-visible', value: boolean): void;
  (e: 'save', isEdit: boolean, formData: FormState['position']): void;
}>();

const formRef = ref<any>(null);
const formValid = ref(false);
const localForm = reactive({ ...props.form });

const alert = reactive({
  show: false,
  type: 'error' as const,
  message: '',
});

function showAlert(message: string, type: 'error' | 'success' = 'error') {
  alert.show = true;
  alert.type = type;
  alert.message = message;

  setTimeout(() => {
    alert.show = false;
  }, 3000);
}

function handleClose() {
  emit('update:dialog-visible', false);
}

async function handleSave() {
  const { valid } = await formRef.value?.validate();

  if (!valid) {
    showAlert('Будь ласка, заповніть всі обовязкові поля');
    return;
  }

  emit('save', props.isEditing, { ...localForm });
}

// Watch for form changes
watch(
  () => props.form,
  (newForm) => {
    Object.keys(newForm).forEach((key) => {
      localForm[key] = newForm[key];
    });
  },
  { deep: true },
);

// Watch for dialog visibility
watch(
  () => props.dialogVisible,
  (newVal) => {
    if (newVal) {
      Object.keys(props.form).forEach((key) => {
        localForm[key] = props.form[key];
      });
      formRef.value?.resetValidation();
    }
  },
);
</script>

<style scoped>
.custom-green-btn {
  background-color: #4caf50;
  color: white;
}

.custom-red-btn {
  background-color: #d32f2f;
  color: white;
}
</style>
