<template>
  <v-card class="outlined-card">
    <v-card-title
      class="d-flex justify-space-between align-start"
      @click="expandList"
    >
      <strong>{{ title }} ({{ positions.length }})</strong>
      <v-icon
        v-if="shouldShowExpandedArrow"
        class="cursor-pointer"
        :icon="expanded ? 'mdi-chevron-down' : 'mdi-chevron-up'"
      ></v-icon>
    </v-card-title>
    <v-list :class="!positionsComputed.length ? 'pa-0' : ''" dense>
      <v-list-item
        v-for="position in positionsComputed"
        :key="position.id"
        @click="$emit('select', position)"
        :class="getItemClass(position)"
        class="position-item"
        ripple
      >
        <v-list-item-title>{{ position.name }}</v-list-item-title>
        <v-list-item-subtitle>{{ position.description }}</v-list-item-subtitle>
        <v-list-item-subtitle
          v-if="position.isUnderAttack"
          class="under-attack-text blink"
        >
          ⚠️ Штурмові дії ворога
        </v-list-item-subtitle>
        <v-list-item-subtitle
          v-if="position.isUnderFire"
          class="under-fire-text blink"
        >
          ⚠️ Під обстрілом
        </v-list-item-subtitle>
      </v-list-item>
    </v-list>
  </v-card>
</template>

<script setup>
import { computed, ref } from 'vue';

const expanded = ref(false);

const positionsIsUnderAttackAndFire = computed(() =>
  props.positions.filter(
    (position) => position.isUnderAttack || position.isUnderFire,
  ),
);

const positionsComputed = computed(() => {
  if (expanded.value) {
    return positionsIsUnderAttackAndFire.value;
  }
  return props.positions;
});

const shouldShowExpandedArrow = computed(
  () => props.positions.length !== positionsIsUnderAttackAndFire.value.length,
);

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  positions: {
    type: Array,
    required: true,
  },
});

defineEmits(['select']);

function expandList() {
  if (shouldShowExpandedArrow.value) {
    expanded.value = !expanded.value;
  }
}

function getItemClass(position) {
  if (position.isUnderAttack && position.isUnderFire) {
    return 'position-item-active under-attack under-fire';
  } else if (position.isUnderAttack) {
    return 'position-item-active under-attack';
  } else if (position.isUnderFire) {
    return 'position-item-active under-fire';
  }
  return '';
}
</script>

<style scoped>
.position-item {
  transition: background-color 0.3s;
}

.position-item-active.under-attack {
  animation: blink-bg-attack 1.5s infinite;
}

.position-item-active.under-fire {
  animation: blink-bg-fire 1.5s infinite;
}

.position-item-active.under-attack.under-fire {
  animation: blink-bg-both 1.5s infinite;
}

.position-item:hover {
  background-color: #f0f0f0;
}

.under-attack-text,
.under-fire-text {
  color: red;
  font-weight: bold;
}

@keyframes blink-bg-attack {
  0%,
  50%,
  100% {
    background-color: #ffcccc;
  }
  25%,
  75% {
    background-color: transparent;
  }
}

@keyframes blink-bg-fire {
  0%,
  50%,
  100% {
    background-color: #ffcccc;
  }
  25%,
  75% {
    background-color: transparent;
  }
}

@keyframes blink-bg-both {
  0%,
  50%,
  100% {
    background-color: #ff9999;
  }
  25%,
  75% {
    background-color: transparent;
  }
}
</style>
