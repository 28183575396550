<template>
  <v-dialog :model-value="show" opacity="0.9" max-width="600px">
    <v-card>
      <v-card-text class="pt-3 w-auto">
        <v-list>
          <h3 class="mb-3">Використати попередні дані?</h3>
          <template v-for="(item, index) in lastEventPaths" :key="index">
            <template
              v-if="item.selectDisplayType?.length && item?.canShowEventPath"
            >
              <div
                class="d-flex align-center mb-3"
                v-for="i in item.selectDisplayType"
                :key="i"
                :value="i"
                @click="goTo(index, item.startCreatedAtEventPath)"
              >
                <v-chip
                  size="large"
                  :text="i"
                  variant="outlined"
                  class="py-1"
                  style="text-wrap: wrap; height: fit-content"
                ></v-chip>
                <v-icon
                  v-if="item.startCreatedAtEventPath"
                  class="cursor-pointer ml-3"
                  >mdi-pencil</v-icon
                >
              </div>
            </template>
            <div
              v-else-if="!!item.displayType?.trim() && item?.canShowEventPath"
              :value="item"
              @click="goTo(index, item.startCreatedAtEventPath)"
            >
              <v-chip
                v-if="item.displayType"
                size="large"
                variant="outlined"
                class="py-1"
                style="text-wrap: wrap; height: fit-content"
                >{{ item.displayType }}</v-chip
              >
            </div>
          </template>
        </v-list>
      </v-card-text>
      <v-card-actions class="d-flex justify-end mt-0 ga-3 px-sm-6">
        <slot />
        <v-btn
          class="custom-gray-btn"
          @click="reset"
          ref="buttonExposeRef"
          :size="smAndUp ? 'large' : 'default'"
          >Скинути</v-btn
        >

        <v-btn
          v-if="canShowProceedButton"
          class="custom-green-btn px-10"
          @click="proceed"
          ref="buttonExposeRef"
          :size="smAndUp ? 'large' : 'default'"
          >Так</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';
import { useDisplay } from 'vuetify';
import { EventPath } from '@/types/events';
import { useEventsSessionStore } from '@/stores/eventSession';

const eventSessionStore = useEventsSessionStore();

const { smAndUp } = useDisplay();

const emit = defineEmits(['goTo']);

const props = defineProps<{
  lastEventPaths: EventPath[];
}>();

const show = defineModel('show', {
  type: Boolean,
  required: true,
});

const canShowProceedButton = computed(() => {
  return props.lastEventPaths.some(
    (item) =>
      item.canShowEventPath === false && item.startCreatedAtEventPath === true,
  );
});

const buttonExposeRef = ref<HTMLButtonElement | null>(null);

defineExpose({
  buttonExposeRef,
});

function goTo(index: number, startCreatedAtEventPath?: boolean) {
  if (startCreatedAtEventPath) {
    const lastEventPaths = [...props.lastEventPaths].splice(0, index);
    emit('goTo', lastEventPaths);
    show.value = false;
  }
}

function proceed() {
  const findIndex = props.lastEventPaths.findIndex(
    (item) =>
      item.canShowEventPath === false && item.startCreatedAtEventPath === true,
  );
  if (findIndex !== -1) {
    goTo(findIndex, true);
  }
}

function reset() {
  eventSessionStore.reset();
  show.value = false;
}
</script>
