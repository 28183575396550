import apiClient from '@/services/axios';
import version from '@/version';
import semver from 'semver';

const VERSION_POLL_INTERVAL = 60 * 1000; // 1 min
let versionPollIntervalId = null;

export function startVersionPolling() {
  stopVersionInterval();

  versionPollIntervalId = setInterval(async () => {
    try {
      const response = await apiClient.getVersion();
      if (semver.gt(response?.data?.version, version)) {
        stopVersionInterval();
        if (confirm('Оновити сторінку для отримання нової версії ?')) {
          window.location.reload();
        }
      }
    } catch (e) {
      console.log(e);
    }
  }, VERSION_POLL_INTERVAL);
}

export function stopVersionInterval() {
  clearInterval(versionPollIntervalId);
  versionPollIntervalId = null;
}
