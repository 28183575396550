import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import type {
  User,
  Squadron,
  Battalion,
  Position,
  AlertState,
  ChatConfig,
  UserRole,
  BattalionMap,
  SquadronResponse,
  RoleTranslations,
} from '@/types/admin';
import apiClient from '@/services/axios';
import { usePositionsStore } from './positions';

export const useAdminStore = defineStore('admin', () => {
  // Base state
  const users = ref<User[]>([]);
  const squadrons = ref<Squadron[]>([]);
  const battalions = ref<Battalion[]>([]);
  const positions = ref<Position[]>([]);
  const currentUser = ref<User | null>(null);

  const selectedBattalion = ref<string | null>(null);
  const selectedSquadron = ref<string | null>(null);
  const activeView = ref<string>('squadrons');

  const alert = ref<AlertState>({
    show: false,
    type: 'success',
    message: '',
  });

  // Role translations
  const roleTranslations: RoleTranslations = {
    user: 'Користувач',
    stat: 'Аналітик',
    uav_stat: 'Аналітик БПЛА',
    uav_pilot: 'Пілот БПЛА',
    admin: 'Суперадмін',
    battalion_admin: 'Адмін батальону',
    squadron_admin: 'Адмін роти',
  };

  const positionsStore = usePositionsStore();

  // Computed properties
  const filteredSquadrons = computed(() => {
    return squadrons.value
      .filter((squadron) =>
        selectedBattalion.value
          ? squadron.battalionId === selectedBattalion.value
          : true,
      )
      .sort((a, b) =>
        (a.battalionName || '').localeCompare(b.battalionName || ''),
      );
  });

  const filteredPositions = computed(() => {
    return positions.value
      .filter((position) => {
        const battalionMatch = selectedBattalion.value
          ? position.battalionName ===
            battalions.value.find((b) => b.id === selectedBattalion.value)?.name
          : true;

        const squadronMatch = selectedSquadron.value
          ? position.squadronId === selectedSquadron.value
          : true;

        return battalionMatch && squadronMatch;
      })
      .sort((a, b) => a.name.localeCompare(b.name));
  });

  const filteredUsers = computed(() => {
    return users.value.map((user) => ({
      ...user,
      squadron: squadrons.value.find((sq) => sq.id === user.squadronId),
    }));
  });

  const allSquadronItems = computed(() =>
    squadrons.value.map((squadron) => ({
      id: squadron.id,
      name: squadron.name,
      display: `${squadron.name} (${squadron.battalionName || ''})`,
    })),
  );

  const activeNonServiceSquadronItems = computed(() =>
    squadrons.value
      .filter((squadron) => !squadron.isService)
      .map((squadron) => ({
        id: squadron.id,
        name: squadron.name,
        display: `${squadron.name} (${squadron.battalionName || ''})`,
      })),
  );

  const activeBattalionItems = computed(() =>
    battalions.value
      .filter((battalion) => !battalion.isService)
      .map((battalion) => ({
        id: battalion.id,
        name: battalion.name,
      })),
  );

  const battalionsFilterItems = computed(() =>
    battalions.value.map((battalion) => ({
      name: battalion.name,
      id: battalion.id,
    })),
  );

  const positionsSquadronsFilterItems = computed(() =>
    squadrons.value
      .filter((squadron) =>
        selectedBattalion.value
          ? squadron.battalionId === selectedBattalion.value
          : true,
      )
      .sort((a, b) =>
        (a.battalionName || '').localeCompare(b.battalionName || ''),
      )
      .map((squadron) => ({
        id: squadron.id,
        display: `${squadron.name} (${squadron.battalionName || ''})`,
      })),
  );

  const getAvailableRoles = computed(() => {
    if (!currentUser.value) return [];

    if (currentUser.value.role === 'admin') {
      return Object.keys(roleTranslations).map((role) => ({
        value: role,
        displayName: roleTranslations[role as keyof RoleTranslations],
      }));
    }

    if (
      currentUser.value.role === 'battalion_admin' ||
      currentUser.value.role === 'squadron_admin'
    ) {
      return Object.keys(roleTranslations)
        .filter((role) => !['admin', 'uav_stat'].includes(role))
        .map((role) => ({
          value: role,
          displayName: roleTranslations[role as keyof RoleTranslations],
        }));
    }

    return [];
  });

  // Utility functions
  function getSquadronWithBattalion(squadron?: Squadron) {
    if (!squadron || !squadron.name) return 'N/A';
    return squadron.battalionName
      ? `${squadron.name} (${squadron.battalionName})`
      : squadron.name;
  }

  function showAlert(message: string, type: AlertState['type'] = 'success') {
    alert.value = {
      show: true,
      type,
      message,
    };
    setTimeout(() => {
      alert.value.show = false;
    }, 3000);
  }

  // Initialization
  async function initialize() {
    try {
      await getMe();
      await fetchAllData();
    } catch (error) {
      console.error('Failed to initialize:', error);
      showAlert('Failed to initialize admin panel', 'error');
    }
  }

  async function getMe() {
    const apiUser = await apiClient.getMe();
    currentUser.value = apiUser.data;
  }

  interface BattalionFormData extends Partial<Battalion> {
    signalChats?: string[];
    whatsappChats?: string[];
  }

  async function fetchAllData() {
    try {
      await fetchBattalions();
      await Promise.all([fetchSquadrons(), fetchPositions(), fetchUsers()]);
    } catch (error) {
      console.error('Failed to fetch data:', error);
      showAlert('Failed to fetch data', 'error');
    }
  }

  // Data fetching
  async function fetchBattalions() {
    try {
      const response = await apiClient.getBatallions();
      battalions.value = Array.isArray(response.data)
        ? response.data
        : [response.data];
      return battalions.value;
    } catch (error) {
      console.error('Failed to fetch battalions:', error);
      throw error;
    }
  }

  async function fetchSquadrons() {
    try {
      const response = await apiClient.getSquadrons();

      const battalionMap: BattalionMap = battalions.value.reduce(
        (map: BattalionMap, battalion) => {
          map[battalion.id] = battalion.name;
          return map;
        },
        {},
      );

      const squadronsData = Array.isArray(response.data)
        ? response.data
        : [response.data];

      squadrons.value = (squadronsData as SquadronResponse[]).map(
        (squadron) => ({
          ...squadron,
          battalionName: battalionMap[squadron.battalionId] || 'N/A',
        }),
      );

      return squadrons.value;
    } catch (error) {
      console.error('Failed to fetch squadrons:', error);
      throw error;
    }
  }

  async function fetchPositions() {
    try {
      const response = await apiClient.getPositions();
      const positionsData = Array.isArray(response.data)
        ? response.data
        : [response.data];

      positions.value = positionsData.map((position: Position) => {
        return {
          ...position,
          squadronName: position.squadron?.name || 'N/A',
          battalionName: position.squadron?.battalion?.name || 'N/A',
        };
      });

      return positions.value;
    } catch (error) {
      console.error('Failed to fetch positions:', error);
      throw error;
    }
  }

  async function fetchUsers() {
    try {
      const response = await apiClient.getAllUsers();
      users.value = Array.isArray(response.data)
        ? response.data
        : [response.data];
      return users.value;
    } catch (error) {
      console.error('Failed to fetch users:', error);
      throw error;
    }
  }

  // CRUD operations
  // Users
  async function createUser(userData: Partial<User>) {
    try {
      await apiClient.createUser(userData);
      showAlert('Користувач успішно створений');
      fetchUsers();
      return true;
    } catch (error: any) {
      const message =
        error.status === 409
          ? "Користувач з таким ім'ям вже існує"
          : 'Помилка створення користувача';
      showAlert(message, 'error');
      throw error;
    }
  }

  async function updateUser(id: string, userData: Partial<User>) {
    try {
      await apiClient.editUser(id, userData);
      const isMe = currentUser?.value?.id == id;
      if (isMe) {
        await getMe();
      }
      showAlert('Користувач успішно оновлений');
      fetchUsers();
      return true;
    } catch (error: any) {
      const message =
        error.status === 409
          ? "Користувач з таким ім'ям вже існує"
          : 'Помилка оновлення користувача';
      showAlert(message, 'error');
      throw error;
    }
  }

  async function deleteUser(id: string) {
    try {
      await apiClient.removeUser(id);
      showAlert('Користувач успішно видалений');
      fetchUsers();
      return true;
    } catch (error) {
      console.error('Failed to delete user:', error);
      showAlert('Помилка видалення користувача', 'error');
      throw error;
    }
  }

  // Squadrons
  async function createSquadron(squadronData: Partial<Squadron>) {
    try {
      await apiClient.createSquadron(squadronData);
      showAlert('Пункт управління успішно створено');
      Promise.all([fetchSquadrons(), fetchPositions()]);
      return true;
    } catch (error) {
      console.error('Failed to create squadron:', error);
      showAlert('Помилка створення Пункту управління', 'error');
      throw error;
    }
  }

  async function updateSquadron(id: string, squadronData: Partial<Squadron>) {
    try {
      await apiClient.editSquadron(id, squadronData);
      showAlert('Пункт управління успішно оновлено');
      Promise.all([fetchSquadrons(), fetchPositions()]);
      if (currentUser?.value?.squadron?.id == id) await getMe();
      return true;
    } catch (error) {
      console.error('Failed to update squadron:', error);
      showAlert('Помилка оновлення Пункту управління', 'error');
      throw error;
    }
  }

  async function deleteSquadron(id: string) {
    try {
      const squadron = squadrons.value.find((s) => s.id === id);
      if (squadron?.isService) {
        showAlert(
          'Цей Пункт управління не може бути видалений, оскільки він є сервісним.',
          'error',
        );
        return false;
      }
      await apiClient.removeSquadron(id);
      showAlert('Пункт управління успішно видалений');
      await fetchSquadrons();
      Promise.all([fetchPositions(), fetchUsers()]);
      return true;
    } catch (error) {
      console.error('Failed to delete squadron:', error);
      showAlert('Помилка видалення Пункту управління', 'error');
      throw error;
    }
  }

  // Battalions
  async function createBattalion(battalionData: BattalionFormData) {
    try {
      const requestBody = {
        ...battalionData,
        signalChatsData: battalionData.signalChatsData || [],
        whatsappChatsData: battalionData.whatsappChatsData || [],
      };

      await apiClient.createBatallion(requestBody);
      showAlert('Підрозділ успішно створено');
      Promise.all([fetchBattalions(), fetchSquadrons(), fetchPositions()]);
      return true;
    } catch (error) {
      console.error('Failed to create battalion:', error);
      showAlert('Помилка створення Підрозділу', 'error');
      throw error;
    }
  }

  async function updateBattalion(id: string, battalionData: BattalionFormData) {
    try {
      const requestBody = {
        ...battalionData,
        signalChatsData: battalionData.signalChatsData || [],
        whatsappChatsData: battalionData.whatsappChatsData || [],
      };

      await apiClient.editBatallion(id, requestBody);
      showAlert('Підрозділ успішно оновлено');
      Promise.all([fetchBattalions(), fetchSquadrons(), fetchPositions()]);
      return true;
    } catch (error) {
      console.error('Failed to update battalion:', error);
      showAlert('Помилка оновлення Підрозділу', 'error');
      throw error;
    }
  }

  async function deleteBattalion(id: string) {
    try {
      const battalion = battalions.value.find((b) => b.id === id);
      if (battalion?.isService) {
        showAlert(
          'Цей Підрозділ не може бути видалений, оскільки він є сервісним.',
          'error',
        );
        return false;
      }
      await apiClient.removeBatallion(id);
      showAlert('Підрозділ успішно видалений');
      Promise.all([fetchBattalions(), fetchSquadrons(), fetchPositions()]);
      return true;
    } catch (error) {
      console.error('Failed to delete battalion:', error);
      showAlert('Помилка видалення Підрозділу', 'error');
      throw error;
    }
  }

  // Positions
  async function createPosition(positionData: Partial<Position>) {
    try {
      await apiClient.createPosition(positionData);
      showAlert('Позицію успішно створено');
      fetchPositions();
      return true;
    } catch (error) {
      console.error('Failed to create position:', error);
      showAlert('Помилка створення позиції', 'error');
      throw error;
    }
  }

  async function updatePosition(id: string, positionData: Partial<Position>) {
    try {
      await apiClient.editPosition(id, positionData);
      showAlert('Позицію успішно оновлено');
      fetchPositions();
      return true;
    } catch (error) {
      console.error('Failed to update position:', error);
      showAlert('Помилка оновлення позиції', 'error');
      throw error;
    }
  }

  async function deletePosition(id: string) {
    try {
      await apiClient.removePosition(id);
      positionsStore.removePosition(id);
      showAlert('Позицію успішно видалено');
      fetchPositions();
      return true;
    } catch (error) {
      console.error('Failed to delete position:', error);
      showAlert('Помилка видалення позиції', 'error');
      throw error;
    }
  }

  // Chat config
  async function updateChatConfig(
    battalionId: string,
    chatType: 'signal' | 'whatsapp',
    chatName: string,
    config: ChatConfig,
  ) {
    try {
      const battalion = battalions.value.find((b) => b.id === battalionId);
      if (!battalion) throw new Error('Battalion not found');

      const chatsDataKey =
        chatType === 'signal' ? 'signalChatsData' : 'whatsappChatsData';
      const chats = [...battalion[chatsDataKey]];
      const chatIndex = chats.findIndex((chat) => chat.name === chatName);

      if (chatIndex !== -1) {
        chats[chatIndex] = { ...chats[chatIndex], config };
        await apiClient.editBatallion(battalionId, { [chatsDataKey]: chats });
        showAlert(
          `Чат ${
            chatType === 'signal' ? 'Signal' : 'WhatsApp'
          } успішно оновлено`,
        );
        await fetchBattalions();
        return true;
      }
      throw new Error('Chat not found');
    } catch (error) {
      console.error('Failed to update chat config:', error);
      showAlert('Помилка оновлення чату', 'error');
      throw error;
    }
  }

  return {
    // State
    users,
    squadrons,
    battalions,
    positions,
    currentUser,
    selectedBattalion,
    selectedSquadron,
    activeView,
    alert,
    roleTranslations,

    // Computed
    filteredSquadrons,
    filteredPositions,
    filteredUsers,
    allSquadronItems,
    activeNonServiceSquadronItems,
    activeBattalionItems,
    battalionsFilterItems,
    positionsSquadronsFilterItems,
    getAvailableRoles,

    // Utility functions
    getSquadronWithBattalion,
    showAlert,

    // Initialize and fetch
    initialize,
    fetchAllData,
    fetchBattalions,
    fetchSquadrons,
    fetchPositions,
    fetchUsers,

    // User CRUD
    createUser,
    updateUser,
    deleteUser,

    // Squadron CRUD
    createSquadron,
    updateSquadron,
    deleteSquadron,

    // Battalion CRUD
    createBattalion,
    updateBattalion,
    deleteBattalion,

    // Position CRUD
    createPosition,
    updatePosition,
    deletePosition,

    // Chat config
    updateChatConfig,
  };
});
