<template>
  <v-container
    :class="isUavStats ? 'uav-stats-width' : 'stats-width'"
    class="stats-container"
    fluid
  >
    <v-row justify="center">
      <v-col cols="12">
        <v-card elevation="2">
          <v-card-title class="headline">
            {{ isUavStats ? 'Статистика БПЛА' : 'Статистика' }}
          </v-card-title>
          <v-card-text>
            <div class="actions">
              <v-row align="center" dense>
                <v-col cols="12" sm="3" md="3">
                  <v-menu
                    v-model="menuStart"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="340px"
                    min-width="340px"
                  >
                    <template #activator="{ props }">
                      <v-text-field
                        v-model="formattedStartDate"
                        label="Початкова дата доповіді"
                        prepend-icon="mdi-calendar"
                        readonly
                        dense
                        v-bind="props"
                      ></v-text-field>
                    </template>
                    <v-card>
                      <v-date-picker
                        v-model="startDate"
                        header="Початкова дата"
                        @update:model-value="updateStartDate"
                      ></v-date-picker>
                      <div class="d-flex justify-center">
                        <label class="time_picker">Час</label>
                        <input
                          class="ml-3 time_picker"
                          type="time"
                          v-model="startTime"
                        />
                      </div>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="handleStartDateOk()"
                          ><strong>OK</strong></v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                </v-col>
                <v-col cols="12" sm="4" md="4" class="d-flex align-center">
                  <v-menu
                    v-model="menuEnd"
                    :close-on-content-click="false"
                    :nudge-right="40"
                    transition="scale-transition"
                    offset-y
                    max-width="340px"
                    min-width="340px"
                  >
                    <template #activator="{ props }">
                      <v-text-field
                        v-model="formattedEndDate"
                        label="Кінцева дата доповіді"
                        prepend-icon="mdi-calendar"
                        readonly
                        dense
                        v-bind="props"
                      ></v-text-field>
                    </template>
                    <v-card>
                      <v-date-picker
                        v-model="endDate"
                        header="Кінцева дата"
                        @update:model-value="updateEndDate"
                      ></v-date-picker>
                      <div class="d-flex justify-center">
                        <label class="time_picker">Час</label>
                        <input
                          class="ml-3 time_picker"
                          type="time"
                          v-model="endTime"
                        />
                      </div>
                      <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn text @click="handleEndDateOk()"
                          ><strong>OK</strong></v-btn
                        >
                      </v-card-actions>
                    </v-card>
                  </v-menu>
                  <v-btn
                    icon
                    @click="clearDates"
                    class="ml-2 mb-4"
                    :disabled="!startDate && !endDate"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-col>
                <v-spacer></v-spacer>
                <v-col cols="12" sm="4" md="4" class="d-flex justify-end">
                  <v-btn
                    :disabled="isExporting || !startDate || !endDate"
                    @click="handleExport"
                    color="primary"
                    class="custom-btn"
                  >
                    Експортувати в Excel
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <template v-if="isUavStats">
              <events-table-UAV
                :startDate="startDateISO"
                :endDate="endDateISO"
              />
            </template>
            <template v-else-if="isStats">
              <events-table :startDate="startDateISO" :endDate="endDateISO" />
            </template>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref, computed } from 'vue';
import EventsTable from '@/components/EventsTable.vue';
import EventsTableUAV from '@/components/EventsTableUAV.vue';
import apiClient from '@/services/axios';
import { useRoute } from 'vue-router';

const route = useRoute();
const isUavStats = computed(() => route.path.includes('uav-stats'));
const isStats = computed(() => route.path.includes('stats'));
const isExporting = ref(false);
const startDate = ref(null);
const endDate = ref(null);
const startTime = ref('00:00');
const endTime = ref('23:59');
const menuStart = ref(false);
const menuEnd = ref(false);

const formattedStartDate = computed(() =>
  startDate.value ? new Date(startDateISO.value).toLocaleString('uk-UA') : '',
);

const formattedEndDate = computed(() =>
  endDate.value ? new Date(endDateISO.value).toLocaleString('uk-UA') : '',
);

const startDateISO = computed(() => {
  if (!startDate.value) return null;
  const [hours, minutes] = startTime.value
    ? startTime.value.split(':')
    : [0, 0];
  const date = new Date(startDate.value);
  date.setHours(hours, minutes, 0, 0);
  return date.toISOString();
});

const endDateISO = computed(() => {
  if (!endDate.value) return null;
  const [hours, minutes] = endTime.value ? endTime.value.split(':') : [23, 59];
  const date = new Date(endDate.value);
  date.setHours(hours, minutes, 59, 999);
  return date.toISOString();
});

const updateStartDate = (value) => {
  startDate.value = value;
};

const updateEndDate = (value) => {
  endDate.value = value;
};

const clearDates = () => {
  startDate.value = null;
  endDate.value = null;
  startTime.value = '00:00';
  endTime.value = '23:59';
};

const handleStartDateOk = () => {
  if (!startDate.value) startDate.value = new Date();
  menuStart.value = false;
};
const handleEndDateOk = () => {
  if (!endDate.value) endDate.value = new Date();
  menuEnd.value = false;
};

const handleExport = async () => {
  if (!startDateISO.value || !endDateISO.value) {
    alert('Будь ласка оберіть початок та кінець періоду');
    return;
  }
  if (startDateISO.value > endDateISO.value) {
    alert('Початок періоду не може бути пізніше за кінець');
    return;
  }

  isExporting.value = true;
  const exportUrl = isUavStats.value ? '/events/export-uav' : '/events/export';
  const fileName = isUavStats.value ? 'uav-report' : 'events-report';
  try {
    await apiClient.handleExportStats(exportUrl, {
      startDate: startDateISO.value,
      endDate: endDateISO.value,
      fileName,
    });
  } catch (error) {
    console.error('Export failed:', error);
    alert('Сталася помилка при експорті');
  } finally {
    isExporting.value = false;
  }
};
</script>

<style scoped>
.stats-container {
  margin: 0 auto;
  padding: 0;
  background-color: inherit;
}

.uav-stats-width {
  width: 100%;
}

.stats-width {
  max-width: 1300px;
}

.actions {
  margin-bottom: 10px;
}

.v-row.dense {
  gap: 10px;
}

.v-text-field {
  font-size: 14px;
}

.custom-btn {
  font-weight: bold;
}

.ml-2 {
  margin-left: 0.5rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.time_picker {
  font-size: 25px;
}
</style>
