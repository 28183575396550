import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, unref as _unref, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, toDisplayString as _toDisplayString, renderList as _renderList, Fragment as _Fragment } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "bg-white rounded-lg elevation-1 pa-3 desctop-preview"
}
const _hoisted_2 = {
  key: 1,
  class: "mt-3"
}

import {
  ref,
  watch,
  nextTick,
  computed,
  onMounted,
  onBeforeUnmount,
  onUnmounted,
} from 'vue';
import { useDisplay } from 'vuetify';
import type {
  EventType,
  EventStep,
  EventOptions,
  Position,
  EventLocation,
  EventPath,
  LinkedObjectType,
} from '@/types/events';
import _omitBy from 'lodash.omitby';
import _isMatch from 'lodash.ismatch';
import { useEventCreationStore } from '@/stores/eventCreation';
import { useEventsSessionStore } from '@/stores/eventSession';
import MapSelectionView from '@/components/MapSelectionView.vue';
import EventNotesForm from '@/components/EventCreation/EventNotesForm.vue';
import EventInput from '@/components/EventCreation/EventInput.vue';
import EventCoordinatesSquareFrom from '@/components/EventCreation/EventCoordinatesSquareFrom.vue';
import EventExpenditureBalance from '@/components/EventCreation/EventExpenditureBalance.vue';
import EventBreadcrumbs from '@/components/EventCreation/EventBreadcrumbs.vue';
import EventKilledAndWoundedSummary from '@/components/EventCreation/EventKilledAndWoundedSummary.vue';
import EventInputNumber from '@/components/EventCreation/EventInputNumber.vue';
import EventPreviewDialog from '@/components/EventCreation/EventPreviewDialog.vue';
import EventPreviewContent from '@/components/EventCreation/EventPreviewContent.vue';
import EventTypeModelCount from '@/components/EventCreation/EventTypeModelCount.vue';
import EventModelCount from '@/components/EventCreation/EventModelCount.vue';
import EventLastCreated from '@/components/EventCreation/EventLastCreated.vue';
import { eventConfig } from '@/config/configResponse';


export default /*@__PURE__*/_defineComponent({
  __name: 'EventCreationDialog',
  props: {
    dialog: { type: Boolean },
    position: {},
    currentOption: {},
    currentOptions: {}
  },
  emits: ["update:dialog", "event-success", "event-error", "close", "select"],
  setup(__props: any, { emit: __emit }) {

const { smAndUp } = useDisplay();

const props = __props;

const emit = __emit;

const buttonExposeRef = ref();

const eventStore = useEventCreationStore();
const eventSessionStore = useEventsSessionStore();

const positionData = ref({ ...props.position });
const alert = ref({ show: false, message: '', type: 'info' });
const currentOptions = ref<EventType[]>(props?.currentOptions ?? eventConfig);

const inputTypeValue = ref('');
const selectedItem = ref<EventType | null>(null);

const step = ref(0);
const path = ref<EventPath[]>([]);
const history = ref<EventType[][]>([]);
const submitThrottle = ref(false);
const isShowEventPreviewLastCreatedEventDialog = ref(true);

const currentType = ref<EventStep | ''>('');
const options = ref<EventOptions>({
  category: 'single',
});

const actionsView = computed(
  () =>
    ![
      'expenditure-balance',
      'coordinates-square',
      'killed-wounded-summary',
      'input-type-model-count',
      'input-model-count',
      'coordinates-square-target-destruction',
      'input-optional',
    ].includes(currentType.value),
);

const lastEventPaths = computed(() => {
  if (options.value.saveKey) {
    return eventSessionStore.lastEventPath(options.value.saveKey);
  }
  return [];
});

const eventMessage = computed(() =>
  getDisplayTypeInPaths(path.value.filter((i) => !i.ignoreDisplayType)).join(
    '\n',
  ),
);

const inputType = computed(() =>
  currentOptions.value.find((item) =>
    ['input-number', 'input'].includes(item.type),
  ),
);

function handleNextButtonForInputType() {
  if (!selectedItem.value) return;

  if (Object.keys(selectedItem.value?.linkedObject ?? {}).length) {
    let linkedObject = selectedItem.value.linkedObject!;
    const linkedObjectKey = Object.keys(linkedObject).filter(
      (i) => i !== 'parentObject',
    )[0];

    handleInput([`${selectedItem.value.displayType}${inputTypeValue.value}`], {
      ...selectedItem.value,
      linkedObject: {
        ...linkedObject,
        [linkedObjectKey]: inputTypeValue.value,
      },
    });
    return;
  }

  handleInput(
    [`${selectedItem.value.displayType}${inputTypeValue.value}`],
    selectedItem.value,
  );
}

function handleSelection(item: EventType, isGoTo = false) {
  if (!isGoTo) {
    updatePathAndHistory(item);
  }
  updateStoreState(item);

  if (shouldAddDisplayType(item)) {
    if (item?.linkedObject) {
      addFieldToPath({
        selectDisplayType: [item.displayType!],
        selectLinkedObject: item.linkedObject as LinkedObjectType,
      });
    } else {
      addFieldToPath({ selectDisplayType: [item.displayType!] });
    }
  }

  if (isStepsCategory(item)) {
    handleStepsCategory(item);
  } else if (hasChildren(item)) {
    handleChildrenCategory(item);
  } else {
    goNextStep();
  }
}

function updatePathAndHistory(item: EventType) {
  path.value.push({ ...item, step: step.value });
  history.value.push([...currentOptions.value]);
}

function updateStoreState(item: EventType) {
  if (item.id) {
    eventStore.$patch({ selectedId: item.id });
  }

  if (item.options?.category) {
    updateOptionState(item.options); // Оновлення опцій на основі категорії
    eventStore.$patch({ type: item.name });
  }

  if (item.displayType && step.value === 0) {
    eventStore.$patch({
      eventMessage: item.displayType,
      description: item.displayType,
    });
  }
}

function isStepsCategory(item: EventType): boolean {
  return item.options?.category === 'steps';
}

function handleStepsCategory(item: EventType) {
  currentOptions.value = item.steps![0]?.children || [];
  step.value = 1;

  if (step.value === 1) {
    eventStore.$patch({ eventMessage: item.name });
  }
}

function hasChildren(item: EventType): boolean {
  return !!item.children?.length;
}

function handleChildrenCategory(item: EventType) {
  currentOptions.value = item.children!;
}

function shouldAddDisplayType(item: EventType): boolean {
  return !!item.displayType && step.value > 0;
}

function updateOptionState(optionsProps: EventOptions) {
  if (optionsProps.category) {
    options.value = {
      ...options.value,
      category: optionsProps.category,
    };
  }

  if (optionsProps.skipMapStep) {
    options.value = {
      ...options.value,
      skipMapStep: optionsProps.skipMapStep,
    };
  }

  if (optionsProps.allies) {
    options.value = {
      ...options.value,
      allies: optionsProps.allies,
    };
  }

  if (optionsProps.enemies) {
    options.value = {
      ...options.value,
      enemies: optionsProps.enemies,
    };
  }

  if (
    optionsProps.isUnderAttack !== undefined ||
    optionsProps.isUnderFire !== undefined
  ) {
    positionData.value = {
      ...positionData.value,
      ...(optionsProps.isUnderAttack !== undefined && {
        isUnderAttack: optionsProps.isUnderAttack,
      }),
      ...(optionsProps.isUnderFire !== undefined && {
        isUnderFire: optionsProps.isUnderFire,
      }),
    };
  }

  if (optionsProps.saveKey) {
    options.value = {
      ...options.value,
      saveKey: optionsProps.saveKey,
    };
  }
}

function addFieldToPath({
  selectDisplayType,
  selectLinkedObject,
}: {
  selectLinkedObject?: LinkedObjectType;
  selectDisplayType: string[];
}) {
  const pathLastItem = path.value.at(-1)!;
  const pathLength = path.value.length - 1;
  const newPathValue =
    pathLength > 0
      ? [
          ...path.value.slice(0, pathLength),
          { ...pathLastItem, selectDisplayType, selectLinkedObject },
        ]
      : [{ ...pathLastItem, selectDisplayType, selectLinkedObject }];
  path.value = newPathValue;
}

// Обробка введення даних з інпутів та форм вводу
function handleInput(value: string[], item: EventType, linkedObject?: any) {
  const name = item.shouldDisplayInputValue ? inputTypeValue.value : item.name;

  if (options.value.category === 'single') {
    handleSelection({ ...item, displayType: value.join('\n'), name });
    return;
  }
  // від зміни порядку виконнання залежить правильність відображення
  handleSelection({ ...item, name });
  if (item?.linkedObject) {
    addFieldToPath({
      selectDisplayType: value,
      selectLinkedObject:
        !!linkedObject && item.linkedObject
          ? (_omitBy(
              linkedObject,
              (value) => value === null,
            ) as LinkedObjectType)
          : (_omitBy(
              item.linkedObject,
              (value) => value === null,
            ) as LinkedObjectType),
    });
  } else {
    addFieldToPath({ selectDisplayType: value });
  }
}

function goTo(index: number) {
  if (index === 0) {
    resetToInitialState();
    return;
  }

  path.value = path.value.slice(0, index);
  history.value = history.value.slice(0, index);

  const pathValue = path.value.at(-1)!;

  step.value = pathValue.step;

  handleSelection(pathValue, true);
}

//Скидає поточний стан та відновлює останню створену подію, обробляючи її для подальшої взаємодії
function reproduceLastCreatedEvent(event: EventType[]) {
  resetToInitialState();
  event.forEach((item) => handleSelection(item));
}

function goBack() {
  if (path.value.length > 1) {
    goTo(path.value.length - 1);
  } else {
    resetToInitialState();
  }
}

// Перехід до наступного кроку
function goNextStep() {
  if (path.value.length > 1) {
    isShowEventPreviewLastCreatedEventDialog.value = false;
  }
  if (step.value > 0) {
    const nextStep = path.value[0]?.steps?.[step.value];
    if (nextStep) {
      currentOptions.value = nextStep.children || [];
      step.value++;
    }
  } else {
    //Якщо більше немає кроків,або вкладених елементів children, то переходимо до останнього кроку (notes або map)
    currentOptions.value = [
      { type: options.value?.skipMapStep ? 'notes' : 'map' },
    ];
  }
}

function resetToInitialState() {
  currentOptions.value = props?.currentOptions ?? eventConfig; // Початкові опції
  path.value = []; // Скидаємо шлях
  history.value = []; // Скидаємо історію
  step.value = 0; // Початковий крок
  currentType.value = ''; // Початковий тип
  options.value = { category: 'single' }; // Початкові опції
  positionData.value = { ...props.position }; // Початкові дані про позицію
  isShowEventPreviewLastCreatedEventDialog.value = true; // Скидаємо стан перегляду попередньої події
}

// Показ алерту
function showAlert(message: string, type: string = 'info') {
  alert.value = { show: true, message, type };
}

function getDisplayTypeInPaths(
  path: Pick<EventPath, 'selectDisplayType' | 'children' | 'steps'>[],
): string[] {
  const result: string[][] = [];

  function traverse(
    event: Pick<EventPath, 'selectDisplayType' | 'children' | 'steps'>,
  ): void {
    if (event?.selectDisplayType?.length) {
      result.push([...event.selectDisplayType]);
    }
    if (event.children?.length) {
      event.children.forEach(traverse);
    }
    if (event.steps?.length) {
      event.steps.forEach(traverse);
    }
  }

  path.forEach(traverse);

  return result.flat(Infinity) as string[];
}

function getLinkedObjectsInPaths(
  path: Pick<EventPath, 'children' | 'steps' | 'selectLinkedObject'>[],
) {
  let result = {};

  function traverse(
    event: Pick<EventPath, 'children' | 'steps' | 'selectLinkedObject'>,
  ): void {
    if (event.selectLinkedObject) {
      result = { ...result, ...event.selectLinkedObject };
    }
    if (event.children?.length) {
      event.children.forEach(traverse);
    }
    if (event.steps?.length) {
      event.steps.forEach(traverse);
    }
  }

  path.forEach(traverse);

  if ('parentObject' in result) {
    const { parentObject, ...linkedObject } = result;
    result = { [parentObject as string]: linkedObject };
  } else {
    result = {};
  }

  return result;
}

// Завершення
async function handleSubmit() {
  if (submitThrottle.value) return;
  submitThrottle.value = false;
  const linkedObject = getLinkedObjectsInPaths(path.value);

  eventStore.$patch({
    chatMessage: eventMessage.value,
  });

  if (options.value.saveKey) {
    eventSessionStore.setPath(options.value.saveKey, path.value);
  }

  const result = await eventStore.createEvent(positionData.value, linkedObject);

  if (result.success) {
    emit('event-success', result.message);
    closeDialog();
  } else {
    showAlert(result.message, 'error');
  }
}

// Оновлення діалогу
function handleDialogUpdate(value: boolean) {
  if (!value) closeDialog();
}

// Обробка вибору локації
async function handleLocationSelected(location: EventLocation) {
  eventStore.$patch({ location });

  // додавання кроку до шляху
  path.value.push({ name: 'Координати', type: 'map', step: step.value });
  history.value.push([...currentOptions.value]);

  // перехід до останнього кроку
  currentOptions.value = [{ type: 'notes' }];
}

// Закриття діалогу
function closeDialog() {
  submitThrottle.value = true;
  nextTick(() => {
    eventStore.reset();
    emit('update:dialog', false);
    emit('close');
  });
}

// --- Спостереження ---
watch(
  () => props.dialog,
  (newValue) => {
    if (!newValue) closeDialog();
  },
);

watch(
  () => props.position,
  (newValue) => {
    positionData.value = { ...newValue };
  },
);

watch(currentOptions, (value) => {
  const type = value[0]?.type || '';
  currentType.value = type;
  // Обнуляємо значення поля вводу при зміні опцій
  inputTypeValue.value = '';
  selectedItem.value = null;
});

function handleEnterPress(e: KeyboardEvent) {
  if (e.key !== 'Enter') return;

  const inputButton = buttonExposeRef.value?.$el;
  const buttonExpose = buttonExposeRef.value?.[0]?.buttonExposeRef?.$el;

  if (buttonExpose) {
    buttonExpose.click();
  }

  if (inputType.value) {
    inputButton?.click();
  }
}

onMounted(() => {
  // якщо props.currentOption існує, то починаємо подію з цього елементу
  if (props.currentOption) {
    handleSelection(props.currentOption);
  }
  window.addEventListener('keypress', handleEnterPress);
});

onBeforeUnmount(() => {
  window.removeEventListener('keypress', handleEnterPress);
});

onUnmounted(() => {
  window.removeEventListener('keypress', handleEnterPress);
});

return (_ctx: any,_cache: any) => {
  const _component_v_btn = _resolveComponent("v-btn")!
  const _component_v_alert = _resolveComponent("v-alert")!
  const _component_v_list_item = _resolveComponent("v-list-item")!
  const _component_v_col = _resolveComponent("v-col")!
  const _component_v_row = _resolveComponent("v-row")!
  const _component_v_container = _resolveComponent("v-container")!
  const _component_v_card_text = _resolveComponent("v-card-text")!
  const _component_v_card_actions = _resolveComponent("v-card-actions")!
  const _component_v_card = _resolveComponent("v-card")!
  const _component_v_form = _resolveComponent("v-form")!
  const _component_v_dialog = _resolveComponent("v-dialog")!

  return (_openBlock(), _createBlock(_component_v_dialog, {
    "model-value": _ctx.dialog,
    "onUpdate:modelValue": handleDialogUpdate,
    "max-width": 600,
    "content-class": 
      currentType.value === 'notes' ? 'dialog-events-content-sizes' : ''
    ,
    persistent: ""
  }, {
    default: _withCtx(() => [
      (currentType.value === 'notes')
        ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
            _createVNode(EventPreviewContent, {
              position: positionData.value,
              eventMessage: eventMessage.value,
              onHandleSubmit: handleSubmit
            }, null, 8, ["position", "eventMessage"])
          ]))
        : _createCommentVNode("", true),
      (path.value.length === 1 && options.value.saveKey && lastEventPaths.value.length)
        ? (_openBlock(), _createBlock(EventLastCreated, {
            key: 1,
            show: isShowEventPreviewLastCreatedEventDialog.value,
            "onUpdate:show": _cache[0] || (_cache[0] = ($event: any) => ((isShowEventPreviewLastCreatedEventDialog).value = $event)),
            ref_key: "buttonExposeRef",
            ref: buttonExposeRef,
            lastEventPaths: lastEventPaths.value,
            onGoTo: reproduceLastCreatedEvent
          }, {
            default: _withCtx(() => [
              _createVNode(_component_v_btn, {
                class: "custom-default-btn",
                onClick: goBack,
                size: _unref(smAndUp) ? 'large' : 'default'
              }, {
                default: _withCtx(() => _cache[5] || (_cache[5] = [
                  _createTextVNode(" Назад ")
                ])),
                _: 1
              }, 8, ["size"])
            ]),
            _: 1
          }, 8, ["show", "lastEventPaths"]))
        : _createCommentVNode("", true),
      _createVNode(_component_v_form, {
        class: "pa-0 ma-0",
        modelValue: _unref(eventSessionStore).valid,
        "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_unref(eventSessionStore).valid) = $event))
      }, {
        default: _withCtx(() => [
          _createVNode(_component_v_card, null, {
            default: _withCtx(() => [
              _createVNode(_component_v_card_text, { class: "pb-0 pt-3" }, {
                default: _withCtx(() => [
                  (alert.value.show)
                    ? (_openBlock(), _createBlock(_component_v_alert, {
                        key: 0,
                        type: alert.value.type,
                        dismissible: "",
                        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (alert.value.show = false))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(alert.value.message), 1)
                        ]),
                        _: 1
                      }, 8, ["type"]))
                    : _createCommentVNode("", true),
                  _createVNode(EventBreadcrumbs, {
                    path: path.value,
                    onGoTo: goTo,
                    onCloseDialog: closeDialog
                  }, null, 8, ["path"]),
                  _createVNode(_component_v_container, { class: "pa-0" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_v_row, { "no-gutters": "" }, {
                        default: _withCtx(() => [
                          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(currentOptions.value, (item) => {
                            return (_openBlock(), _createBlock(_component_v_col, {
                              key: item.name,
                              cols: "12",
                              "data-type": item.type ?? 'constant',
                              "data-name": item.name,
                              class: "event-items"
                            }, {
                              default: _withCtx(() => [
                                (!item.type || item.type === 'constant')
                                  ? (_openBlock(), _createBlock(_component_v_list_item, {
                                      key: 0,
                                      class: "list-item-button",
                                      onClick: ($event: any) => (handleSelection(item)),
                                      title: item?.title || item.name,
                                      "data-pw": item.name
                                    }, null, 8, ["onClick", "title", "data-pw"]))
                                  : (
                    item.type === 'input' || item.type === 'input-optional'
                  )
                                    ? (_openBlock(), _createBlock(EventInput, {
                                        key: 1,
                                        type: item.type,
                                        displayType: item.displayType,
                                        linkedObject: item.linkedObject,
                                        label: item.name!,
                                        modelValue: inputTypeValue.value,
                                        "onUpdate:modelValue": [
                                          _cache[2] || (_cache[2] = ($event: any) => ((inputTypeValue).value = $event)),
                                          () => (selectedItem.value = item)
                                        ],
                                        onInput: 
                    (value, linkedObject) =>
                      handleInput(value, item, linkedObject)
                  
                                      }, null, 8, ["type", "displayType", "linkedObject", "label", "modelValue", "onUpdate:modelValue", "onInput"]))
                                    : (item.type === 'input-number')
                                      ? (_openBlock(), _createBlock(EventInputNumber, {
                                          key: 2,
                                          label: item.name!,
                                          modelValue: inputTypeValue.value,
                                          "onUpdate:modelValue": [
                                            _cache[3] || (_cache[3] = ($event: any) => ((inputTypeValue).value = $event)),
                                            () => (selectedItem.value = item)
                                          ]
                                        }, null, 8, ["label", "modelValue", "onUpdate:modelValue"]))
                                      : (
                    item.type === 'coordinates-square' ||
                    item.type === 'coordinates-square-target-destruction'
                  )
                                        ? (_openBlock(), _createBlock(EventCoordinatesSquareFrom, {
                                            key: 3,
                                            type: item.type,
                                            ref_for: true,
                                            ref_key: "buttonExposeRef",
                                            ref: buttonExposeRef,
                                            onInput: 
                    (value, linkedObject) =>
                      handleInput(value, item, linkedObject)
                  
                                          }, {
                                            default: _withCtx(() => [
                                              _createVNode(_component_v_btn, {
                                                class: "custom-default-btn",
                                                onClick: goBack,
                                                size: _unref(smAndUp) ? 'large' : 'default'
                                              }, {
                                                default: _withCtx(() => _cache[6] || (_cache[6] = [
                                                  _createTextVNode(" Назад ")
                                                ])),
                                                _: 1
                                              }, 8, ["size"])
                                            ]),
                                            _: 2
                                          }, 1032, ["type", "onInput"]))
                                        : (item.type === 'expenditure-balance')
                                          ? (_openBlock(), _createBlock(EventExpenditureBalance, {
                                              key: 4,
                                              onInput: (value) => handleInput(value, item),
                                              ref_for: true,
                                              ref_key: "buttonExposeRef",
                                              ref: buttonExposeRef
                                            }, {
                                              default: _withCtx(() => [
                                                _createVNode(_component_v_btn, {
                                                  class: "custom-default-btn",
                                                  size: _unref(smAndUp) ? 'large' : 'default',
                                                  onClick: goBack
                                                }, {
                                                  default: _withCtx(() => _cache[7] || (_cache[7] = [
                                                    _createTextVNode(" Назад ")
                                                  ])),
                                                  _: 1
                                                }, 8, ["size"])
                                              ]),
                                              _: 2
                                            }, 1032, ["onInput"]))
                                          : (item.type === 'killed-wounded-summary')
                                            ? (_openBlock(), _createBlock(EventKilledAndWoundedSummary, {
                                                key: 5,
                                                ref_for: true,
                                                ref_key: "buttonExposeRef",
                                                ref: buttonExposeRef,
                                                onInput: 
                    (value, linkedObject) =>
                      handleInput(value, item, linkedObject)
                  
                                              }, {
                                                default: _withCtx(() => [
                                                  _createVNode(_component_v_btn, {
                                                    class: "custom-default-btn",
                                                    size: _unref(smAndUp) ? 'large' : 'default',
                                                    onClick: goBack
                                                  }, {
                                                    default: _withCtx(() => _cache[8] || (_cache[8] = [
                                                      _createTextVNode(" Назад ")
                                                    ])),
                                                    _: 1
                                                  }, 8, ["size"])
                                                ]),
                                                _: 2
                                              }, 1032, ["onInput"]))
                                            : _createCommentVNode("", true),
                                (item.type === 'input-model-count')
                                  ? (_openBlock(), _createBlock(EventModelCount, {
                                      key: 6,
                                      ref_for: true,
                                      ref_key: "buttonExposeRef",
                                      ref: buttonExposeRef,
                                      ammoType: (item.linkedObject!.ammunitionType as string),
                                      onInput: 
                    (value, linkedObject) =>
                      handleInput(value, item, linkedObject)
                  
                                    }, {
                                      default: _withCtx(() => [
                                        (path.value.length > 0)
                                          ? (_openBlock(), _createBlock(_component_v_btn, {
                                              key: 0,
                                              class: "custom-default-btn",
                                              onClick: goBack,
                                              size: _unref(smAndUp) ? 'large' : 'default'
                                            }, {
                                              default: _withCtx(() => _cache[9] || (_cache[9] = [
                                                _createTextVNode(" Назад ")
                                              ])),
                                              _: 1
                                            }, 8, ["size"]))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 2
                                    }, 1032, ["ammoType", "onInput"]))
                                  : _createCommentVNode("", true),
                                (item.type === 'input-type-model-count')
                                  ? (_openBlock(), _createBlock(EventTypeModelCount, {
                                      key: 7,
                                      ref_for: true,
                                      ref_key: "buttonExposeRef",
                                      ref: buttonExposeRef,
                                      typeOfAmmunitions: item.typeOfAmmunitions,
                                      onInput: 
                    (value, linkedObject) =>
                      handleInput(value, item, linkedObject)
                  
                                    }, {
                                      default: _withCtx(() => [
                                        (path.value.length > 0)
                                          ? (_openBlock(), _createBlock(_component_v_btn, {
                                              key: 0,
                                              class: "custom-default-btn",
                                              onClick: goBack,
                                              size: _unref(smAndUp) ? 'large' : 'default'
                                            }, {
                                              default: _withCtx(() => _cache[10] || (_cache[10] = [
                                                _createTextVNode(" Назад ")
                                              ])),
                                              _: 1
                                            }, 8, ["size"]))
                                          : _createCommentVNode("", true)
                                      ]),
                                      _: 2
                                    }, 1032, ["typeOfAmmunitions", "onInput"]))
                                  : _createCommentVNode("", true),
                                (item.type === 'map')
                                  ? (_openBlock(), _createBlock(MapSelectionView, {
                                      key: 8,
                                      dialog: _ctx.dialog,
                                      onLocationSelected: handleLocationSelected,
                                      "onUpdate:dialog": closeDialog,
                                      ref_for: true,
                                      ref_key: "buttonExposeRef",
                                      ref: buttonExposeRef
                                    }, {
                                      title: _withCtx(() => [
                                        _createVNode(EventBreadcrumbs, {
                                          path: path.value,
                                          onGoTo: goTo,
                                          onCloseDialog: closeDialog
                                        }, null, 8, ["path"])
                                      ]),
                                      action: _withCtx(() => [
                                        _createVNode(_component_v_btn, {
                                          class: "custom-default-btn",
                                          size: _unref(smAndUp) ? 'large' : 'default',
                                          onClick: goBack
                                        }, {
                                          default: _withCtx(() => _cache[11] || (_cache[11] = [
                                            _createTextVNode(" Назад ")
                                          ])),
                                          _: 1
                                        }, 8, ["size"])
                                      ]),
                                      _: 1
                                    }, 8, ["dialog"]))
                                  : (item.type === 'notes')
                                    ? (_openBlock(), _createBlock(EventNotesForm, {
                                        key: 9,
                                        position: positionData.value,
                                        eventMessage: eventMessage.value,
                                        "show-allies": Boolean(options.value?.allies),
                                        "show-enemies": Boolean(options.value?.enemies)
                                      }, null, 8, ["position", "eventMessage", "show-allies", "show-enemies"]))
                                    : _createCommentVNode("", true)
                              ]),
                              _: 2
                            }, 1032, ["data-type", "data-name"]))
                          }), 128))
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }),
              (actionsView.value && path.value.length)
                ? (_openBlock(), _createBlock(_component_v_card_actions, {
                    key: 0,
                    class: "px-sm-6 pt-3 pb-2 d-flex justify-end mt-0"
                  }, {
                    default: _withCtx(() => [
                      (currentType.value === 'notes')
                        ? (_openBlock(), _createBlock(EventPreviewDialog, {
                            key: 0,
                            position: positionData.value,
                            eventMessage: eventMessage.value
                          }, {
                            title: _withCtx(() => [
                              _createVNode(EventPreviewContent, {
                                position: positionData.value,
                                eventMessage: eventMessage.value,
                                onHandleSubmit: handleSubmit
                              }, null, 8, ["position", "eventMessage"])
                            ]),
                            default: _withCtx(() => [
                              (currentType.value === 'notes')
                                ? (_openBlock(), _createBlock(_component_v_btn, {
                                    key: 0,
                                    disabled: _unref(eventStore).loading,
                                    onClick: handleSubmit,
                                    size: _unref(smAndUp) ? 'large' : 'default',
                                    class: "custom-green-btn"
                                  }, {
                                    default: _withCtx(() => _cache[12] || (_cache[12] = [
                                      _createTextVNode(" Створити подію ")
                                    ])),
                                    _: 1
                                  }, 8, ["disabled", "size"]))
                                : _createCommentVNode("", true)
                            ]),
                            _: 1
                          }, 8, ["position", "eventMessage"]))
                        : _createCommentVNode("", true),
                      (path.value.length > 0)
                        ? (_openBlock(), _createBlock(_component_v_btn, {
                            key: 1,
                            class: "custom-default-btn",
                            onClick: goBack,
                            size: _unref(smAndUp) ? 'large' : 'default'
                          }, {
                            default: _withCtx(() => _cache[13] || (_cache[13] = [
                              _createTextVNode(" Назад ")
                            ])),
                            _: 1
                          }, 8, ["size"]))
                        : _createCommentVNode("", true),
                      (inputType.value)
                        ? (_openBlock(), _createBlock(_component_v_btn, {
                            key: 2,
                            ref_key: "buttonExposeRef",
                            ref: buttonExposeRef,
                            class: "custom-green-btn px-10",
                            onClick: handleNextButtonForInputType,
                            size: _unref(smAndUp) ? 'large' : 'default',
                            disabled: !_unref(eventSessionStore).valid || !inputTypeValue.value
                          }, {
                            default: _withCtx(() => _cache[14] || (_cache[14] = [
                              _createTextVNode(" Далі ")
                            ])),
                            _: 1
                          }, 8, ["size", "disabled"]))
                        : _createCommentVNode("", true),
                      (currentType.value === 'notes')
                        ? (_openBlock(), _createBlock(_component_v_btn, {
                            key: 3,
                            disabled: _unref(eventStore).loading,
                            onClick: handleSubmit,
                            size: _unref(smAndUp) ? 'large' : 'default',
                            class: "custom-green-btn"
                          }, {
                            default: _withCtx(() => _cache[15] || (_cache[15] = [
                              _createTextVNode(" Створити подію ")
                            ])),
                            _: 1
                          }, 8, ["disabled", "size"]))
                        : _createCommentVNode("", true)
                    ]),
                    _: 1
                  }))
                : (_openBlock(), _createElementBlock("div", _hoisted_2))
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue"])
    ]),
    _: 1
  }, 8, ["model-value", "content-class"]))
}
}

})